//
// User Navigation
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-fast-links {
  list-style: none;
  margin-bottom: 31px;
  margin-top: 0;
  padding: 0;

  li {
    display: inline-block;
    margin-bottom: 10px;
    width: 100%;

    &:last-of-type {
      margin-bottom: 0;
    }

    a {
      @include rounded-white;
      background-color: $white;
      border-radius: 20px;
      color: $orange;
      display: flex;
      align-items: center;
      font: {
        size: 1.4em;
        weight: $semibold;
      }
      line-height: 23px;
      padding: 4px 16px;
      text-decoration: none;
      transition: color 0.2s $ease-default, background 0.2s $ease-default;
      width: 100%;
      min-height: 36px;

      &:hover {
        background-color: $orange;
        color: $white;

        .vg-icon {
          fill: $white;

          &.vg-icon-divulgar-vagas,
          &.vg-icon-programa-estagio-trainee,
          &.vg-icon-administrar-eventos-presenciais,
          &.vg-icon-abrir-processo-seletivo,
          &.vg-icon-acompanhar-vagas {
            stroke: $white;
          }
        }
      }

      .badge-new-feature {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 28px;
        width: 28px;
        border-radius: 14px;
        background-color: #ecfdf3;
        margin-left: 8px;
      }

      .vg-icon {
        display: inline-block;
        fill: $orange;
        margin-right: 10px;
        margin-top: 3px;
        max-height: 23px;
        transition: fill 0.2s $ease-default, stroke 0.2s $ease-default;
        width: 23px;

        &.vg-icon-divulgar-vagas,
        &.vg-icon-programa-estagio-trainee,
        &.vg-icon-administrar-eventos-presenciais,
        &.vg-icon-abrir-processo-seletivo,
        &.vg-icon-acompanhar-vagas {
          stroke: $orange;
        }
      }
    }
  }

  &.vg-fast-placeholder {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 80%;

    li {
      @include rounded-white;
      border-radius: 20px;
      padding: 9px 20px 11px;
    }

    .vg-icon {
      animation: infiniteFade 0.7s $ease-default both alternate infinite;
      display: block;
      width: 100%;
    }
  }
}

@include desktop {
  .vg-fast-links {
    max-width: 100%;
    margin: 0 auto 24px;
    text-align: center;

    li {
      margin: 0;
      width: auto;
    }

    li + li {
      margin: 8px 0 0 10px;
    }

    &.vg-fast-placeholder {
      flex-direction: row;
    }
  }
}
