//
// Header
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";
.vg-video-player {
  font-family: $base-font-family !important;
  position: relative;

  .vg-toggle-mask {
    appearance: none;
    background: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    padding: 0;
    position: absolute;
    right: 24px;
    top: 24px;
    z-index: 1;

    .vg-icon {
      height: 40px;
      width: 40px;
      fill: $orange;
    }

    &.vg-active {
      .vg-icon {
        fill: $white;
      }
    }
  }

  .video-react {
    background-color: $light-gray-2;
    height: 0;
    height: auto !important;
    overflow: hidden;
    padding-bottom: 71%; /*16:9*/
    padding-top: 30px;
    position: relative;
    width: 100% !important;

    .video-react-video {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.vg-video-controllers {
  align-items: center;
  border: 1px solid $light-gray-2;
  border-radius: 0 0 5px 5px;
  border-top: 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 43px;

  .vg-steps-bullet {
    margin: 0;
  }

  .vg-button-controller {
    appearance: none;
    background: transparent;
    border: 0;
    border-right: 1px solid $light-gray-2;
    color: $orange;
    cursor: pointer;
    font-size: 1.5em;
    font-weight: $semibold;
    padding: 12px 21px;

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.45;
    }

    &:last-child {
      border: 0;
      border-left: 1px solid $light-gray-2;
    }
  }
}

.vg-video-details {
  em {
    color: $dark-gray-2;
    font-size: 1.5em;
    font-style: normal;
  }

  h1 {
    border-bottom: 1px solid $light-gray-2;
    color: $dark-gray-1;
    font-size: 2.4em;
    font-weight: $regular;
    line-height: 1.5;
    margin: 14px 0 24px;
    padding-bottom: 24px;
  }

  h4 {
    color: $dark-gray-1;
    font-size: 1.5em;
    font-weight: $semibold;
    margin-bottom: 16px;

    + p {
      color: $dark-gray-2;
      font-size: 1.5em;
      margin-bottom: 24px;
      margin-top: 0;
    }
  }

  .vg-tags {
    border-bottom: 1px solid $light-gray-2;
    list-style: none;
    padding: 0;
    padding-bottom: 16px;
    margin-bottom: 32px;

    li {
      background-color: $light-orange-2;
      border-radius: 5px;
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 8px;
      padding: 7px 16px;
    }

    span {
      background: linear-gradient(45deg, $orange, $red-gradient);
      color: $orange;
      font-size: 1.5em;
      font-weight: $semibold;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.vg-video-page {
  $primary-foreground-color: $white;
  $primary-background-color: $dark-gray-2;
  $center-big-play-button: false;
  $big-play-width: 3em;
  $big-play-height: 1.5em;
  $slider-bg-color: lighten($primary-background-color, 33%);

  .vg-container {
    padding: 0;

    .vg-row {
      margin: 0;

      [class^="vg-col-"] {
        padding: 0;
      }
    }
  }

  .video-react {
    color: $primary-foreground-color;
    font-family: $open-sans;
    font-size: 10px;
  }

  .video-react-default-skin .video-react-big-play-button {
    border: 0.06666em solid $primary-foreground-color;
    border-radius: 0.3em;
    font-size: 3em;
    height: $big-play-height;
    line-height: $big-play-height;
    width: $big-play-width;

    @if $center-big-play-button {
      left: 50%;
      top: 50%;
      margin-left: -($big-play-width / 2);
      margin-top: -($big-play-height / 2);
    } @else {
      left: 0.5em;
      top: 0.5em;
    }
  }

  .video-react .video-react-control-bar,
  .video-react .video-react-big-play-button,
  .video-react .video-react-menu-button .video-react-menu-content {
    background-color: $primary-background-color;
    background-color: rgba($primary-background-color, 0.7);
  }

  .video-react .video-react-big-play-button {
    @include orange-degrade;
    border: 0;
    border-radius: 100px;
  }

  .video-react .video-react-slider {
    background-color: $slider-bg-color;
    background-color: rgba($slider-bg-color, 0.5);
  }

  .video-react .video-react-volume-level,
  .video-react .video-react-play-progress,
  .video-react .video-react-slider-bar {
    background: $primary-foreground-color;
  }

  .video-react .video-react-load-progress {
    background: lighten($slider-bg-color, 25%);
    background: rgba($slider-bg-color, 0.5);
  }

  .video-react .video-react-load-progress div {
    background: lighten($slider-bg-color, 50%);
    background: rgba($slider-bg-color, 0.75);
  }

  .vg-video-content {
    padding: 24px;
  }

  .vg-graph-emotions {
    bottom: -2px;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    z-index: 2;

    line {
      stroke: $dark-gray-1 !important;
    }

    text tspan {
      fill: $dark-gray-1 !important;
      font-family: $open-sans !important;
      font-size: 12px !important;
    }
  }

  .video-react-control-bar {
    z-index: 3;
  }

  .vg-container {
    @include rounded-white;

    aside {
      border-top: 1px solid $light-gray-1;
      height: 100%;

      .animation-loader,
      .vg-video-user {
        + h4 {
          margin-top: 32px;
        }
      }

      h4 {
        color: $dark-gray-5;
        font-size: 15px;
        font-weight: $semibold;
      }

      .vg-section-title {
        margin: 0 16px 8px;
      }

      .vg-time-reading,
      .vg-rating-star {
        margin-bottom: 24px;
      }

      .vg-comment-input-container {
        padding: 16px;
      }

      textarea {
        border-radius: 5px;
        border-color: $light-gray-1;
        color: $dark-gray-5;
        font-size: 1.3em;
        height: 80px;
        margin-bottom: 10px;
        padding: 10px;
        resize: none;
        width: 100%;
      }

      button {
        &:disabled {
          opacity: 0.5;
        }
      }
    }
  }
}

.vg-transcript {
  font-size: 1.5em;

  &::first-letter {
    text-transform: Uppercase;
  }

  a {
    border-radius: 4px;
    color: $dark-gray-1;
    display: inline;
    line-height: 2.5;
    padding: 8px 2px;
    text-decoration: none;
    transition: background 0.2s $ease-default;

    &:hover {
      background-color: $light-gray-5;
    }
  }
}

.vg-curriculo {
  margin-top: 10px;
  text-align: right;
  width: 100%;
}

.vg-inline-block {
  display: inline-block;
}

.vg-stars-container {
  margin: 0 auto;
  position: relative;
}

.vg-text-avaliation {
  color: $dark-gray-1;
  font-size: 16px;
  font-weight: normal;
  margin: 0 0 7px;
}

.vg-video-user {
  align-items: center;
  display: flex;
  position: relative;

  .vg-rouded-graph {
    height: 85px;
    left: -7.2px;
    position: absolute;
    top: -7.5px;
    width: 85px;

    .circle {
      animation: progress 1s ease-out forwards;
      fill: none;
      stroke-width: 2.3;
      stroke-linecap: round;
    }

    @keyframes progress {
      0% {
        stroke-dasharray: 0 100;
      }
    }
  }

  &-photo {
    border: 4px solid $light-gray-6;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba($black, 0.4);
    height: 40px;
    margin-right: 17px;
    min-width: 40px;
    overflow: hidden;
    position: relative;
    width: 40px;
    z-index: 1;

    img {
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  h1 {
    color: $dark-gray-1;
    font-size: 16px;
    font-weight: $regular;
    margin: 0 0 10px;
  }

  p {
    color: $dark-gray-2;
    font-size: 14px;
    margin: 0;

    .vg-icon {
      fill: $orange;
      stroke: $orange;
    }
  }
}

.vg-empty {
  @include rounded-gray;
  display: block;
  padding: 32px;
  text-align: center;

  .vg-icon-videoOff {
    fill: $light-gray-1;
    height: 48px;
    margin: 0 0 16px 0;
    width: 48px;
  }

  h2 {
    color: $dark-gray-5;
    display: block;
    font-size: 1.5em;
    margin: 0 0 8px;
  }

  p {
    color: $dark-gray-2;
    font-size: 1.5em;
    margin: 0;
  }
}

@mixin tags {
  [class^="vg-tag-"] {
    color: $orange;
    display: inline-block;
    font-size: 1.8em;
    font-weight: $semibold;
    padding: 2px 8px;
  }

  @for $i from 10 through 32 {
    .vg-tag-#{$i} {
      font-size: #{$i}px;
      @if $i <= 18 {
        color: lighten($orange, 25);
      } @else if $i <= 25 {
        color: lighten($orange, 20);
      } @else {
        color: $orange;
        font-weight: $bold;
      }
    }
  }
}

.tag-cloud {
  @include tags;
  text-align: center;
}

.vg-page-list-participants {
  .vg-box-title-interviewListNot {
    margin-bottom: 16px;
    padding-top: 16px;
    h3 {
      color: $dark-gray-1;
      font-size: 2.4em;
      font-weight: 600;
      line-height: 1;
      margin: 0 0 8px;
    }
    p {
      margin: 8px 0 0;
    }
  }
  .vg-item-participant {
    @include rounded-white;
    position: relative;
    display: flex;
    margin: 0 0 16px 0;
    width: 100%;

    &::after {
      content: "";
      clear: both;
      display: inherit;
    }

    .vg-img-participant {
      height: 100%;
      min-width: 108px;
      min-height: 80px;
      position: relative;
      width: 10%;

      .vg-img {
        display: block;
        height: 100%;
        object-fit: cover;
        position: absolute;
        width: 100%;
      }
    }

    .vg-item-data {
      float: left;
      height: 80px;
      line-height: 1.6em;
      padding: 16px;
      text-decoration: none;
      width: 100%;

      > div {
        display: inline-block;
      }

      > div.vg-buttons-right {
        display: block;
      }

      .vg-item-infos {
        float: left;
        width: 20%;
        .vg-name {
          color: $dark-gray-1;
          display: block;
          font-size: 1.6em;
          font-weight: $semibold;
          line-height: 1.5em;
          overflow-x: hidden;
          padding-bottom: 4px;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        > span {
          color: $dark-gray-2;
          font-size: 1.4em;
          font-weight: $semibold;

          .vg-icon {
            fill: $dark-gray-2;
            margin: 0 8px 0 0;
          }

          &.vg-relevance {
            padding: 0 16px 0 0;
          }
        }
      }

      .vg-types {
        display: inline-flex;
        float: left;
        line-height: 1.6em;
        padding: 16px 0 0;
        text-align: center;
        width: 60%;

        > div {
          display: inline-block;
          margin-left: 8px;
          text-align: center;
          width: 20%;

          .vg-item-analysis-label {
            display: none;
          }

          p {
            color: $dark-gray-1;
            font-size: 1.3em;
            font-weight: $semibold;
            margin: 0;
            padding: 0;

            em {
              color: $dark-gray-3;
              font-style: normal;
              font-weight: $regular;
            }
          }

          &.vg-rating {
            float: left;
            line-height: 1.6em;

            .vg-rating-star {
              label {
                cursor: none;
                pointer-events: none;

                &:hover {
                  cursor: none;
                  pointer-events: none;
                }
              }
            }

            .vg-icon-star {
              height: 16px;
              width: 16px;
            }
          }
        }
      }
      .vg-btn-interview {
        font-size: 1.5em;
        line-height: 1.2em;
        margin-top: 6px;
        position: relative;
        top: 50%;
        @media (max-width: $desktop-width) {
          display: inline-block;
          margin-top: 8px;
          text-align: center;
          width: 100%;
        }
      }

      .vg-btn-interview.visitor {
        margin-top: 6px;
      }
    }

    &.vg-not-responded {
      position: relative;

      .vg-img-participant {
        background-color: $light-gray-2;
        height: 100%;
        left: 0;

        .vg-icon-timeFilled {
          display: block;
          fill: $light-gray-1;
          height: 24px;
          left: 50%;
          margin: -14px 0 0 -14px;
          position: absolute;
          top: 50%;
          width: 24px;
        }
      }

      .vg-item-data {
        display: flex;
        width: 100%;
        .vg-item-infos {
          width: 70%;
          @media (max-width: $desktop-width) {
            width: 100%;
          }
        }
        .vg-interview-status {
          align-items: center;
          display: flex;
          justify-content: flex-end;
          width: 100%;

          @media (max-width: $desktop-width) {
            margin-top: 8px;
            justify-content: flex-start;
          }
        }
      }
    }

    &.vg-participant-header {
      background-color: transparent;
      box-shadow: 0 0px 0px rgba($black, 0);
      display: block;
      margin: 0;

      .vg-img-participant {
        min-height: auto;
      }

      .vg-item-data {
        height: auto;
        padding: 0 0 0 143px;
        width: 100%;

        .vg-item-infos,
        .vg-rating,
        .vg-types > div {
          p {
            color: $dark-gray-2;
            cursor: pointer;
            display: inline-block;
            font-size: 1.3em;
            font-weight: $regular;

            &.token-share {
              cursor: auto;
            }

            &:not(.order-false):after {
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 7px solid rgba(188, 188, 188, 0.52);
              content: "";
              cursor: default;
              display: inline-block;
              height: 0;
              margin-left: 8px;
              vertical-align: middle;
              width: 0;
            }

            &.order-true:after {
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 7px solid;
              content: "";
              cursor: default;
              display: inline-block;
              height: 0;
              margin-left: 8px;
              vertical-align: middle;
              width: 0;
            }

            &.order-false:after {
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-bottom: 7px solid;
              content: "";
              cursor: default;
              display: inline-block;
              height: 0;
              margin-left: 8px;
              vertical-align: middle;
              width: 0;
            }

            &.token-share:not(.order-false):after {
              content: "";
              visibility: hidden;
            }
          }
        }
        .vg-rating {
          text-align: center;
        }
      }

      .vg-extended-left-margin {
        padding-left: 162px;
      }
    }
    &.vg-participant-header-mobie {
      display: none;
    }

    &:hover {
      .vg-play-video {
        background-color: rgba($black, 0.5);
      }
    }
  }
}

.vg-title-loader {
  width: 100%;

  .vg-icon-loaderList {
    display: block;
  }
}

.vg-list-placeholder {
  @include rounded-white;
  margin: 0 0 12px 0;
  width: 100%;

  .vg-icon-loaderList {
    animation: infiniteFade 0.7s $ease-default both alternate infinite;
    height: auto;
    width: 100%;
  }
}

.vg-video-header {
  margin-bottom: 20px;
  padding: 20px !important;

  h2 {
    color: $dark-gray-1;
    font-size: 1.6em;
    margin: 0 0 8px;
  }

  .vg-rating-star label .vg-icon {
    height: 20px;
    width: 20px;
  }
}

.vg-btn-pagination-internal {
  width: 49px;
  height: 100px;
  background-color: $white;
  position: fixed;
  top: calc(50% - -87px);
  border-radius: 50% 0 0 50%;
  box-shadow: 0 1px 1px 0 rgba($black, 0.2);
  padding: 0;
  cursor: pointer;
  .vg-icon {
    position: absolute;
    top: calc(50% - 9px);
  }
  svg {
    fill: #f7902a;
  }
}
.vg-btn-interview-right {
  right: 0;
  border-radius: 100px 0 0 100px;
  .vg-icon {
    transform: rotate(-90deg);
    left: calc(50% - 4px);
  }
}
.vg-btn-interview-left {
  left: 0;
  border-radius: 0 100px 100px 0;
  .vg-icon {
    transform: rotate(90deg);
    right: calc(50% - 4px);
  }
}

@media (max-width: $large-desktop-width) {
  .vg-btn-pagination-internal {
    display: none;
  }
}

@media (max-width: $desktop-width) {
  .vg-curriculo {
    text-align: center;
  }

  .vg-text-avaliation {
    margin-top: 10px;
  }

  .vg-stars-container {
    margin-bottom: 10px;
  }

  .vg-page-list-participants {
    .vg-item-participant {
      display: block;
      position: relative;
      overflow: visible;

      &.vg-participant-header {
        display: none;
      }

      .vg-img-participant {
        background-color: $white;
        border-radius: 5px 0 0 5px;
        display: block;
        float: none;
        height: 100%;
        overflow: hidden;
        max-width: 100%;
        position: relative;
        width: 100%;

        .vg-play-video {
          display: none;
        }

        .vg-img {
          max-width: 100%;
          position: relative;
          width: 100%;
        }
      }

      .vg-item-data {
        display: inline-block;
        float: none;
        height: auto;
        padding: 8px;
        width: 100%;

        .vg-item-infos {
          text-align: left;
          width: 100%;
        }

        .vg-types {
          align-content: center;
          flex-wrap: wrap;
          justify-content: center;
          width: 100%;

          > div {
            margin-left: 0;
            padding: 0 0 16px 0;
            text-align: left;
            width: 100%;

            p {
              color: $dark-gray-2;
              display: inline-block;
              font-weight: $regular;
            }

            .vg-item-analysis-label {
              display: inline-block;
              font-weight: $semibold;
              font-size: 1.4em;
              margin-right: 4px;
            }

            &.vg-rating {
              padding-bottom: 25px;
              padding-right: 0;
              width: 100%;
            }
          }
        }
      }

      &.vg-not-responded {
        padding: 0;

        .vg-img-participant {
          height: 36px;
          position: relative;
          width: 100%;
        }

        .vg-item-data {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
      }
    }
  }
}

.vg-buttons-right {
  float: right;
  margin: 0;
  @media (max-width: $desktop-width) {
    float: none;
  }
}

.vg-dropdown-right .vg-dropdown-menu {
  @include drop-down-card-corner-position;
}

@include desktop {
  .vg-video-page {
    .vg-container {
      aside {
        border: 0;
        border-left: 1px solid $light-gray-1;
      }
    }
  }
}

.vg-dropdown-rating {
  display: inline-block;
  position: relative;
}

.vg-dropdown-rating-content {
  background-color: $white;
  box-shadow: 0 2px 2px rgba($black, 0.2);
  border-radius: 5px;
  opacity: 0;
  left: -150px;
  min-width: 450px;
  padding: 12px 16px;
  position: absolute;
  text-align: left;
  transform: translate3d(0, 0, 0);
  transition: transform 0.2s $ease-default, opacity 0.5s $ease-default,
    visibility 0.5s ease;
  visibility: hidden;
  z-index: 2;

  h4 {
    color: $dark-gray-5;
    font-size: 1.5em;
    font-weight: $semibold;
    margin: 16px 0 8px;
  }

  .vg-time-reading {
    border-color: $orange;

    .vg-custom-radio {
      border-right: 0;

      .vg-label-text {
        color: $orange;
      }

      .vg-label {
        width: 100.3%;
      }
    }

    .vg-custom-radio + .vg-custom-radio {
      border-left: solid 1px $orange;
    }
  }
}

.vg-dropdown-rating:hover .vg-dropdown-rating-content.vg-active {
  opacity: 1;
  transform: translate3d(0, 10px, 0);
  visibility: visible;
}

.vg-check-all-label {
  color: $orange;
  cursor: pointer;
  font-size: 1.3em;
  font-weight: $semibold;
  line-height: 1.5em;
  margin-top: 1px;
  padding-left: 8px;
}

.vg-video-interview-load-more {
  .vg-load-more {
    @include rounded-white;
    color: $orange;
  }
}
