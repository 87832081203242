.modal-vagas25 {
  .vg-modal-small {
    width: 544px;
    min-height: 390px;
  }
  .vg-modal-header {
    border-bottom: none;
    padding-bottom: 0;
    height: 200px !important;
    background: url("../../../assets/imgModalAjuda.svg") center no-repeat;

    button {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
  .vg-modal-body {
    margin-top: 170px;
    text-align: center;
    padding: 20px;
  }
  .vg-icon {
    height: 54px;
    width: 54px;
  }
  h2 {
    font-size: 18px;
    font-weight: 500;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    &:first-of-type {
      margin-bottom: 30px;
    }
  }
}