//
// Auto Suggest
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-close-process-content {
  .vg-autosuggest {
    &-container {
      margin-bottom: 49px;
      position: relative;

      .vg-icon-search {
        content: "";
        height: 21px;
        opacity: 0.2;
        pointer-events: none;
        position: absolute;
        right: 20px;
        top: 20px;
        width: 21px;
      }

      &.vg-autosuggest-is-searching {
        .vg-icon-search {
          animation: searching 1s $ease-default both infinite alternate;
        }
      }
    }

    &-input {
      @include rounded-white;
      border: 1px solid $light-gray-2;
      font-size: 1.6em;
      height: 60px;
      outline: none;
      padding: 0 20px;
      width: 100%;
    }

    &-list {
      @include rounded-white;
      border: 1px solid $light-gray-2;
      border-radius: 0 0 5px 5px;
      position: absolute;
      top: calc(100% - 4px);
      width: 100%;
      z-index: 3;

      p {
        margin: 0;
        padding: 10px 20px;
      }

      ul {
        list-style: none;
        margin: 0;
        max-height: 160px;
        overflow-y: auto;
        overflow: hidden;
        padding: 0;

        li {
          cursor: pointer;
          padding: 10px 20px;

          &:hover {
            background-color: $light-gray-3;
          }
        }

        span {
          text-align: left;

          &.vg-autosuggest-candidate-name {
            color: $dark-gray-1;
            display: inline-block;
            font-size: 1.4em;
            margin-top: 2px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: calc(100% - 26px);

            em {
              color: $dark-gray-2;
              display: block;
              font-size: 0.71em;
              font-style: normal;
              overflow: hidden;
              text-overflow: ellipsis;
              text-transform: uppercase;
              white-space: nowrap;
              width: 100%;
            }

            strong {
              color: $black;
              font-weight: $regular;
            }
          }
        }

        .vg-icon {
          fill: $light-gray-1;
          vertical-align: bottom;

          &-candidate {
            float: left;
            margin-right: 8px;
            margin-top: 7px;
          }
        }
      }
    }
  }
}

@keyframes searching {
  0% {
    fill: $black;
  }

  10% {
    fill: $orange;
    opacity: 0.8;
  }

  50% {
    transform: scale(0.8);
  }

  80% {
    fill: $orange;
    opacity: 0.8;
  }

  100% {
    fill: $black;
    opacity: 0.2;
    transform: scale(1);
  }
}
