@import "../../../styles/base/global";

.vg-video-detail-ratings-list-item {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding-bottom: 16px;
  padding-left: 8px;
  padding-right: 8px;

  .vg-icon-star {
    width: 10px;
    height: 10px;
  }

  .vg-item-avatar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px;
  }

  .vg-item-email {
    font-size: 1.4em;
    line-height: 1.71;
    letter-spacing: normal;
    color: $dark-gray-2;
  }

  .vg-item-date {
    font-size: 1.4em;
    line-height: 1.14;
    color: $light-gray-1;
  }
}
