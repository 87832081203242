//
// Lists
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

// color variables
$status-red-1: #ce4146 !default;
$status-red-2: #b93b3f !default;
$status-green-1: #2dac3a !default;
$status-green-2: #299b34 !default;
$status-orange-1: #f59434 !default;
$status-orange-2: #de8226 !default;

.flex-align-center {
  display: flex;
  align-items: center;
}
.vg-status {
  @include default-drop-shadow;
  border-radius: 20px;
  color: $white;
  display: inline-block;
  font-size: 1.4em;
  line-height: 1.8em;
  font-weight: $semibold;
  vertical-align: center;
  margin-top: 10px;
  overflow: hidden;
  padding: 1px 10px 1px 6px;
  position: relative;
  vertical-align: middle;

  [class^="vg-icon"] {
    display: inline-block;
    fill: $white;
    width: 16px;
  }

  &.vg-agendada {
    @include linear-degrade($status-orange-1, $status-orange-2);
  }

  &.vg-veiculacao-suspensa {
    @extend .flex-align-center;
    @include linear-degrade($status-red-1, $status-red-2);
  }

  &.vg-fechada-com-contratacao,
  &.vg-fechada-sem-contratacao {
    @extend .flex-align-center;
    @include linear-degrade($status-green-1, $status-green-2);
  }

  &.vg-em-andamento-inscricao-encerrada {
    @extend .flex-align-center;
    @include linear-degrade($status-orange-1, $status-orange-2);
  }

  .vg-icon {
    margin-right: 6px;
    vertical-align: center;
  }

  .vg- {
    &icon-clock {
      stroke: $light-gray-5;
    }
  }
}

@include desktop {
  .vg-status {
    display: inline-block;
    margin-top: 0;
  }
}
