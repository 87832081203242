//
// BoxTitle
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-box-title {
  background-color: $white;
  box-shadow: 0 2px 2px rgba($black, 0.2);
  line-height: 1.5em;
  margin-bottom: 32px;
  padding: 40px 15px 48px;
  text-align: center;

  &-second {
    background-color: transparent;
    box-shadow: none;
    padding: 40px 15px 0px;
  }

  h1 {
    color: $dark-gray-1;
    font-size: 2.4em;
    font-weight: $semibold;
    line-height: 1;
    margin-top: 0;
  }

  p {
    color: $dark-gray-1;
    font-size: 1.5em;
    font-weight: $regular;
    line-height: 1.2;
    margin-bottom: 0;
  }
}
