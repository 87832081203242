//
// SupportInfo
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.vg-support-info {
  padding-bottom: 24px;

  h1 {
    margin: 24px;
  }

  h3 {
    margin: 8px 24px 0;
  }

  p {
    margin: 0 24px;
    display: flex;
    align-items: center;

    .vg-icon {
      margin-right: 8px;
      width: 16px;
    }
  }
}
