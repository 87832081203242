//
// Utilities
// -----------------------------------------------------------------------------

// Toggling
.hide,
.hidden {
  display: none;
}

.show {
  display: block;
}

.vg-overflow-h {
  overflow: hidden;
}

.vg-text-center {
  text-align: center;
}

.vg-text-right {
  text-align: right;
}

// Visibility
.invisible {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

// Clearing floats
.clearfix {
  @include clearfix;
}

// Accessible yet invisible text
.hide-text {
  @include hide-text;
}

// No pointer events
.no-pointer-events {
  pointer-events: none;
}

.vg-responsive-img {
  display: inline-block;
  width: 100%;
}

.vg-content-box {
  @include rounded-white;
  font-size: 1.5em;
  padding: 22px;

  p {
    line-height: 1.5;
  }
}

// Grid Utilities
@include desktop {
  .vg-hide-lg {
    display: none;
  }
}

@media (max-width: #{$desktop-width}) {
  .vg-hide-md {
    display: none;
  }
}

@media (max-width: #{$tablet-width}) {
  .vg-hide-sm {
    display: none;
  }
}

@media (max-width: #{$mobile-width}) {
  .vg-hide-xs {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  clip-path: inset(50%);
  white-space: nowrap;
}
