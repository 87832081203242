.spacing {
  margin-bottom: 24px;
}

.vg-display-flex {
  display: flex;
  font-style: normal;

  &.vg-card-block {
    display: block;
    text-align: center;

    p {
      color: #667085;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      min-height: 40px;
    }
  }

  h2 {
    margin-top: 0;
    font-size: 18px;
    font-weight: 500;
  }

  .vg-cnt-inline-block {
    display: inline-block;
  }

  > div {
    &:first-of-type {
      margin-right: 12px;
      width: 30px;
    }
    p {
      color: #667085;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      min-height: 40px;
    }
    .vg-content-btns {
      margin-top: 16px;
      text-align: left;

      &.vg-ctn-left {
        text-align: left;
      }

      > a:first-of-type {
        margin-right: 12px;
        margin-bottom: 12px;
      }
    }
    .vg-icon-error_circle {
      height: 20px;
      width: 20px;
      margin-right: 8px;
      margin-top: -4px;
    }
    .vg-text-error {
      color: #D92D20;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
    }

    .vg-text-credits {
      display: block;
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      text-align: left;
      color: #FB6501;
    }
  }
}

.vg-btn-header-gray {
  background-color: white !important;
  border: 1px solid #D0D5DD !important;
  color: #797979 !important;
  margin-top: 16px;
  font-size: 14px;
  text-align: left;

  .vg-icon-plus {
    fill: #797979;
    margin-right: 10px;
    height: 11px;
    width: 12px;
    top: -2px;
    position: relative;
  }

  &:hover {
    color:#FB6501 !important;
    border-color: #FB6501 !important;
    box-shadow: 0 4px 3px rgba(0, 0, 0, 0.3) !important;

    .vg-icon-plus {
      fill: #FB6501 !important;
    }
  }
}

.vg-icon-bg-border {
  background: #FDEAD7;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 5px solid #FEF6EE;
  display: block;
  text-align: center;

  &.bg-border-gray {
    background: #F2F4F7;
    border: 5px solid #F9FAFB;
    display: inline-block;
  }

  &.vg-icon-sm  > .vg-icon{
    width: 15px;
    height: 15px;
    svg {
      margin-top: -4px;
      > path {
        margin-top: -2px;
        fill: rgb(71, 84, 103);
      }
    }
  }
}

.vg-btn-float-right {
  float: right;
}

.vg-v25-b {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #344054;
  margin-right: 16px;
}

.vg-v25-span {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #667085;

}

.vg-graph-v25 {
  width: 100% !important;
  padding: 0;
  box-shadow: none !important;

  .vg-icon-graphLine {
    max-height: 120px;
  }

  .vg-error-handler {
    padding: 0 !important;
  }
}