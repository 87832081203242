//
// Lista de SLA
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-sla-item {
  @include rounded-white;
  display: block;
  margin: 0 0 25px;
  overflow: hidden;
  transition: box-shadow 0.2s $ease-default;
  width: 100%;

  &:hover {
    @include rounded-white-hover;
  }

  &.vg-active {
    .vg-sla-header {
      background-color: $light-gray-5;

      .vg-icon-arrow {
        transform: rotate(180deg);
        transform-origin: 50% 50%;
      }
    }
  }

  .vg-sla-header {
    display: block;
    padding: 32px;
    transition: background 0.2s $ease-default;

    h2 {
      color: $dark-gray-1;
      display: inline-block;
      font-size: 1.5em;
      font-weight: $semibold;
      margin: 0 0 0 26px;
      width: calc(100% - 25px);
    }

    .vg-sla-status-wrapper {
      position: relative;
      width: calc(100% - 25px);
    }

    .vg-sla-status {
      @extend %vertical-center;
      background-color: $light-gray-1;
      border-radius: 50%;
      display: inline-block;
      height: 16px;
      margin-right: 10px;
      vertical-align: bottom;
      width: 16px;

      &.vg-sla-disabled {
        background-color: $dark-pink;
      }

      &.vg-sla-enabled {
        background-color: $green;
      }
    }

    .vg-sla-orange {
      float: right;
      font-weight: $semibold;
      text-align: right;

      span > span:first-of-type {
        display: none;
      }

      .vg-sla-link {
        color: $orange;
        display: inline-block;
      }
    }

    .vg-icon-arrow {
      fill: $orange;
      height: 15px;
      margin-left: 10px;
      transform-origin: 50% 50%;
      transition: transform 0.2s $ease-default;
      vertical-align: middle;
      width: 15px;
    }
  }

  .vg-animation {
    transition: max-height 0.2s $ease-default, padding 0.2s $ease-default,
      opacity 0.8s $ease-default, transform 0.2s $ease-default;
  }

  .vg-sla-content {
    overflow: hidden;
    padding-left: 30px;
    padding-right: 30px;

    h3 {
      font-size: 1.4em;
      margin: 0;
    }

    .vg-custom-select {
      margin-bottom: 0;
    }

    .vg-row .vg-row {
      border-bottom: 1px solid $light-gray-2;
      padding-bottom: 20px;
      padding-top: 20px;

      &:last-of-type {
        border-bottom: 0;
      }
    }

    .vg-btn-success {
      float: right;
      margin-top: 10px;
    }
  }
}

.vg-sla-box-type {
  background-color: $light-gray-3;
  margin-top: 23px;
  padding: 20px;
  position: relative;
  width: 100%;

  &::before {
    border: 10px solid transparent;
    border-bottom-color: $light-gray-3;
    content: "";
    left: 20px;
    position: absolute;
    top: -20px;
  }

  &.vg-second {
    &::before {
      left: 138px;
    }
  }

  &.vg-third {
    &::before {
      left: 264px;
    }
  }

  .vg-input-number {
    margin-bottom: 40px;

    &:last-of-type {
      margin-bottom: 0;
    }

    label {
      font-size: 1.4em;
      font-weight: $semibold;

      &.vg-input-number-prefix {
        display: inline-block;
        margin-right: 20px;
        min-width: 300px;
      }

      &.vg-input-number-suffix {
        margin-left: 20px;
      }
    }
  }
}

.vg-sla-content-setting {
  padding-bottom: 20px;

  .vg-btn-success {
    width: 100%;
  }

  &.vg-row,
  .vg-row {
    margin: 0;
  }

  .vg-col-xs-12 {
    padding: 0;
  }

  .vg-custom-radio,
  .vg-custom-checkbox {
    margin-top: 20px;
  }

  .vg-custom-radio-sla {
    display: flex;
    flex-direction: column;
  }

  .vg-label-sla {
    margin-bottom: 10px;
  }

  .vg-subtitle-sla {
    font-size: 0.875rem;
  }

  .vg-input-number-prefix {
    margin-bottom: 20px;
    width: 100%;
  }

  .vg-custom-select {
    display: block;
    margin-top: 20px;
    width: 100%;
  }

  .vg-disabled {
    opacity: 0.5;
    pointer-events: none;
    position: relative;

    &::before {
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 3;
    }
  }
}

@include desktop {
  .vg-sla-item {
    .vg-sla-content-setting {
      .vg-custom-select {
        margin-top: 0;
      }
    }
  }
}

@include tablet {
  .vg-sla-item {
    .vg-sla-header {
      .vg-sla-link {
        color: $dark-gray-1;
        display: inline-block;
        font-size: 1.5em;
      }

      .vg-sla-orange {
        float: none;

        span > span:first-of-type {
          display: inline-block;
        }
      }

      .vg-icon-arrow {
        height: 10px;
        width: 10px;
      }
    }

    .vg-sla-content-setting {
      .vg-custom-checkbox {
        margin-top: 0;
      }

      .vg-custom-select {
        margin-top: 0;
      }

      .vg-input-number-prefix {
        margin-bottom: 0;
        width: auto;
      }

      .vg-input-number {
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .vg-btn-success {
        width: auto;
      }
    }
  }
}
