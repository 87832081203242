//
// Steps
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-steps-bullet {
  background-color: $light-green-2;
  border-radius: 20px;
  padding: 10px;

  &-orange {
    background-color: $orange;
  }

  li {
    color: $white;
    display: none;
    font-size: 1.2em;
    font-weight: $semibold;

    &::after {
      display: none;
    }

    &.vg-active {
      display: inline-flex;

      &::after {
        content: attr(data-total);
        margin-left: 4px;
      }

      .vg-bullets-button {
        &::after {
          content: attr(data-total);
          margin-left: 5px;
        }
      }
    }

    .vg-bullets-button {
      @include reset-button;
      color: $white;
      height: 100%;
      pointer-events: none;
      width: 100%;

      &:focus {
        outline: none;
      }
    }
  }

  &.vg-steps-bullet-reduce {
    li {
      color: $white;
      display: none;

      span {
        display: none;
      }

      &.vg-active {
        display: inline-flex;
        padding: 0 10px;
        margin: 0;

        &.no-click {
          &::after {
            content: attr(data-total);
            margin-left: 5px;
            display: block;
          }
        }

        .vg-bullets-button {
          padding: 0 10px;
          pointer-events: none;

          &::after {
            content: attr(data-total);
            margin-left: 5px;
            display: inline-block;
          }
        }
      }
    }
  }
}

@include desktop {
  .vg-steps-bullet {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0 0 24px;
    padding: 0;
    background: transparent;
    border-radius: 0;

    li {
      align-items: center;
      background-color: $light-green-2;
      border-radius: 13px;
      display: flex;
      height: 26px;
      justify-content: center;
      margin: 0 64px 0 8px;
      min-width: 26px;
      position: relative;

      &:after {
        display: none;
      }

      span {
        background: $light-gray-3;
        border-radius: 8px;
        height: 4px;
        overflow: hidden;
        position: absolute;
        right: calc(100% + 8px);
        width: 56px;

        &::before {
          background-color: $light-green-2;
          bottom: 0;
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          content: "";
        }
      }

      &:first-of-type {
        margin-left: 8px;

        span {
          display: none;
        }
      }

      &:last-of-type {
        margin-right: 8px;
      }

      &.vg-active {
        ~ li {
          background-color: $light-gray-1;

          span {
            &::before {
              display: none;
            }
          }

          &:last-of-type {
            &::before {
              display: none;
            }
          }
        }

        + li {
          span {
            &::before {
              @include linear-degrade($light-green-2, $light-gray-3);
              display: block;
            }
          }
        }
      }

      .vg-bullets-button {
        pointer-events: visible;

        &::after {
          display: none;
        }
      }
    }

    &-orange {
      li {
        background-color: $orange;

        span {
          background: $light-gray-3;

          &::before {
            background-color: $orange;
          }
        }

        &.vg-active {
          ~ li {
            background-color: $light-gray-1;
          }

          + li {
            span {
              &::before {
                @include linear-degrade($orange, $light-gray-3);
              }
            }
          }
        }
      }
    }
  }
}
