//
// DropDown Menu
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.vg-dropdown-menu {
  .vg-dropdown-list.vg-dropdown-button-list {
    padding-bottom: 15px;
    padding-top: 15px;
    li {
      a {
        font-size: 1.4em;
        font-weight: normal;
        line-height: 1.4;
        padding: 5px 20px;
        &:hover {
          background-color: $light-gray-3;
        }
      }
      &.vg-margin-5 {
        margin-bottom: 5px;
      }

      &.vg-line-top {
        border-top: 1px solid $light-gray-2;
        margin-top: 8px;
        padding-top: 8px;
      }
    }
  }
}

.vg-btn-outline-light-gray + .vg-dropdown-list.button-list {
  li {
    a {
      font-weight: normal;
      &:hover {
        color: $orange;
        background-color: $light-gray-3;
      }
    }
  }
}
