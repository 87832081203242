//
// Select
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-select {
  background-color: $white;
  border: solid 1px $light-gray-1;
  display: block;
  font-size: 1.3em;
  padding: 5px;
  margin: 16px 20px 0 0;
  height: 36px;

  &.vg-select-redes {
    margin: 0;
  }
}

@include desktop {
  .vg-select.vg-select-redes {
    min-width: 300px;
    margin: 0;
  }
}
