.vg-professional-page {
  .vg-box-title {
    margin-bottom: 1px;

    ~ div {
      margin-bottom: 24px;
    }

    ~ .vg-container {
      margin-top: 24px;
    }
  }
}
