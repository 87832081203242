@import "../../../styles/base/global";

.vg-check-all {
  margin-left: 10px;
  position: absolute;
  margin-top: 12px;

  .vg-input-checkbox-inner {
    margin-top: 2px !important;
  }
}

.vg-name-vacancy {
  &.vg-videointerview-name-vacancy {
    font-weight: 600;
    font-size: 1.9em; // ~83% do h1 da página (vide Figma)
    &:not(:first-of-type) {
      margin-top: 60px;
    }
  }
}
