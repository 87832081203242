//
// Status (Requisição)
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.vg-request-status {
  border: 1px solid $green;
  color: $green;
  border-radius: 25px;
  display: inline-flex;
  align-items: center;
  padding: 4px 8px 4px 4px;
  font-size: 1.3em;
  font-weight: $semibold;

  .vg-icon {
    @include size(16px, 16px);
    margin-right: 8px;
    fill: $green;
  }

  .vg-request-responsible-approval {
    margin-left: 4px;
  }

  .vg-request-status-code {
    margin-left: 8px;
  }

  &-cancelada,
  &-reprovada,
  &-reprovada-pre-aprovacao {
    color: $red-status-2;
    border-color: $red-status-2;

    .vg-icon {
      fill: $red-status-2;
    }
  }

  &-aprovada-sem-vaga,
  &-aguardando-pre-aprovacao,
  &-aguardando-definicao,
  &-aguardando-envio,
  &-processo-de-aprovacao {
    color: $light-orange;
    border-color: $light-orange;

    .vg-icon {
      fill: $light-orange;
    }
  }
}
