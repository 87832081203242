//
// Link
// -----------------------------------------------------------------------------
@import "../../../../styles/base/global";
@import "../BoxGrade/BoxGrade";

.grid-grade {
  & .box-select-grade {
    width: 152px;
  }

  & .box-phase-grade-limite {
    @media (min-width: $large-mobile-width) {
      padding-top: 8px;
    }
    @media (max-width: $desktop-width) {
      width: 100%;
    }
  }
}
