//
// Navigation
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-navigation {
  display: block;
  margin-top: 1px;
  padding: 0;
  padding-top: $header-height;
  text-align: center;
  width: 100%;

  ul {
    list-style: none;
    padding: 0;
    position: relative;

    li {
      display: block;
      margin-bottom: 30px;

      &:last-child {
        margin-right: 0;
      }

      a {
        color: $white;
        font-size: 1.8em;
        font-weight: $semibold;
        line-height: 0;
        position: relative;
        text-decoration: none;

        &::after {
          @include horizontal-centered;
          background-color: $white;
          border-radius: 4px;
          content: "";
          height: 2px;
          opacity: 0;
          position: absolute;
          top: 108%;
          transform: translate3d(-50%, 10px, 0);
          transition: opacity 0.2s $ease-default, transform 0.2s $ease-default,
            width 0.4s $ease-default;
          width: 4px;
          will-change: transform;
        }

        &:hover,
        &.vg-link-active {
          &::after {
            opacity: 1;
            transform: translate3d(-50%, 5px, 0);
            width: 100%;
          }
        }
      }
    }
  }
}

@include desktop {
  .vg-navigation {
    display: inline-block;
    padding: 0;
    width: calc(100% - #{$header-user-navigation});

    ul li {
      display: inline-block;
      margin-bottom: 0;
      margin-right: 33px;
      position: relative;

      &:hover {
        .vg-submenu {
          display: block;
        }
      }

      a {
        font-size: 1.4em;
        font-weight: $semibold;
      }
    }
  }
}

.header-search-icon {
  position: absolute;
  right: 224px;
  top: 6px;

  > box-icon {
    fill: #fff;
  }
}

.header-notification-icon {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 264px;
  top: 4px;
}

.vg-bell-ring {
  background-color: #12b76a;
  border-radius: 100%;
  height: 0.425rem;
  position: absolute;
  right: 8px;
  top: -8px;
  width: 0.425rem;
}
