//
// Modal
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-sidebar {
  @include default-drop-shadow;
  color: $dark-gray-1;
  background: $white;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  z-index: 4;
  overflow-y: auto;
  overflow-x: hidden;

  .vg-icon-cross {
    fill: $dark-gray-1;
    height: 30px;
    width: 25px;
    float: right;
    margin: 15px;
    cursor: pointer;
  }
}

@include desktop {
  .vg-sidebar {
    width: 430px;
  }
}
