//
// Link
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.container-grid-grade {
  & a {
    cursor: pointer;
  }

  & .grid-grade-children {
    text-align: left;
  }

  & .grid-add-grid {
    color: $light-orange-4;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
  }
  & .div-check-box-grade {
    padding-bottom: 16px;
    text-align: left;
  }
  & .title-grade {
    font-size: 1.4em;
    margin: 4px 0;
    text-align: left;
  }
  & .subtitle-grade {
    font-size: 1em;
    text-align: left;
  }

  & .label-container-grade {
    display: inline-flex;
    cursor: pointer;
    font-size: 12px;
    padding: 0px 6px;
  }

  & .div-container-tooltip-mobile {
    position: relative;
  }
  & .div-container-tooltip {
    position: relative;
    display: none;
  }
  @media (min-width: $tablet-width) {
    & .div-container-tooltip {
      display: block;
    }
    & .icon-more-info-range {
      left: auto;
      position: relative;
      top: auto;
    }
    & .div-container-tooltip-mobile {
      display: none;
    }
  }

  & .icon-more-info-range {
    left: 139px;
    position: absolute;
    top: -23px;
  }
}
