//
// Header da fase
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-phase-header {
  align-items: center;
  display: flex;

  &-drag {
    @include reset-button;
    height: 20px;
    pointer-events: none;
    width: 19px;

    .vg-icon {
      fill: $orange;
    }
  }

  &-index {
    align-items: center;
    background-color: $light-gray-5;
    border-radius: 40px;
    color: $orange;
    display: inline-flex;
    font-size: 1.8em;
    font-weight: $semibold;
    height: 40px;
    justify-content: center;
    margin: 0 22px;
    min-width: 40px;
    padding: 14px;
    text-align: center;
    transition: background 0.2s $ease-default;
  }

  &-title {
    flex-grow: 2;

    small {
      color: $dark-gray-2;
      font-size: 13px;
      font-weight: $regular;
      text-transform: uppercase;
    }
  }

  &-orange {
    color: $orange;
    font-size: 1.5em;
    font-weight: $semibold;

    .vg-link-text {
      display: none;
    }

    .vg-icon-arrow {
      fill: $orange;
      height: 15px;
      margin-left: 10px;
      transform-origin: 50% 50%;
      transition: transform 0.8s $ease-default;
      vertical-align: middle;
      width: 15px;
    }
  }
}

.vg-collapse-item.vg-active {
  .vg-phase-header {
    &-index {
      background-color: $white;
    }
  }
}

@include desktop {
  .vg-phase-header {
    &-orange {
      .vg-link-text {
        display: inline-block;
      }
    }
  }
}
