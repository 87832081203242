//
// Tag
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.vg-tag {
  background-color: $orange;
  border-radius: 4px;
  color: $white;
  display: inline-block;
  padding: 2px 6px;
}
