//
// VacanciesRequestList
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.vg-list-wrap-request {
  .vg-list-item {
    @include rounded-white;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    padding: 24px 16px;
    position: relative;

    .vg-dropdown-menu {
      @include drop-down-card-corner-position;
    }

    .vg-list-time {
      align-items: center;
      display: inline-flex;

      .vg-icon {
        @include size(16px, 16px);
        fill: $orange;
        margin-right: 8px;
      }

      .vg-time-days {
        font-size: 1.3em;
        color: $dark-gray-2;

        strong {
          color: $dark-gray-1;
          font-weight: $semibold;
        }
      }
    }

    .vg-list-content {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 16px 0 0;
      width: 100%;

      &-title {
        width: 100%;
      }

      .vg-request-detail {
        margin: 0;
        font-size: 10px;

        &-name {
          font-size: 1.6em;
          font-weight: $semibold;
          color: $dark-gray-1;
        }

        &-code {
          font-size: 1.3em;
          font-weight: $regular;
          color: $dark-gray-2;
          margin-left: 8px;
        }
      }

      .vg-request-info {
        color: $dark-gray-1;
        font-size: 1.3em;
        font-weight: $semibold;
        margin: 8px 0 0;

        .vg-icon {
          background-color: $light-gray-4;
          border-radius: 50%;
          width: 24px;
          height: 24px;
          padding: 5px;
          margin-right: 8px;
        }

        > span {
          align-items: center;
          display: flex;

          + span {
            margin-top: 8px;
          }
        }
      }
    }

    .vg-request-status {
      width: auto;
    }

    .vg-list-buttons {
      display: flex;
      flex-direction: column;
      width: 100%;

      span + span {
        margin-top: 8px;
      }
    }
  }
}

@include desktop {
  .vg-list-wrap-request {
    .vg-list-item {
      flex-direction: row;
      padding: 0;

      .vg-list-time {
        border-right: 1px solid $light-gray-1;
        flex-direction: column;
        justify-content: center;
        max-width: 110px;
        text-align: center;
        width: 10%;

        .vg-icon {
          @include size(24px, 24px);
          display: block;
          margin: 0 auto 8px;
        }

        .vg-time-days {
          font-size: 1.6em;
        }
      }

      .vg-list-content {
        align-items: center;
        flex-direction: row;
        padding: 24px;

        &-title {
          max-width: 35%;
        }
      }

      .vg-list-buttons {
        flex-direction: row;
        width: auto;

        span + span {
          margin-left: 8px;
          margin-top: 0;
        }
      }
    }
  }
}

@media (min-width: 1440px) {
  .vg-list-wrap-request {
    .vg-list-item {
      .vg-list-content {
        &-title {
          max-width: 45%;
        }
      }
    }
  }
}
