.vg-search-requisition {
  margin-bottom: 25px;
}

.vg-btn {
  text-decoration: none;
  background: transparent;
  &:active:not(:disabled),
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background: transparent;
    box-shadow: none;
  }
  font-size: 0.9em;
  font-weight: 600;
}
