@import "../../../styles/base/global";

.vg-shortcuts-container {
  @include rounded-white;
  display: block;
  margin: 0 0 25px;
  padding: 24px;
  width: 100%;

  h3 {
    color: $dark-gray-1;
    display: inline-block;
    font-size: 1.2rem;
    font-weight: $semibold;
    width: calc(100% - 25px);
  }
}

.vg-shortcuts-wrapper {
  align-items: center;
  display: flex;
  font-size: 0.875rem;
  justify-content: space-between;
  margin-bottom: 16px;

  svg {
    margin-right: 10px;
  }

  p {
    font-weight: $semibold;
    margin-bottom: 8px;
    margin-top: 6px;
  }
}

.vg-shortcuts-text {
  display: flex;
}

.vg-shortcuts-link-wrapper {
  border-top: 1px solid $light-gray-3;
  margin-top: 30px;
  padding-top: 16px;
}

.vg-shortcuts-link {
  color: $orange;
  font-size: 0.875rem;
  font-weight: $semibold;
  line-height: 24px;
}

.vg-shortcuts-link-button {
  border: 1px solid $light-gray-1;
  border-radius: 2px;
  color: $dark-gray-1;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: $semibold;
  padding: 8px 12px;
  text-decoration: none;
}
