//
// Tabs
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-language {
  display: block;
  margin: 0 auto 90px auto;
  position: relative;
  width: 140px;

  button {
    appearance: none;
    background: transparent;
    border: 0;
    color: $dark-gray-1;
    cursor: pointer;
    font-size: 1.3em;
    padding: 0;
    vertical-align: middle;
    outline: none;
    width: 100%;
    text-align: left;
    transition: opacity 0.2s $ease-default;
  }

  button.vg-language-trigger {
    @include rounded-white;
    padding: 8px;
  }

  .vg-icon {
    margin-right: 8px;
    vertical-align: middle;
    width: 26px;

    &-check {
      fill: $light-green;
      height: 12px;
      margin-left: 8px;
      margin-right: 0;
      width: 12px;
    }

    &-arrow {
      fill: $dark-gray-1;
      float: right;
      height: 8px;
      margin-left: 8px;
      margin-top: 5px;
      transform: rotate(-90deg);
      transition: transform 0.2s $ease-default;
      width: 6px;
    }
  }

  &.vg-active {
    .vg-language-options {
      opacity: 1;
      pointer-events: auto;
      transform: translate3d(0, 0, 0);
    }

    .vg-icon {
      &-arrow {
        transform: rotate(90deg);
      }
    }
  }

  .vg-language-options {
    @include rounded-white;
    bottom: 100%;
    left: 0;
    list-style: none;
    opacity: 0;
    padding: 8px;
    pointer-events: none;
    position: absolute;
    transform: translate3d(0, -10px, 0);
    transition: opacity 0.2s $ease-default, transform 0.2s $ease-default;
    width: 100%;

    li {
      margin-bottom: 8px;

      button:hover {
        opacity: 0.7;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

@include desktop {
  .vg-language {
    margin: 0;
    margin-bottom: 0;
    position: absolute;
    right: 0;
    top: 16px;
  }
}
