@import "../../../styles/base/global";

.vg-atom-location {
  align-items: center;
  display: flex;

  .vg-icon-location {
    margin-right: 6px;
    height: 16px;
    width: 16px;
    svg {
      fill: $dark-gray-5;
    }
  }

  .vg-atom-location-label {
    @include text-overflow;
    display: inline-block;
    font-size: 1.4em;
    line-height: 1.8;
    margin-right: 8px;
    max-width: 200px;
    vertical-align: bottom;
  }
}
