// Search
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.vg-filter-partipants {
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  margin-bottom: 15px;

  .vg-filter-partipants-container {
    @include rounded-white;
    width: 100%;
  }
  .vg-open-filter {
    @include reset-button;
    position: absolute;
    right: 0;
    top: 0;
    fill: $orange;
    padding: 10px 20px;
  }

  .vg-all-filter {
    height: 100%;
    width: 100%;
    display: none;
    flex-wrap: wrap;

    &.vg-filter-open {
      display: flex;
    }
  }

  .vg-input-search {
    padding: 10px 30px 10px 16px;
    width: 100%;

    .vg-icon {
      fill: $dark-gray-2;
      height: 16px;
      margin-right: 8px;
      width: 16px;
    }

    input {
      appearance: none;
      border: 0;
      font-size: 1.3em;
      padding: 4px 0 0;
      width: 89%;

      &:-webkit-autofill {
        box-shadow: 0 0 0 30px $white inset;
      }

      &:focus {
        outline: none;
      }
    }
  }
  .vg-filter {
    border-bottom: 1px solid $light-gray-2;
    position: relative;
    width: 100%;

    .vg-filter-title {
      @include reset-button;
      color: $dark-gray-1;
      cursor: pointer;
      display: block;
      font-family: $open-sans;
      font-size: 1.3em;
      font-weight: 600;
      line-height: 24px;
      min-width: 200px;
      padding: 8px 32px 7px 24px;
      text-align: left;
      width: 100%;
    }

    .vg-icon-arrow {
      fill: $orange;
      height: 10px;
      position: absolute;
      right: 24px;
      top: 16px;
      transition: all 0.3s;
      width: 8px;
    }

    &.vg-filter-open {
      .vg-icon-arrow {
        transform: rotate(-180deg);
      }

      .vg-menu-filter {
        display: block;
      }
    }

    .vg-menu-filter {
      display: none;
      padding: 16px;
      width: 100%;
      z-index: 1;

      .vg-filter-selected {
        margin-bottom: 16px;
        border-bottom: 1px solid $light-gray-1;

        .vg-filter-scroll {
          height: 96px;
          padding-bottom: 16px;
        }
      }

      .vg-filter-scroll {
        display: block;
        max-height: 160px;
        overflow: auto;

        &.selected {
          border-bottom: 1px solid $light-gray-1;
          margin-bottom: 16px;
        }
      }

      .vg-custom-check {
        width: 100%;
        margin-bottom: 16px;

        &.hide {
          display: none;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        label {
          color: $dark-gray-1;
          cursor: pointer;
          display: block;

          .vg-label-text,
          .vg-five-stars {
            display: inline-block;
            font-size: 1.3em;
            margin-top: -1px;
            padding: 0 0 0 8px;
            width: 80%;
          }
        }
      }
    }
  }
}
@include desktop {
  .vg-filter-partipants {
    overflow: visible;
    margin-bottom: 24px;

    .vg-open-filter {
      display: none;
    }

    .vg-filter-partipants-container {
      display: flex;
      height: 40px;
      width: auto;
    }

    .vg-input-search {
      width: 322px;
      border-right: 1px solid $light-gray-2;
    }

    .vg-all-filter {
      display: flex;
      width: 426px;
      flex-wrap: nowrap;
    }

    .vg-filter {
      border: 0;
      border-right: 1px solid $light-gray-2;
      display: inline-block;
      position: relative;
      width: 100%;

      &.vg-filter-more {
        border-left: 0;
        border-right: 0;
        padding: 0 16px;

        &::before {
          background: linear-gradient(
            to top,
            rgba($white, 1) 0%,
            rgba($white, 0) 100%
          );
          bottom: 23px;
          content: "";
          height: 45px;
          left: 9px;
          pointer-events: none;
          position: absolute;
          transition: opacity 0.3s $ease-default;
          width: 96%;
          z-index: 2;
        }

        .vg-filter-title {
          padding: 0 0 16px;

          .vg-menu-filter {
            .vg-menu-filter-height {
              display: block;
              margin-bottom: 24px;
              max-height: 200px;
              overflow: auto;
              padding: 0 0 24px;
            }

            .no-padding {
              padding: 0;
            }

            &.vg-sub-menu-filter {
              @include rounded-white;
              background-color: $white;
              border-radius: 0 0 8px 8px;
              border-top: solid 1px $light-gray-2;
              display: block;
              left: 0;
              padding: 16px;
              position: absolute;
              top: 100%;
              width: 100%;
            }

            label {
              display: block;

              &.vg-label-search-filter {
                border-bottom: solid 1px $light-gray-1;
                margin-bottom: 16px;
              }
            }
          }
        }

        .vg-menu-filter {
          margin-bottom: 24px;
        }
      }

      &:last-child {
        border-radius: 0;
        border-right: 0;
      }

      .vg-menu-filter {
        @include rounded-white;
        background-color: $white;
        border-radius: 0 0 8px 8px;
        border-top: solid 1px $light-gray-2;
        left: 0;
        position: absolute;
        top: 100%;

        label {
          display: block;

          &.vg-label-search-filter {
            border-bottom: solid 1px $light-gray-1;
            margin-bottom: 16px;
          }
        }
      }

      &.vg-filter-more {
        .vg-menu-filter {
          border: 0;
          border-radius: 0;
          box-shadow: none;
          display: block;
        }

        .vg-filter-title {
          cursor: auto;
          pointer-events: visible;
        }

        .vg-icon {
          display: none;
        }
      }

      &.vg-filter-more {
        .vg-menu-filter {
          border: 0;
          border-radius: 0;
          box-shadow: none;
          display: block;
        }

        .vg-filter-title {
          cursor: auto;
          pointer-events: visible;
        }

        .vg-icon {
          display: none;
        }
      }
    }
  }
}
