//
// Hired List
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.vg-selected-candidates {
  min-height: 100px;
  position: relative;

  &:hover {
    &::before {
      opacity: 0;
    }
  }

  &.vg-apply-before::before {
    background: linear-gradient(
      to top,
      rgba($white, 1) 0%,
      rgba($white, 0.4) 100%
    );
    bottom: 0;
    content: "";
    height: 50px;
    left: 0;
    pointer-events: none;
    position: absolute;
    transition: opacity 0.4s $ease-default;
    width: 100%;
  }

  ul {
    list-style: none;
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
    padding: 0;
    text-align: left;
  }

  li {
    @include rounded-white;
    border: 1px solid $light-gray-2;
    box-shadow: none;
    padding: 10px 20px;

    + li {
      margin-top: 8px;
    }

    span {
      color: $dark-gray-1;
      font-size: 1.4em;

      &.vg-hired-name {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: calc(100% - 50px);

        em {
          color: $dark-gray-2;
          display: block;
          font-size: 0.71em;
          font-style: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          text-transform: uppercase;
          white-space: nowrap;
          width: 100%;
        }
      }
    }

    button {
      appearance: none;
      background: none;
      border: 0;
      cursor: pointer;
      float: right;
      height: 24px;
      margin: 2px -10px 0 0;
      outline: none;
      padding: 0;
      width: 24px;

      &:hover {
        .vg-icon {
          fill: $dark-pink;
        }
      }
    }
  }

  .vg-icon {
    fill: $light-gray-1;
    transition: fill 0.2s $ease-default;
    vertical-align: bottom;

    &-candidate {
      float: left;
      margin-right: 8px;
      margin-top: 5px;
    }

    &.vg-icon-close {
      display: block;
      height: 24px;
      width: 24px;

      .circle {
        display: none;
      }
    }
  }
}
