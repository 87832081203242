//
// Variables
// -----------------------------------------------------------------------------

// Generic Colors
// -----------------------------------------------------------------------------
$white: #fff !default;
$black: #000 !default;
$dark-green: #1f8563 !default;
$green: #2dac3a !default;
$green-status: #7ab84d !default;
$light-green: #50c092 !default;
$light-green-2: #8cd5b7 !default;
$dark-blue-2: #2e505c !default;
$dark-blue: #1b6dab !default;
$blue: #4b97b9 !default;
$light-blue: #60bed6 !default;
$light-blue-2: #dae4eb !default;
$dark-purple: #a9375b !default;
$purple: #ab4c93 !default;
$light-purple: #f55bab !default;
$dark-pink: #ec373c !default;
$pink: #dc4f78 !default;
$light-pink: #d75f8f !default;
$dark-orange: #d97535 !default;
$orange: #f7902a !default;
$light-orange: #fbae39 !default;
$light-orange-2: #fef1dd !default;
$light-orange-3: #ffdbb2 !default;
$light-orange-4: #f59434 !default;
$light-orange-5: #fec1af!default;

$red-status: #ce4146 !default;
$red-status-2: #cd4248 !default;

// Gradient Colors
$red-gradient: #f9564b !default;
$green-gradient: #00be70 !default;
$green-gradient-2: #00a613 !default;

// Fifty shades of gray
$dark-gray-1: #474747 !default;
$dark-gray-2: #797979 !default;
$dark-gray-3: #8c8c8c !default;
$dark-gray-4: #d9d9d9 !default;
$dark-gray-5: #4c4c4c !default;
$dark-gray-6: #667085 !default;
$light-gray-1: #c8c8c8 !default;
$light-gray-2: #edeced !default;
$light-gray-3: #f1f1f1 !default;
$light-gray-4: #eee !default;
$light-gray-5: #f6f6f6 !default;
$light-gray-6: #eaeaea !default;
$light-gray-7: #e6e6e6 !default;
$light-gray-8: #c4c4c4 !default;
$light-gray-9: #e0e0e0 !default;

// Domain Specific Colors
// -----------------------------------------------------------------------------

// SLA Graph Colors
$sla-gray: $dark-gray-3 !default;
$sla-green: $green !default;
$sla-red: #f9574b !default;
$sla-yellow: #ffbb45 !default;

// Toast colors
$toast-green: #7ab84d !default;
$toast-light-green: #e9f3e1 !default;
$toast-yellow: #ffbb45 !default;
$toast-light-yellow: #f9f3d5 !default;
$toast-red: #ce4146 !default;
$toast-light-red: #f7dedf !default;

// Star color
$star-yellow: #ffb62e !default;

// Data Situation
$cinnabar: #da3333 !default;
$lime-green: #2dac3a !default;
$denim: #1b6da8 !default;

// Layout
// -----------------------------------------------------------------------------
$header-height: 70px;
$header-height-desktop: 10em;
$header-user-navigation: 170px;

// Scaffolding
// -----------------------------------------------------------------------------
$body-background: $light-gray-4 !default;
$text-color: $black !default;
$hr-border: $black !default;

// Typography
// -----------------------------------------------------------------------------
$sans: sans-serif !default;
$serif: serif !default;
$open-sans: 'Open Sans', $sans !default;

$light: 300 !default;
$regular: 400 !default;
$semibold: 600 !default;
$bold: 700 !default;

$base-font-size: 10px !default;
$base-font-family: $open-sans !default;
$base-line-height: 20px !default;

$headings-font-weight: $semibold !default;
$headings-font-family: $open-sans !default;
$headings-color: inherit !default;

// Breakpoints
// -----------------------------------------------------------------------------
$mobile-width: 30em;
$large-mobile-width: 600px;
$tablet-width: 48em;
$desktop-width: 64em;
$large-desktop-width: 75em;
$xlarge-desktop-width: 1600px;

// Import Breakpoints
// gives names to the breakpoints when imported to the javascript side
// -----------------------------------------------------------------------------
$breakpoints: (
  'isMobile': $mobile-width,
  'isLargeMobile': $large-mobile-width,
  'isTablet': $tablet-width,
  'isDesktop': $desktop-width,
  'isLargeDesktop': $large-desktop-width,
) !default;

// Easing presets
// -----------------------------------------------------------------------------
$linear: cubic-bezier(0.25, 0.25, 0.75, 0.75);
$ease: cubic-bezier(0.25, 0.1, 0.25, 1);
$ease-in: cubic-bezier(0.42, 0, 1, 1);
$ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
$ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$ease-out: cubic-bezier(0, 0, 0.58, 1);
$ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
$ease-in-out: cubic-bezier(0.42, 0, 0.58, 1);
$ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
$ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
$ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$ease-default: $ease;

// Spacing
// -----------------------------------------------------------------------------
$mobile-margin: 6vw;
$desktop-margin: 4.8vw;

// Themes
// ==========================================================================
$theme: $orange;
$theme2: $dark-blue;

// Buttons
// ==========================================================================
$buttons: (
  vg-btn-light: $white,
  vg-btn-success: $green,
  vg-btn-danger: $dark-pink,
  vg-btn-warning: $light-orange,
  vg-btn-info: $light-blue,
);

$outline-buttons: (
  vg-btn-outline-white: $white,
  vg-btn-outline-black: $black,
  vg-btn-outline-gray: $dark-gray-2,
  vg-btn-outline-light-gray: $light-gray-1,
  'vg-btn-outline.vg-btn-success': $green,
  'vg-btn-outline.vg-btn-danger': $dark-pink,
  'vg-btn-outline.vg-btn-warning': $light-orange,
  'vg-btn-outline.vg-btn-info': $light-blue,
  'vg-btn-outline.vg-btn-epartner': $theme,
);

// Border radius
// ==========================================================================
$default-radius: 4px;

// Inputs
// ==========================================================================
$input-height: 38px;

// Tooltip
// ==========================================================================
$tooltip-offset-default: 30px;
