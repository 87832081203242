//
// Custom Checkbox
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.vg-custom-checkbox {
  button {
    appearance: none;
    background: linear-gradient(to right, $orange 0%, $red-gradient 100%);
    border: 0;
    border-radius: 30px;
    cursor: pointer;
    height: 26px;
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
    outline: none;
    overflow: hidden;
    position: relative;
    transform: rotate(0.002deg);
    transition: box-shadow 0.2s $ease-default;
    width: 60px;

    &::before {
      background-color: $light-gray-1;
      content: "";
      height: 36px;
      left: -5px;
      position: absolute;
      top: -5px;
      transition: opacity 0.2s $ease-in-out-circ;
      width: 70px;
      z-index: 1;
    }

    &::after {
      background: $white;
      border-radius: 20px;
      content: "";
      height: 20px;
      left: 3px;
      position: absolute;
      top: 3px;
      transition: transform 0.2s $ease-in-out-circ;
      width: 20px;
      z-index: 2;
    }

    &.is-checked {
      @include rounded-white-hover;

      &::after {
        transform: translate3d(34px, 0, 0);
      }

      &::before {
        opacity: 0;
      }
    }
  }
}
