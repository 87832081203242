//
// Box Professionals
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-intro-professionals {
  text-align: center;

  .vg-sub-tit {
    font-size: 1.5em;
    font-weight: $regular;
  }

  .vg-btn-success {
    .vg-icon {
      fill: $white;
      height: 16px;
      margin-right: 20px;
      vertical-align: sub;
      width: 16px;
    }
  }
}

.vg-professionals-box {
  @include rounded-white;
  margin: 0 0 20px;

  .vg-icon-box {
    background-color: $light-blue-2;
    border-radius: 8px 8px 0 0;
    height: 150px;
    padding: 10px 0 0;

    .vg-icon {
      display: block;
      height: 100%;
      margin: 0 auto;
      width: auto;
    }
  }

  .vg-professionals-box-content {
    padding: 20px 20px 0;
    text-align: center;

    .vg-professionals-box-title {
      font-size: 1.7em;
      margin: 0;
    }

    .vg-professionals-box-description {
      font-size: 1.4em;
    }

    .vg-list-btn {
      a {
        text-decoration: none;
      }
    }
  }

  .vg-list-links {
    border-top: solid 1px $light-gray-2;
    list-style: none;
    padding: 10px 20px;

    li {
      padding: 10px 0;

      a {
        color: $orange;
        font-size: 1.5em;
        text-decoration: none;
      }
    }
  }

  &.vg-box-colaboradores {
    .vg-btn-disabled {
      display: block;
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .vg-alert {
    height: auto;
  }
}

@include desktop {
  .vg-professionals-box {
    height: 100%;
    margin: 0 auto 24px;
    max-width: 500px;
  }
}
