@import "../../../../styles/base/global";

.vg-modal-box {
  width: 100vw;
  height: 100vh;
  background-color: rgba(237, 237, 237, 0.6);
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
