//
// Sla Page
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-sla-page {
  > header {
    text-align: center;

    h1 {
      color: $dark-gray-1;
      margin-bottom: 0;
    }
  }

  .vg-error-message {
    font-size: 2em;
    padding: 40px 0;
    text-align: center;
  }

  &.vg-sla-wrapper {
    .vg-loader-wrap {
      margin: 0 auto;
    }
  }

  .vg-filter {
    @include rounded-white;
    margin-bottom: 20px;
    padding: 9px 20px;
    position: relative;
    text-align: left;

    button {
      appearance: none;
      background-color: transparent;
      border: 0;
      cursor: pointer;
      outline: none;
      padding: 10px;
      position: absolute;
      right: 13px;

      &.vg-button-active {
        .vg-icon-filter {
          fill: $orange;
        }
      }

      .vg-icon-filter {
        fill: $light-gray-1;
        height: 22px;
        transition: fill 0.2s $ease-default;
        width: 22px;
      }
    }

    &-input {
      display: inline-block;
      position: relative;
      width: calc(100% - 38px);
      z-index: 2;

      .vg-icon {
        fill: $light-gray-1;
        margin-right: 12px;
      }

      input {
        appearance: none;
        border: 0;
        font-size: 1.5em;
        height: 40px;
        width: calc(100% - 57px);

        &:focus {
          outline: none;
        }
      }
    }

    &-select {
      @include horizontal-centered;
      @include default-drop-shadow;
      background-color: $white;
      border-radius: 0 0 4px 4px;
      opacity: 0;
      padding: 20px;
      pointer-events: none;
      position: absolute;
      top: calc(100% + 1px);
      transform: translate3d(-50%, -10px, 0);
      transition: opacity 0.8s $ease-default, transform 0.3s $ease-default;
      width: 80%;
      z-index: 1;

      &.vg-filter-active {
        opacity: 1;
        pointer-events: auto;
        transform: translate3d(-50%, 0, 0);
      }

      .vg-custom-radio {
        display: block;

        .vg-label {
          display: block;
          margin-bottom: 8px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      h3 {
        display: inline-block;
        font-size: 1.5em;
        font-weight: $semibold;
        margin-top: 0;
      }

      &::before {
        @include vertical-centered;
        background: url("../../../assets/arrow.svg") no-repeat;
        content: "";
        display: none;
        height: 7px;
        pointer-events: none;
        right: 10px;
        width: 10px;
        z-index: 2;
      }
    }
  }
}
