//
// DropDown Menu
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.vg-dropdown-menu {
  display: inline-block;
  position: relative;

  .vg-dropdown-info {
    padding: 0;

    header {
      margin-bottom: 8px;
      padding: 16px 16px 0;
    }
  }

  .vg-dropdown-button {
    appearance: none;
    background: none;
    cursor: pointer;
    line-height: 0.5;
    outline: 0;
    padding: 10px;

    .vg-icon-arrow,
    .vg-icon-dots {
      display: inline-block;
      height: 10px;
      margin: 0;
      transform: rotate(0deg);
      transition: transform 0.3s ease;
      vertical-align: middle;
      width: 10px;

      svg {
        fill: $orange;
        margin-bottom: 3px;
      }
    }

    .vg-icon-dots svg {
      height: auto;
      margin: -3px 0 0 -3px;
      width: 16px;
    }

    &:focus,
    &:hover {
      svg {
        fill: $white;
      }
    }
  }

  [class*="vg-btn-outline-light-gray"].vg-dropdown-button {
    .vg-icon-arrow,
    .vg-icon-dots {
      svg {
        fill: $dark-gray-2;
      }
    }
    &:focus,
    &:hover {
      svg {
        fill: $orange;
      }
    }
  }

  &.vg-active {
    .vg-icon-arrow,
    .vg-icon-dots {
      transform: rotate(180deg);
      transition: transform 0.3s ease;

      svg {
        fill: $white;
      }
    }

    .vg-dropdown-list {
      opacity: 1;
      transform: translate3d(0, 10px, 0);
      visibility: visible;
    }
  }

  .vg-dropdown-list {
    @include rounded-white;
    border: solid 1px $light-gray-1;
    list-style: none;
    margin: 0;
    min-width: 220px;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: left;
    top: 74%;
    transform: translate3d(0, 0, 0);
    transition: transform 0.2s $ease-default, opacity 0.5s $ease-default,
      visibility 0.5s ease;
    visibility: hidden;
    z-index: 4;

    &::after,
    &::before {
      border: 10px solid transparent;
      border-bottom-color: $light-gray-1;
      content: " ";
      height: 0;
      pointer-events: none;
      position: absolute;
      right: 3px;
      top: -26px;
      width: 0;
    }

    &::after {
      border-bottom-color: $white;
      top: -19px;
      right: 10px;
    }

    &::before {
      right: 10px;
      top: -20px;
    }

    li {
      cursor: pointer;

      a {
        color: $dark-gray-1;
        display: block;
        text-decoration: none;
        white-space: nowrap;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.vg-dropdown-line {
        border-bottom: 1px solid $light-gray-1;
      }
    }
  }
  .vg-dropdown-left-aligned {
    left: -9px;
    &::after,
    &::before {
      left: 15px;
    }

    &::after {
      border-bottom-color: $white;
      top: -20px;
    }
  }
}

.vg-btn-outline-light-gray + .vg-dropdown-list {
  li {
    a {
      font-weight: normal;
    }
  }
}

.vg-dropdown-default {
  list-style: none;
  padding: 0 0 16px;
  position: relative;
  opacity: 1;
  visibility: visible;
  width: 300px;

  li {
    cursor: pointer;
    display: block;

    a {
      color: $dark-gray-1;
      display: block;
      font-size: 1.6em;
      line-height: 1.5em;
      padding: 4px 16px;
      text-decoration: none;
      white-space: nowrap;

      .vg-icon {
        margin-right: 8px;
      }

      &:hover {
        background-color: $light-gray-3;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.vg-dropdown-line {
      border-bottom: 1px solid $light-gray-1;
    }
  }
}

@media (max-width: $desktop-width) {
  .vg-dropdown-menu {
    [class*="vg-btn-outline"].vg-dropdown-button {
      border-width: 0;

      &:hover,
      &:focus {
        background-color: transparent;
        box-shadow: none;

        .vg-icon svg {
          fill: $orange;
        }
      }
    }

    &.vg-active {
      .vg-dropdown-button,
      .vg-dropdown-button:hover,
      .vg-dropdown-button:focus {
        background-color: transparent;
        box-shadow: none;

        .vg-icon svg {
          fill: $orange;
        }
      }
    }
  }
}
