.vg-title-1 {
  color: $dark-gray-1;
  font-size: 2.4em;
  font-weight: $semibold;
  margin-bottom: 27px;
  margin-top: 0;
  text-align: center;

  &.vg-title-left {
    text-align: left;
  }

  &.vg-title-mt {
    margin-top: 63px;
  }

  &.vg-title-no-mb {
    margin-bottom: 0;
  }
}

.vg-sub-title {
  font-weight: $light;
  margin-bottom: 20px;
  margin-top: 10px;
  text-align: center;
}
