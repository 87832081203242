//
// SLA Bar
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-sla {
  display: block;
  text-align: center;

  &-0 {
    bottom: 28px;
  }

  &-1 {
    bottom: 104px;
  }

  &-2 {
    bottom: 160px;
  }

  &-bar {
    background-color: $light-gray-3;
    display: block;
    height: 4px;
    overflow: hidden;
    width: 100%;

    .vg-sla-width {
      display: block;
      height: 100%;
      width: 0;
      transition: width 0.2s $ease-default, background 0.2s $ease-default;
    }
  }
}

.vg-sla-bar-status-no_prazo {
  background-color: $orange;
}

.vg-sla-bar-status-excedido {
  background-color: $sla-red;
}

.vg-sla-bar-status-pausado {
  background-color: $sla-gray;
}

@include desktop {
  .vg-sla {
    position: static;
    width: 100%;
  }
}
