//
// Modal
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-modal {
  background: $white;
  font-size: 10px;
  max-height: calc(100vh - 80px);
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: translate3d(0, -80px, 0);
  transition: transform 0.2s $ease-default, opacity 0.2s $ease-default,
    visibility 0.2s linear;
  visibility: hidden;
  width: 100vw;
  will-change: transform;
  display: flex;
  flex-direction: column;

  &-wrapper {
    height: 100vh;
    left: 0;
    pointer-events: none;
    position: fixed;
    text-align: left;
    top: 0;
    width: 100vw;
    z-index: 5;

    &.vg-modal-wrapper-titleless {
      .vg-modal-header {
        border: 0;
        height: auto;
        position: absolute;
      }

      .vg-modal-body {
        height: 100%;
      }
    }

    &.vg-entered {
      pointer-events: auto;

      .vg-modal-backdrop {
        opacity: 1;
      }

      .vg-modal {
        opacity: 1;
        transform: translate3d(0, 0, 0) rotate(0.002deg);
        visibility: visible;
      }
    }
  }

  &-header {
    border-bottom: 1px solid $light-gray-1;
    min-height: 70px;
    line-height: 25px;
    padding: 22px;
    position: relative;
    width: 100%;
    z-index: 3;
    display: flex;
    align-items: center;

    .vg-icon-dollar {
      margin-right: 8px;
      width: 40px;
      height: 40px;
      vertical-align: middle;
      path {
        fill: $orange;
      }
    }

    h1 {
      color: $dark-gray-1;
      display: inline-block;
      font-size: 1.8em;
      font-weight: $semibold;
      margin: 0;
      width: 100%;

      span {
        display: inline-block;
        max-width: calc(100% - 115px);
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: bottom;
        white-space: nowrap;
        width: auto;
      }
    }

    button {
      background: transparent;
      border: 0;
      cursor: pointer;
      display: inline-block;
      height: 25px;
      outline: none;
      padding: 0;
      vertical-align: top;
      width: 25px;

      .vg-icon-close {
        fill: $dark-pink;
        height: 25px;
        width: 25px;

        .x,
        .circle {
          transform-origin: 50% 50%;
          transition: transform 0.2s $ease-default;
        }

        &:hover {
          .x {
            transform: scale(1.8) translate3d(0, 0, 0);
          }

          .circle {
            transform: scale(0) translate3d(0, 0, 0);
          }
        }
      }
    }
  }

  &-body {
    height: 100%;
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 10px;

    &-text {
      font-size: 1.5em;
      margin: 20px auto;
      text-align: center;
      width: 50%;
    }

    &-btns {
      text-align: center;
      margin: 30px auto 20px;

      [class^="vg-btn"] {
        margin-left: 20px;
        font-size: 1.18rem;
      }
    }
  }

  &-backdrop {
    background-color: rgba($light-gray-2, 0.7);
    height: 100%;
    opacity: 0;
    transition: opacity 0.4s $ease-default;
    width: 100%;
  }
}

@include desktop {
  .vg-modal-wrapper-titleless {
    .vg-modal {
      &-body {
        height: 100%;
      }
    }
  }

  .vg-modal {
    @include rounded-white;
    @include vertical-horizontal-centered;
    height: auto;
    max-width: 950px;
    transform: translate3d(-50%, calc(-50% - 80px), 0);
    width: 90vw;

    &.vg-modal-small {
      @include rounded-white;
      @include vertical-horizontal-centered;
      transform: translate3d(-50%, calc(-50% - 80px), 0);
      width: 50vw;
      height: auto;
      min-height: 440px;
    }

    &-wrapper {
      &.vg-entered {
        .vg-modal {
          transform: translate3d(-50%, -50%, 0) rotate(0.002deg);

          &.vg-modal-small {
            transform: translate3d(-50%, -50%, 0) rotate(0.002deg);
          }
        }
      }
    }
  }
}

@include min-height-desktop {
  .vg-modal {
    border-radius: 4px;
    left: 50%;
    position: fixed;
    top: 50%;
    overflow: auto;
    transform: translate(-50%, -50%);

    &-wrapper {
      &.vg-entered {
        .vg-modal {
          transform: translate(-50%, -50%);
        }
      }
      &.vg-modal-wrapper-titleless {
        .vg-modal-header button {
          margin-right: 18px;
        }
      }
    }
  }
}
