//
// Help
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.vg-help {
  &-wrapper {
    display: flex;
    flex-direction: column;
    height: 488px;
    justify-content: space-between;
    margin-bottom: 16px;

    @include desktop {
      height: 60vh;
    }
  }

  &-body {
    border-top: 1px solid $light-gray-1;
    margin: 12px 24px;

    p {
      font-size: 14px;
      margin-top: 24px;
    }

    h2 {
      margin-top: 32px;
    }

    .vg-schedule {
      border: 1px solid $light-gray-2;
      border-radius: 2px;
      cursor: pointer;
      margin-top: 24px;
      padding: 5px 16px;
      width: 130px;

      &:hover {
        a {
          color: $orange;
        }

        svg path {
          fill: $orange;
        }
      }

      svg path {
        fill: $dark-gray-1;
      }

      a {
        color: $dark-gray-1;
        font-weight: 600;
        margin-left: 10px;
        text-decoration: none;
      }
    }
  }

  &-link {
    align-items: normal;
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    text-align: center;
  }
}
