//
// @extends
// -----------------------------------------------------------------------------

// Verical, Horizontal and Center Alignment
// -----------------------------------------------------------------------------
%spread {
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

%horizontal-center {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

%vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

%absolute-center {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

// Responsive media embeds
// -----------------------------------------------------------------------------
%embed-container {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%; // 16:9 aspect ratio (adjust accordingly)
  position: relative;

  embed,
  iframe,
  img,
  object,
  video {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
