@import "../../../styles/base/global";

.vg-info {
  color: $dark-gray-5;
  font-size: 14px;
  line-height: 1.71;
  padding: 0 16px 16px;

  p {
    margin: 0;
    + p {
      margin: 8px 0 0 0;
    }
  }

  .vg-link {
    margin-left: 4px;
  }
}
.vg-detail .vg-dropdown-info {
  padding: 0;

  header {
    margin-bottom: 8px;
    padding: 16px 16px 0;
  }
}
.vg-info-numbers {
  font-size: 24px;
  font-weight: $semibold;
  line-height: 24px;
  margin: 0;
  padding: 0;
}

.vg-block-totals {
  background: $light-gray-7;
  font-size: 14px;
  flex-direction: row;
  display: flex;
  padding: 16px;
  text-align: center;
}

.vg-col-totals {
  + .vg-col-totals {
    border-left: 1px solid $light-gray-1;
    padding-left: 8px;
    margin-left: 8px;
  }
}

.vg-text-totals {
  line-height: 18px;
  margin: 8px 0 0;
  width: 110px;
  white-space: pre-wrap;
}

.vg-icon-location,
.vg-icon-buildings,
.vg-icon-tag {
  svg {
    fill: $dark-gray-5;
  }
}

.badge-rec-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.badge-rec {
  align-items: center;
  background-color: #eff8ff;
  border-radius: 16px;
  color: #175cd3;
  display: flex;
  gap: 0.25rem;
  justify-content: center;
  margin-right: 8px;
  padding: 0.125rem 0.5rem 0.125rem 0.375rem;

  [data-icon="box-icon"] {
    height: auto;
    width: 0.75rem;
  }

  span {
    font-size: 12px;
    font-weight: 600;
  }
}
