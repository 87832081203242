//
// Intro
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.vg-intro {
  @include orange-degrade;
  box-shadow: 0 1px 2px rgba($black, 0.15);
  padding: 40px 0;
  text-align: center;

  .vg-avatar {
    margin-bottom: 12px;
  }

  h1 {
    color: $white;
    font-size: 2.4em;
    font-weight: $light;
    margin: 0;
  }

  p {
    color: $white;
    font-size: 1.5em;
    line-height: 1.4;
    margin: 0;
    margin-top: 10px;
    padding: 0 20px;

    a {
      border-bottom: 1px solid;
      color: $white;
      text-decoration: none;
    }
  }
}

@include desktop {
  .vg-intro {
    padding: 40px 0;
  }
}
