//
// Página de edição de Fases
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-phases-wrapper .vg-phase-content {
  h3 {
    color: $dark-gray-1;
    font-size: 1.4em;
    font-weight: $semibold;
  }

  .vg-btn-link {
    padding: 0;
  }
}
