// ==========================================================================
// Buttons
// ==========================================================================

//
// Descrição:
// Criamos padrões de botões que podem ser utilizados em outros components.
//

@import "../../../styles/base/global";

.vg-main-candidate {
  [class^="vg-btn"] {
    @include theme($invert: true, $color: $theme2);

    &:focus:not(:disabled),
    &:hover:not(:disabled) {
      @include theme($invert: true, $variation: -10%, $color: $theme2);
    }

    &:active:not(:disabled) {
      @include theme($invert: true, $variation: 20%, $color: $theme2);
    }

    &[class*="vg-btn-primary"] {
      @include theme($color: $theme2);

      &:focus:not(:disabled),
      &:hover:not(:disabled) {
        @include theme($variation: -10%, $color: $theme2);
      }

      &:active:not(:disabled) {
        @include theme($variation: 10%, $color: $theme2);
      }

      &.vg-btn-primary-dark {
        @include theme($variation: 20%, $color: $theme2);

        &:hover:not(:disabled),
        &:focus:not(:disabled) {
          @include theme($variation: 10%, $color: $theme2);
        }

        &:active:not(:disabled) {
          @include theme($variation: 10%, $color: $theme2);
        }
      }
    }

    &[class*="vg-btn-outline"],
    &.vg-btn-outline {
      @include theme($prop: color, $color: $theme2);
      @include theme($prop: border-color, $color: $theme2);
      background-color: transparent;
      border: {
        style: solid;
        width: 1px;
      }

      [class*="vg-theme-"] & {
        background-color: transparent;
      }

      &:focus:not(:disabled),
      &:hover:not(:disabled) {
        @include theme($color: $theme2, $variation: -10%);
        @include theme($prop: background, $color: $theme2, $variation: -10%);
      }

      &:active:not(:disabled) {
        @include theme($variation: 10%);
      }

      // Outline Buttons
      // $outline-buttons é um objeto com todas as classes e valores
      // necessário para a criação
      @each $el, $each-color in $outline-buttons {
        &.#{$el} {
          @include theme($prop: color, $color: $theme2);
          @include theme($prop: border-color, $color: $theme2);

          &:focus:not(:disabled),
          &:hover:not(:disabled) {
            @include theme($color: $theme2, $variation: -10%);
            @include theme($prop: background, $color: $theme2);
          }

          &:active:not(:disabled) {
            @include theme($color: $theme2, $variation: 10%);
          }
        }
      }
    }
  }
}

// Default Button
[class^="vg-btn"] {
  @include theme($invert: true);
  appearance: none;
  border: {
    radius: 4px;
    width: 0;
  }
  cursor: pointer;
  display: inline-block;
  font: {
    size: 1.4em;
    weight: $semibold;
  }
  line-height: 1;
  outline: none;
  padding: 16px;
  text-decoration: none;
  transition: background 0.3s $ease-default, box-shadow 0.3s $ease-default,
    color 0.2s $ease-default, opacity 0.2s $ease-default;

  &[class*="vg-btn-outline"] {
    padding: 15px 14px;
  }

  &.vg-btn-no-padding {
    padding-left: 8px;
    padding-right: 8px;
  }

  &.vg-btn-no-padding-left {
    padding-left: 8px;
  }

  &.vg-btn-no-padding-right {
    padding-right: 8px;
  }

  &.vg-btn-split {
    margin-top: -16px;
    transform: translate3d(0, 50%, 0);
  }

  &:focus:not(:disabled),
  &:hover:not(:disabled) {
    @include theme($invert: true, $variation: -10%);
    box-shadow: 0 4px 3px rgba($black, 0.3);
  }

  &:active:not(:disabled) {
    @include theme($invert: true, $variation: 20%);
    box-shadow: 0 3px 3px -2px rgba($black, 0.5);
  }

  // Sizes
  &.vg-btn-sm {
    font-size: 1.2em;
    padding: 6px 16px;
    text-transform: uppercase;

    &[class*="vg-btn-outline"] {
      padding: 5px 15px;
    }

    &.vg-btn-no-padding {
      padding-left: 8px;
      padding-right: 8px;
    }

    &.vg-btn-no-padding-left {
      padding-left: 8px;
    }

    &.vg-btn-no-padding-right {
      padding-right: 8px;
    }
  }

  &.vg-btn-lg {
    font-size: 1.6em;
    padding: 24px 39px;
    text-transform: uppercase;

    &[class*="vg-btn-outline"] {
      padding: 23px 38px;
    }

    &.vg-btn-no-padding {
      padding-left: 19.5px;
      padding-right: 19.5px;
    }

    &.vg-btn-no-padding-left {
      padding-left: 19.5px;
    }

    &.vg-btn-no-padding-right {
      padding-right: 19.5px;
    }
  }

  // Layouts
  &[class*="vg-btn-primary"] {
    @include theme;

    &:focus:not(:disabled),
    &:hover:not(:disabled) {
      @include theme($variation: -10%);
    }

    &:active:not(:disabled) {
      @include theme($variation: 10%);
    }

    &.vg-btn-primary-dark {
      @include theme($variation: 20%);

      &:hover:not(:disabled),
      &:focus:not(:disabled) {
        @include theme($variation: 10%);
      }

      &:active:not(:disabled) {
        @include theme($variation: 10%);
      }
    }
  }

  // Default buttons
  // $buttons é um objeto com todas as classes e valores
  // necessário para a criação
  @each $el, $each-color in $buttons {
    &.#{$el} {
      @include theme($color: $each-color);

      &:focus:not(:disabled),
      &:hover:not(:disabled) {
        @include theme($color: $each-color, $variation: -10%);
      }

      &:active:not(:disabled) {
        @include theme($color: $each-color, $variation: 10%);
      }
    }
  }

  // Padrão de links com estilo de botão
  &[class*="vg-btn-link"] {
    background: transparent;

    &:active:not(:disabled),
    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      background: transparent;
      box-shadow: none;
    }

    &.vg-btn-link-white {
      color: $white;

      &:focus:not(:disabled),
      &:hover:not(:disabled) {
        color: $white;
      }
    }

    &:focus:not(:disabled),
    &:hover:not(:disabled) {
      text-decoration: underline;
    }
  }

  &.vg-btn-block {
    display: block;
    text-align: center;
    width: 100%;
  }

  // Outline
  &[class*="vg-btn-outline"],
  &.vg-btn-outline {
    @include theme(color);
    @include theme(border-color);
    background-color: transparent;
    border: {
      style: solid;
      width: 1px;
    }

    [class*="vg-theme-"] & {
      background-color: transparent;
    }

    &:focus:not(:disabled),
    &:hover:not(:disabled) {
      @include theme($variation: -10%);
    }

    &:active:not(:disabled) {
      @include theme($variation: 10%);
    }

    // Outline Buttons
    // $outline-buttons é um objeto com todas as classes e valores
    // necessário para a criação
    @each $el, $each-color in $outline-buttons {
      &.#{$el} {
        @include theme($prop: color, $color: $each-color);
        @include theme($prop: border-color, $color: $each-color);

        &:focus:not(:disabled),
        &:hover:not(:disabled) {
          @include theme($color: $each-color, $variation: -10%);
        }

        &:active:not(:disabled) {
          @include theme($color: $each-color, $variation: 10%);
        }
      }
    }
  }

  // Disabled
  &[disabled] {
    box-shadow: none;
    cursor: not-allowed;
    opacity: 0.45;

    &:hover {
      box-shadow: none;
    }
  }
}

.vg-btn-feedback {
  @include horizontal-centered;
  border-radius: 5px 5px 0 0;
  bottom: 0;
  box-shadow: -1px -1px 2px 0 rgba($black, 0.2);
  opacity: 0;
  overflow: hidden;
  padding: 16px 16px 16px 60px;
  pointer-events: none;
  position: fixed;
  text-align: center;
  transform: translate3d(-50%, 200px, 0);
  transition: transform 0.6s $ease-default;
  visibility: hidden;
  width: 200px;
  z-index: 9000;

  &:hover:not(:disabled) {
    box-shadow: -1px -3px 3px 0 rgba($black, 0.3);
  }

  .vg-icon {
    @include orange-degrade;
    height: 100%;
    left: 0;
    padding: 8px;
    position: absolute;
    stroke: $white;
    top: 0;
    width: 46px;

    svg {
      padding: 7px 0 0 7px;
    }
  }
}

// Espaçamentos dos botões
.vg-btn-spc-lt {
  margin-left: 16px;
}

.vg-btn-spc-rt {
  margin-right: 16px;
}

@include desktop {
  [class^="vg-btn"] {
    padding: 9px 16px;

    &[class*="vg-btn-outline"] {
      padding: 8px 16px;
    }

    &[class*="vg-btn-feedback"] {
      padding: 16px 16px 16px 60px;
    }
  }

  .vg-btn-feedback {
    @include vertical-centered;
    border-radius: 0 0 5px 5px;
    box-shadow: 1px 1px 2px 0 rgba($black, 0.2);
    bottom: auto;
    left: auto;
    opacity: 1;
    pointer-events: auto;
    position: fixed;
    left: -60px;
    transform: translateY(-50%) rotate(-90deg);
    transition: box-shadow 0.2s $ease-default;
    visibility: visible;
    width: 160px;

    .vg-icon {
      transform: rotate(90deg);
    }
  }
}
