//
// UserRedesForm
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.vg-form-redes {
  font-weight: bold;
  line-height: 1.6;
  margin: 0 auto;
  width: 85%;

  &-access,
  &-userinfo {
    margin: 30px 0;

    h3 {
      font-size: 15px !important;
    }

    fieldset {
      border: 0;
      min-height: 44px;
      overflow: visible;
      position: relative;

      label {
        display: inline-block;
        font-size: 14px;
        font-weight: normal;
        height: 100%;
        left: 20px;
        line-height: 2em;
        text-align: right;
      }

      input {
        border: solid 1px $light-gray-1;
        border-radius: 4px;
        display: inline-block;
        font-size: 1.5em;
        height: 30px;
        padding: 0 10px;
        width: 100%;
      }

      .error {
        border-color: $dark-pink;
      }

      span {
        display: block;
        min-height: 19px;
        padding-top: 3px;

        &.error {
          color: $dark-pink;
          font-weight: normal;
          margin-left: 24%;
          width: 70%;
        }
      }

      .vg-custom-select {
        display: inline-block;
        height: 30px;
        margin-bottom: 0;
        width: 100%;

        select {
          margin: 0;
        }
      }

      .vg-custom-radio {
        .vg-label-text {
          font-size: inherit;
        }
      }
    }
  }

  .vg-label-text-rede {
    padding-top: 0;
    display: inline-block;
    font-size: 14px;
    height: 100%;
    line-height: 2em;
  }
}
.vg-modal-body-removeUR,
.vg-modal-form-redes {
  .vg-wrap-buttons {
    [class^="vg-btn"] {
      margin: 8px 4% !important;
      width: 92% !important;
    }
  }
}

@include desktop {
  .vg-form-redes {
    width: 70%;
    .vg-form-redes-access,
    .vg-form-redes-userinfo {
      margin: 0;
      fieldset {
        label {
          margin-right: 20px;
          width: 20%;
        }
        input,
        .vg-custom-select {
          width: 70%;
        }
      }
    }
    .vg-custom-radio .vg-label {
      width: inherit;
    }
  }
  .vg-modal-body-removeUR,
  .vg-modal-form-redes {
    .vg-wrap-buttons {
      [class^="vg-btn"] {
        margin: 0 0 8px 15px !important;
        width: auto !important;
      }
    }
  }
}
