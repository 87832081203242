//
// StarRating
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.vg-rating-star {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;

  input {
    left: -9999px;
    position: absolute;
  }

  label {
    cursor: pointer;
    margin-right: 8px;

    &:first-of-type {
      margin-right: 0;
    }

    .vg-icon {
      height: 25px;
      width: 25px;

      .vg-line-star {
        fill: $dark-gray-3;
      }

      .vg-fill-star {
        fill: $star-yellow;
        opacity: 0;
        transition: opacity 0.2s $ease-default;
        will-change: opacity;
      }
    }

    &:hover,
    &:hover ~ label {
      .vg-icon {
        .vg-line-star {
          fill: $star-yellow;
        }

        .vg-fill-star {
          opacity: 1;
        }
      }
    }
  }

  > input:checked {
    ~ label {
      .vg-icon {
        .vg-line-star {
          fill: $star-yellow;
        }

        .vg-fill-star {
          opacity: 1;
        }
      }
    }
  }
}
