//
// User Navigation
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";
.vg-navigation {
  .vg-submenu {
    @include rounded-white;
    border-radius: 0 0 5px 5px;
    display: none;
    left: 50%;
    margin-left: -70px;
    padding: 2px;
    position: absolute;
    text-align: left;
    overflow: hidden;
    width: 160px;

    li {
      display: block;
      margin: 0;
      padding: 0;

      &:last-child {
        padding: 0;
      }

      a {
        border-radius: 5px;
        color: $orange;
        display: block;
        padding: 16px 8px;

        &:hover {
          background-color: $light-gray-2;
        }
      }
    }
  }
}
