//
// Tabs
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-wrapper-tabs {
  @include rounded-white;
}

.vg-content-list-tabs {
  position: relative;
}

.vg-list-tabs {
  background-color: $light-gray-3;
  border-radius: 5px 5px 0 0;
  border-top: solid 1px $white;
  list-style: none;
  margin: 0;
  padding: 70px 0 0;

  &.vg-tab-open {
    li {
      display: block;
    }
  }

  li {
    display: none;

    a {
      border-radius: 5px 5px 0 0;
      cursor: pointer;
      display: block;
      font-size: 1.5em;
      padding: 20px;
    }

    &.vg-tab-active {
      background-color: $white;
      display: block;
      position: absolute;
      top: 0;
      width: 100%;

      a {
        border-top: solid 3px $orange;
        color: $orange;
        font-size: 1.9em;
        font-weight: 600;
        padding: 25px 20px;
      }
    }
  }
}

.vg-btn-tab {
  display: block;
  position: absolute;
  right: 10px;
  top: 10px;

  .vg-icon-arrow {
    transform: rotate(90deg);
    transition: transform 0.3s ease;

    svg {
      fill: $orange;
    }
  }

  &:focus,
  &:hover {
    svg {
      fill: $white;
    }
  }

  &.vg-btn-active {
    .vg-icon-arrow {
      transform: rotate(270deg);
      transition: transform 0.3s ease;
    }
  }
}

.vg-content-tabs {
  .vg-content-tab {
    padding: 10px 20px;

    .vg-content-item {
      border-bottom: solid 1px $light-gray-1;
      margin-bottom: 12px;
      padding-bottom: 12px;
      width: 100%;

      .vg-title-content {
        font-size: 1.6em;
        margin: 0;
        vertical-align: middle;
      }

      &:last-child {
        border-bottom: 0;
      }

      .vg-btn-link {
        display: block;
        font-size: 1.6em;
        padding: 10px 0;
      }
    }

    &.vg-tab-active {
      display: block;
    }
  }
}

@include desktop {
  .vg-btn-tab {
    display: none;
  }

  .vg-list-tabs {
    padding: 0;

    li {
      display: inline-block;

      a {
        border-radius: 0;
        padding: 15px 20px;
      }

      &:first-child {
        a {
          border-radius: 5px 0 0;
        }
      }

      &.vg-tab-active {
        display: inline-block;
        position: relative;
        width: auto;

        a {
          font-size: 1.5em;
          padding: 15px 20px;
        }
      }
    }

    &.vg-tab-open {
      li {
        display: inline-block;
      }
    }
  }

  .vg-content-tabs {
    .vg-content-tab {
      padding: 30px;

      .vg-content-item {
        display: block;
        margin-bottom: 10px;
        padding-bottom: 10px;
        text-align: right;

        .vg-title-content {
          display: inline-block;
          float: left;
          font-size: 1.5em;
          padding: 10px 0 0;
        }

        .vg-btn-link {
          display: inline-block;
          font-size: 1.5em;
        }
      }
    }
  }
}

@media (max-width: $desktop-width) {
  .vg-tab .vg-btn-tab {
    border: 0;

    &[class^="vg-btn"].vg-btn-active,
    &[class^="vg-btn"]:hover,
    &[class^="vg-btn"]:focus {
      background-color: transparent;
      box-shadow: none;

      svg {
        fill: $orange;
      }
    }

    .vg-icon {
      width: 12px;
      height: 12px;
    }
  }
}
