//
// ErrorHandler
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-error-handler-wrapper {
  padding: 0 0 32px;
}

.vg-error-handler {
  @include rounded-white;
  height: 100%;
  overflow: hidden;
  padding: 30px 32px;
  text-align: center;

  .vg-icon {
    fill: $light-gray-1;
    height: 40px;
    margin-bottom: 18px;
    stroke: $light-gray-1;
    width: 40px;
  }

  h2 {
    color: $light-gray-1;
    font-size: 3.2em;
    font-weight: $semibold;
    margin: 0;
  }

  p {
    color: $dark-gray-2;
    font-size: 1.5em;
  }
}
