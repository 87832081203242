//
// Input Number
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

$default-height: 37px !default;

.vg-input-number {
  display: block;

  &-decrease,
  &-increase,
  input[type="text"] {
    appearance: none;
    border: 1px solid $light-gray-1;
    display: inline-block;
    height: $default-height;
    outline: none;
    padding: 0;
  }

  input[type="text"] {
    border-left: 0;
    border-right: 0;
    box-shadow: inset 0 1px 2px 0 rgba($black, 0.2);
    font-size: 1.4em;
    line-height: 31px;
    text-align: center;
    vertical-align: top;
    width: 78px;
  }

  &-decrease,
  &-increase {
    background: linear-gradient(to right, $orange 0%, $red-gradient 100%);
    color: $orange;
    cursor: pointer;
    font-weight: $semibold;
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
    overflow: hidden;
    position: relative;
    transform: rotate(0.002deg);
    transition: color 0.2s $ease-default;
    vertical-align: middle;
    width: 48px;

    &::before {
      background: $white;
      content: "";
      height: 100%;
      left: -1px;
      line-height: 35px;
      position: absolute;
      top: 0;
      transition: opacity 0.2s $ease-default;
      width: 50px;
      z-index: 1;
    }

    .vg-icon {
      fill: $orange;
      height: 10px;
      position: relative;
      transition: fill 0.2s $ease-default;
      width: 10px;
      z-index: 2;
    }

    &:hover {
      .vg-icon {
        fill: $white;
      }

      &::before {
        opacity: 0;
      }
    }
  }

  &-increase {
    border-radius: 0 5px 5px 0;
  }

  &-decrease {
    border-radius: 5px 0 0 5px;
  }
}
