//
// HighLight
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

[class^="vg-mark"] {
  background-color: $light-orange-2;
  color: $orange;
  padding: 5px 12px;
  border-radius: 4px;
  position: relative;
  font-weight: $semibold;

  mark {
    background-color: inherit;
    color: inherit;
    padding: 0;
    border-radius: none;
    position: static;
  }

  &.vg-mark-related {
    background-color: $light-gray-6;
    color: $dark-gray-2;

    span {
      @extend %horizontal-center;
      @include rounded-white;
      background-color: $white;
      border-radius: 4px;
      bottom: calc(100% + 2px);
      color: $dark-gray-2;
      font-size: 13px;
      opacity: 0;
      padding: 5px 10px;
      pointer-events: none;
      transform: translate3d(-50%, 40px, 0) rotate(0.02deg);
      transition: opacity 0.4s $ease-default, transform 0.2s $ease-default;
      will-change: transform;

      &::before {
        @extend %horizontal-center;
        border: 10px solid transparent;
        border-top-color: $white;
        content: "";
        top: calc(100% - 20px);
        z-index: 1;
      }
    }

    &:hover {
      span {
        opacity: 1;
        pointer-events: auto;
        transform: translate3d(-50%, 0, 0) rotate(0.02deg);

        &::before {
          transform: translate3d(-50%, 15px, 0) rotate(0.02deg);
        }
      }
    }
  }
}
