//
// VideoInterviewCandidate
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-interview-candidate {
  header {
    color: $dark-gray-1;
    margin-bottom: 30px;
    padding-top: 48px;
    text-align: center;

    .row {
      margin-top: 18px;
    }

    h1 {
      font-size: 3.2em;
      font-weight: $regular;
      margin-bottom: 20px;
    }

    h2 {
      color: $dark-gray-1;
      font-size: 2.4em;
      font-weight: $semibold;
      line-height: 1.5;
      margin: 0 auto;
      max-width: 720px;
    }

    p {
      font-size: 1.5em;
      line-height: 1.6;
      margin: 0;
    }

    .vg-icon {
      height: 106px;
      width: 108px;
    }
  }

  [class*="vg-col-md-3"] + [class*="vg-col-md-3"] {
    margin-top: 8px;
  }

  .vg-box-info {
    text-align: center;
  }

  .vg-interview-wrap-buttons {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 32px;

    p {
      font-size: 1.5em;
      line-height: 1.6;
      margin-top: 0;
    }

    [class*="vg-btn"] + [class*="vg-btn"] {
      margin-top: 16px;
    }

    .vg-inline-buttons {
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
    }
  }

  &-description {
    font-size: 1.5em;
    text-align: center;
  }

  .vg-show-buttons-enter {
    opacity: 0.01;
    transform: scale(0.9) translateY(-50%);
  }

  .vg-show-buttons-enter-active {
    opacity: 1;
    transform: scale(1) translateY(0%);
    transition: all 300ms ease-out;
  }

  .vg-show-buttons-exit {
    opacity: 1;
    transform: scale(1) translateY(0%);
  }

  .vg-show-buttons-exit-active {
    opacity: 0.01;
    transform: scale(0.9) translateY(-50%);
    transition: all 300ms ease-out;
  }

  .vg-fake-player {
    @include rounded-gray;
    max-width: 640px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px auto 0;
    padding: 170px 0;

    .vg-icon-check-circle {
      height: 50px;
      width: 50px;
    }
  }
}

.vg-interview-terms {
  border: 1px solid $light-gray-1;
  border-radius: 3px;
  padding: 20px;
  position: relative;

  p {
    line-height: 21px;
    margin-top: 0;
    text-align: justify;

    &:last-of-type {
      margin: 0;
    }
  }

  > div {
    font-size: 1.2em;
    line-height: 1.4;
    max-height: 330px;
    overflow-y: auto;
    padding-bottom: 15px;
    text-align: justify;
  }

  &::before {
    background: linear-gradient(
      to bottom,
      rgba($white, 0) 0%,
      rgba($white, 1) 100%
    );
    bottom: 0;
    content: "";
    height: 40px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  ol,
  ul {
    margin: 1em 0;
    padding-left: 2em;

    &.upper-roman {
      list-style-type: upper-roman;
    }

    li + li {
      margin-top: 8px;
    }
  }
}

@include desktop {
  .vg-interview-candidate {
    [class*="vg-col-md-3"] + [class*="vg-col-md-3"] {
      margin: 0;
    }

    .vg-interview-wrap-buttons {
      .vg-inline-buttons {
        flex-direction: row-reverse;

        [class*="vg-btn"] {
          margin: 0 8px;
        }
      }
    }
  }
}
