//
// Well
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-well {
  border-radius: 5px;
  border: 1px solid $light-gray-1;
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 25px 12px;

  p {
    color: $dark-gray-1;
    font-size: 1.3em;
    line-height: 1.5;
    margin: 0;
    flex-grow: 1;

    strong {
      color: $dark-blue;
    }
  }

  .vg-icon {
    width: 100%;
    height: auto;
    max-width: 35px;
    max-height: 35px;
    flex-grow: 2;
    display: block;
    margin-right: 18px;
  }
}

@include desktop {
  .vg-well {
    align-items: center;
    flex-direction: column;
    padding: 32px;
    text-align: center;

    p {
      margin-top: 29px;
    }

    .vg-icon {
      height: 66px;
      max-height: 100%;
      max-width: 73px;
      width: auto;
      margin: 0;
    }
  }
}
