//
// Tabs
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-ct-captacao-aberta {
  display: block;
  margin: 40px 0;
  width: 100%;
}

.vg-sub-tit {
  display: block;
  font-size: 1.8em;
  font-weight: $regular;
  text-align: center;
  margin: 0 0 30px 0;
}

.vg-item-captacao-aberta {
  @include rounded-white;
  display: block;
  margin: 0 0 25px;
  padding: 24px;
  width: 100%;

  .vg-division-name {
    color: $dark-gray-1;
    display: inline-block;
    font-size: 1.5em;
    font-weight: $semibold;
    line-height: 1.4em;
    margin: 0;
    padding: 0 0 0 26px;
    position: relative;

    .vg-status-captacao-text {
      color: $dark-gray-3;
      font-size: 1em;
      font-weight: $regular;
      padding: 0 0 0 8px;
    }
  }

  .vg-status-captacao {
    border-radius: 50%;
    background-color: $light-gray-1;
    display: inline-block;
    height: 16px;
    left: 0;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    width: 16px;

    &.vg-status-ativa {
      background-color: $green;
    }
    &.vg-status-inativa {
      background-color: $dark-pink;
    }
    &.vg-status-aguardando_ativacao_homepage {
      background-color: $orange;
    }
  }
  .vg-total-candidatos {
    margin: 10px 0 0;
    text-align: left;

    .vg-dados-captacao {
      color: $dark-gray-3;
      font-size: 1.4em;
      margin: 0;
      padding: 0 0 0 30px;

      .vg-icon-candidate {
        display: none;
        fill: $light-gray-1;
      }
      .vg-number-captacao {
        padding: 0 4px;
        font-style: normal;
      }

      &.vg-total {
        text-align: left;
        padding: 0 0 0 20px;
      }
    }
  }
  .vg-ct-links {
    text-align: center;

    .vg-btn-outline {
      margin: 10px 0 0;
      width: 100%;
    }
    .vg-btn-link {
      margin: 20px 0 0;
      padding: 10px;
    }
  }
}

@include desktop {
  .vg-item-captacao-aberta {
    .vg-total-candidatos {
      margin: 0;
      text-align: center;

      .vg-dados-captacao {
        padding: 0;
        .vg-icon-candidate {
          display: inline-block;
        }
        &.vg-total {
          text-align: center;
          padding: 0;
        }
      }
    }
    .vg-ct-links {
      text-align: right;
      .vg-btn-outline,
      .vg-btn-link {
        margin: 0;
        width: auto;
      }
    }

    .vg-division-name {
      padding: 0 0 0 30px;
    }
  }
}
