//
// Página de edição de Fases
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-phases-wrapper {
  .vg-rounded-link {
    @include rounded-white;
    appearance: none;
    background-color: $white;
    border: 0;
    border-radius: 20px;
    color: $orange;
    cursor: pointer;
    display: block;
    font: {
      size: 1.4em;
      weight: $semibold;
    }
    line-height: 23px;
    margin: 0 auto;
    outline: none;
    padding: 9px 20px 11px;
    text-align: center;
    text-decoration: none;
    transition: color 0.2s $ease-default, background 0.2s $ease-default;
    width: 220px;

    .vg-icon {
      display: inline-block;
      fill: $orange;
      margin-right: 10px;
      margin-top: 3px;
      transition: fill 0.2s $ease-default, stroke 0.2s $ease-default;
      width: 20px;
      height: 20px;
    }

    &:hover {
      background-color: $orange;
      color: $white;

      .vg-icon {
        fill: $white;
      }
    }
  }

  .vg-create-phase {
    @include rounded-white;
    appearance: none;
    border: 0;
    color: $orange;
    cursor: pointer;
    font-size: 1.4em;
    font-weight: $semibold;
    outline: none;
    padding: 32px;
    transition: box-shadow 0.2s $ease-default;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      @include rounded-white-hover;
    }

    .vg-icon {
      border-radius: 50%;
      height: 20px;
      width: 20px;
      margin-right: 11px;
      fill: $orange;
    }
  }

  .vg-error-handler {
    margin-top: 32px;
  }

  .vg-phases-modal {
    [class^="vg-animate-"] {
      opacity: 0;
      transform: translate3d(0, 5px, 0);
      transition: opacity 0.2s $ease-default, transform 0.2s $ease-default;

      &.vg-animate-entered {
        opacity: 1;
        position: relative;
        transform: translate3d(0, 0, 0);
        z-index: 2;
      }

      &.vg-animate-exiting,
      &.vg-animate-exited {
        position: absolute;
        width: calc(100% - 40px);
      }
    }

    &-content {
      padding: 24px;
      @media (min-width: $desktop-width) {
        &.vg-test-scroll {
          overflow-y: auto;
        }
      }

      p {
        color: $dark-gray-1;
        font-size: 1.4em;
        line-height: 1.5;
        margin: 0 0 15px;
        text-align: center;
      }

      h1 {
        color: $dark-gray-1;
        font-size: 2.6em;
        font-weight: $regular;
        margin: 0 0 8px;
        text-align: center;
      }
      .vg-warning-title {
        align-items: center;
        display: flex;
        margin-top: 8px;

        &.vg-empty-content-error {
          .vg-icon-warning {
            svg,
            path {
              fill: $cinnabar;
            }
          }
          p {
            color: $cinnabar;
          }
        }
        .vg-icon-warning {
          margin-right: 8px;

          svg,
          path {
            fill: $black;
          }
        }
        p {
          font-size: 1.2em;
          font-stretch: normal;
          font-style: normal;
          font-weight: normal;
          line-height: 1.43;
          letter-spacing: normal;
          margin: 0;
        }
      }
      .vg-form {
        .vg-input-token {
          margin-top: 8px;
        }

        > .vg-label {
          display: inline-block;
          position: relative;
          width: 100%;

          + .vg-label {
            margin-top: 24px;
          }

          .vg-label-text {
            font-size: 1.6em;
            font-weight: $semibold;
            display: block;

            + .vg-input-text {
              margin-top: 8px;
              &.vg-empty-content-error {
                border-color: $cinnabar;
              }
            }

            + .vg-label-helper {
              margin-top: 4px;
            }

            &--desc {
              color: $dark-gray-1;
              display: block;
              font-size: 1.4em;
              margin-top: 8px;

              a {
                color: $orange;
                text-decoration: none;
              }
            }
          }

          .vg-label-helper {
            display: block;
            font-size: 1.3em;

            + .vg-input-text {
              margin-top: 8px;
            }
          }

          &.vg-label-error {
            .vg-label-helper-error {
              opacity: 1;
              transform: translate3d(0, 0, 0);
            }

            .vg-input-text {
              border-color: $dark-pink;
            }
          }

          .vg-label-helper-error {
            color: $dark-pink;
            opacity: 0;
            position: absolute;
            top: calc(100% + 4px);
            transform: translate3d(0, -10px, 0);
            transition: transform 0.2s $ease-default, opacity 0.2s $ease-default;
          }

          .vg-input-text {
            border: {
              color: $light-gray-1;
              radius: 4px;
              style: solid;
              width: 1px;
            }
            color: $dark-gray-2;
            display: block;
            font-size: 1.4em;
            padding: 12px 8px;
            width: 100%;
            transition: border-color 0.2s $ease-default;

            &.vg-uppercase {
              text-transform: uppercase;
            }

            &:focus {
              outline: none;
            }
          }
        }

        .vg-custom-radio {
          margin-top: 8px;

          .vg-label {
            display: block;
            margin-right: 0;
            margin-bottom: 8px;
          }
        }
        .vg-phase-actions {
          border: none;
          li {
            border: 1px solid $light-gray-1;
            border-radius: 2px;
            margin-bottom: 16px;
            position: relative;
            p {
              margin-bottom: 0;
            }
            button {
              position: absolute;
              right: 8px;
              top: 24px;
            }
          }
        }
      }
      .vg-add-filter {
        color: $orange;
        cursor: pointer;
        font-size: 1.4em;
        float: right;

        .vg-icon {
          margin-right: 4px;
        }
      }
    }

    .vg-custom-select {
      display: inline-block;
      margin: 0 auto;
      max-width: 260px;

      &.vg-redes-select {
        margin: 0 0 0 8px !important;
      }

      &.vg-error-select {
        border: 1px solid $dark-pink;
      }
    }

    &-inside-buttons {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 24px 0 0;

      [class^="vg-btn"] {
        + [class^="vg-btn"] {
          margin-top: 16px;
        }
      }

      p {
        font-size: 1.3em;
        margin-top: 16px;
      }
    }
  }

  .vg-wrap-buttons {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px 0;
  }

  .vg-action-create {
    .vg-label {
      text-align: left;
    }

    .vg-row {
      + .vg-row {
        margin-top: 16px;
      }

      p {
        text-align: left;
        display: inline-block;
        vertical-align: text-top;
        margin-right: 16px;

        + .vg-custom-select {
          display: inline-block;
        }
      }

      .vg-editor-wrapper {
        margin: 0;

        &.vg-empty-content-error {
          border-color: $cinnabar;
        }
      }
    }

    .vg-custom-select {
      margin: 8px 0;
    }
  }

  .vg-collapse-list {
    .vg-changed-item .vg-collapse-item {
      animation: focusOnChange 1s $ease-default both;
    }
  }
  .vg {
    &-notifications {
      margin-top: 16px;
      width: 100%;
    }
    &-selects-divisons-tests,
    &-record-deadline {
      display: flex;
      flex-wrap: wrap;
      padding: 0 0.5rem;
      width: 100%;

      h3,
      p {
        color: $black;
        text-align: left;
        width: 100%;
      }

      h3 {
        font-size: 1.5em;
        font-weight: 600;
        margin-top: 0;
      }
      p {
        font-size: 1.4em;
        font-weight: 400;
      }
      &-form {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        @media (max-width: $mobile-width) {
          justify-content: space-between;
        }
      }
      &-btn-count {
        background-color: $orange;
        border: 0;
        border-radius: 5px;
        cursor: pointer;
        height: 39px;
        width: 73px;

        &:disabled,
        &[disabled] {
          cursor: default;
          opacity: 0.4;
        }

        svg,
        path {
          fill: $white;
        }
      }
      &-view {
        border: 1px solid $light-gray-1;
        border-radius: 5px;
        color: $black;
        font-size: 1.4em;
        font-weight: 400;
        height: 39px;
        margin: 0 8px;
        text-align: center;
        width: 126px;

        @media (max-width: $large-mobile-width) {
          width: 100px;
        }
      }
    }
    &-select-test {
      margin-top: 16px;
      width: 100%;

      @include large-mobile {
        max-width: 300px;
      }
    }
    &-content-test {
      width: 100%;
    }
    &-title-test {
      margin-bottom: 16px;
    }
  }
}

.vg-text-left {
  text-align: left;
}

@keyframes focusOnChange {
  0% {
    background: $white;
  }

  50% {
    background: $toast-light-yellow;
  }

  100% {
    background: $white;
  }
}
@include min-height-desktop {
  .vg-phases-wrapper {
    .vg-wrap-buttons {
      padding: 10px 90px 0;
    }
  }
}
