//
// Tabs
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-custom-select {
  background: $white;
  border: 1px solid $light-gray-1;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  font-size: 10px;
  height: 37px;
  margin: 0 0 10px;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  vertical-align: middle;

  select {
    appearance: none;
    background: $white;
    border: 0;
    color: $dark-gray-1;
    display: inline-block;
    font-size: 1.4em;
    height: inherit;
    outline: none;
    padding: 0 42px 4px 10px;
    position: relative;
    width: 100%;
    width: 145% \9;
    z-index: 1;
  }

  &::after {
    background-color: $white;
    content: "";
    height: inherit;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    z-index: 1;
  }

  &::before {
    @include vertical-centered;
    background: url("../../../assets/arrow.svg") no-repeat;
    content: "";
    height: 7px;
    pointer-events: none;
    right: 10px;
    width: 10px;
    z-index: 2;
  }
}
