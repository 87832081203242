//
// Search Period
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.react-datepicker.vg-calendar {
  border: 1px solid $light-gray-2;

  .react-datepicker__header {
    background-color: $light-gray-3;
    border-bottom-color: $light-gray-2;
  }

  .react-datepicker__triangle {
    &::before {
      border-bottom-color: $light-gray-2 !important;
    }
  }

  .react-datepicker__day--in-range {
    background-color: $orange;
  }

  .react-datepicker__day--in-selecting-range:not(
      .react-datepicker__day--in-range
    ),
  .react-datepicker__day:hover {
    background-color: rgba($orange, 0.2);
    color: $dark-gray-1;
  }

  .react-datepicker__input-container {
    width: 100%;
  }
}

.vg-sub-menu-periodo {
  .vg-custom-radio .vg-label {
    margin-bottom: 16px;
  }

  .vg-invalid-date {
    background-color: rgba($dark-pink, 0.2);
    border: solid 1px $dark-pink;
    border-radius: 4px;
    font-size: 1.4em;
    margin: 0 0 8px;
    padding: 4px;
  }

  .vg-tit-data {
    border-bottom: 1px solid $light-gray-1;
    font-size: 1.3em;
    padding: 0 0 8px;
    margin-bottom: 8px;
  }

  .vg-date-interval {
    color: $dark-gray-1;
    display: inline-block;
    font-size: 1.3em;
    padding: 0 4px;
    text-align: center;
  }

  .vg-label-text {
    padding: 0 0 0 20px;
    width: 100%;
  }

  .vg-input-date {
    margin-bottom: 16px;
    width: 100%;
  }

  .vg-label-date > span {
    display: block;
    font-size: 1.3em;
    margin-bottom: 8px;
  }
}

.vg-fieldset-borderless {
  border: none;
}
