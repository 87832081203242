//
// Video Recorder
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.vg-video-recorder {
  margin: 0 auto;
  max-width: 560px;
  position: relative;
  width: 100%;

  &-readtime {
    @extend %spread;
    align-items: center;
    background: linear-gradient(
      to bottom,
      rgba($dark-blue, 0.8) 20%,
      rgba($dark-blue, 0.1) 100%
    );
    color: $white;
    display: flex;
    font-size: 1.5em;
    justify-content: center;
    pointer-events: none;
    z-index: 9999;
    border-radius: 5px;
  }

  .vg-video-wrapper {
    border-radius: 5px;
    box-shadow: 0 2px 1px rgba($black, 0.2);
    height: 0;
    overflow: hidden;
    padding-bottom: 75%;
    position: relative;

    [data-selector*="recorder-container"],
    [data-selector="recorder-player"],
    .ba-videoplayer-space-theme-container {
      @extend %spread;
      height: 100% !important;
      margin: 0;
      overflow: hidden;
      width: 100% !important;

      // .ba-videorecorder-theme-space-chooser-button-1 {
      //   display: none;
      // }
    }
  }
}
