@import "../../../styles/base/global";

.divider {
  height: 1px;
  background: #D0D5DD;
  width: 100%;
  max-width: 1222px;
  margin: 0 auto;

  @media screen and (max-width: 1228px) {
    max-width: 90vw;
    margin: 0 2rem;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 6rem;
  margin: 0 auto;
  background-color: #fff;
}

.hero {
  background: url('../../../assets/landingPageTriagem/bg-landing-page-form.svg') no-repeat right bottom;
  background-repeat: no-repeat;

  .hero-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    max-width: 1222px;
    margin: 2rem auto 0 auto;

    @media screen and (max-width: 1228px) {
      margin: 0 2rem;
    }

    @media screen and (max-width: 998px) {
      display: flex;
      flex-direction: column;
    }
  }

  .content-start {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media screen and (max-width: 998px) {
      margin-top: 3rem;
    }

    .content-start-headings {
      display: flex;
      flex-direction: column;
    }

    .content-start-heading {
      max-width: 560px;
    }

    .content-start-heading,
    .content-start-subheading {
      font-size: 56px;
      line-height: 72px;
      font-weight: 700;
    }

    .content-start-subheading {
      color: #fb6501;
    }

    .content-start-description {
      line-height: 32px;
      color: #667085;
      font-size: 1rem;
      max-width: 392px;
    }
  }
}

.form {
  width: 640px;
  height: 828px;

  @media screen and (max-width: 998px) {
    width: 100%;
    height: 828px;
  }
}

.sectionExplain {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 2rem;
  height: 450px;

  @media screen and (max-width: 998px) {
    margin: 0 2rem;
    height: 600px;
  }

  @media screen and (max-width: 998px) {
    display: flex;
    flex-direction: column;
  }

  .content-start {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .content-start-image-notebook {
    position: absolute;
    width: 100%;
    height: auto;
    max-width: 540px;
    left: -45px;

    @media screen and (max-width: 998px) {
      max-width: 400px;
    }
  }

  .content-end {
    position: relative;

    .content-end-text {
      position: relative;
      color: #fb6501;
      font-size: 24px;
      line-height: 38px;
      font-weight: 600;
      max-width: 495px;

      @media screen and (max-width: 1228px) {
        text-align: center;
      }
    }

    .content-end-symbol-star {
      position: absolute;
      right: 0;
      top: -180px;

      @media screen and (max-width: 998px) {
        display: none;
      }
    }

    .content-end-symbol-arrow {
      position: absolute;
      left: -516px;
      top: -120px;

      @media screen and (max-width: 998px) {
        display: none;
      }
    }

    .content-end-arrowRight {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.sectionInfoItems {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem 2rem;
  max-width: 1222px;
  margin: 0 auto;

  @media screen and (max-width: 1228px) {
    margin: 0 2rem;
  }

  @media screen and (max-width: 998px) {
    grid-template-columns: 1fr;
  }
}

.infoItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  .info-item-title {
    font-size: 18px;
    line-height: 32px;
    font-weight: 600;
  }

  .info-item-description {
    text-align: center;
    color: #667085;
    font-size: 16px;
    line-height: 24px;
  }
}

.itemIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 56px;
  border-radius: 50%;
  background: #FDEAD7;
}

.sectionCTA {
  display: grid;
  grid-template-columns: 240px 1fr;
  width: 100%;
  max-width: 1222px;
  margin: 0 auto 6rem auto;

  border-radius: 0.5rem;
  background-color: #F9FAFB;

  @media screen and (max-width: 1228px) {
    margin: 0 2rem 4rem 2rem;
    max-width: 90vw;
  }

  @media screen and (max-width: 998px) {
    display: flex;
    flex-direction: column;
  }

  .sectionCTA-image {
    border-radius: 0.5rem 0 0 0.5rem;
    width: 100%;
    background: url('../../../assets/landingPageTriagem/landing-page-woman.jpg') no-repeat center center;
    background-size: cover;

    @media screen and (max-width: 998px) {
      width: 100%;
      height: 240px;
      border-radius: 0.5rem 0.5rem 0 0;
    }
  }

  .sectionCTA-infos {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 1.5rem;
  }

  .sectionCTA-infos-title {
    font-size: 24px;
    font-weight: 700;

    @media screen and (max-width: 998px) {
      text-align: center;
    }
  }

  .sectionCTA-infos-description {
    font-size: 16px;
    color: #667085;
    line-height: 24px;

    @media screen and (max-width: 998px) {
      text-align: center;
    }
  }
}

.itemIcon {
  height: 28px;
  width: 28px;
  background-repeat: no-repeat;
  background-position: center center;
}

.button {
  margin-top: 0.5rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 256px;

  font-size: 16px;

  background-color: #475467;
  color: #fff;

  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;

  border: none;

  :hover {
    background-color: var(--gray-500);
  }

  @media screen and (max-width: 998px) {
    width: 100%;
    height: 40px;
  }
}