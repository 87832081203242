@import "../../../styles/base/global";

.vg-video-detail-comments-list-item {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding-bottom: 16px;
  padding-left: 8px;
  padding-right: 8px;

  .vg-icon-star {
    width: 10px;
    height: 10px;
  }

  .vg-item-avatar {
    padding: 8px;
  }

  .vg-item-user-name {
    font-size: 1.4em;
    line-height: 1.71;
    letter-spacing: normal;
    color: $dark-gray-2;
  }

  .vg-item-comment {
    font-size: 14px;
    line-height: 1.43;
    color: $dark-gray-5;
  }
}
