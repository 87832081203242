@import "../../../styles/base/global";

.vg-checkbox-container {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  width: 100%;
  min-height: 80px;
  opacity: 1;
  transition: 0.2s;

  @media (max-width: $desktop-width) {
    width: 100%;
    height: 100%;
  }

  .vg-checkbox-bg {
    background-color: $white;
    opacity: 0.3;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.vg-checkbox-hoverable {
  opacity: 0;

  &:hover {
    opacity: 1;
  }
}

.vg-check-share {
  min-height: 40px;
  min-width: 40px;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 1;
}
