//
// Root
// -----------------------------------------------------------------------------

@-ms-viewport {
  width: device-width;
}
@-o-viewport {
  width: device-width;
}
@viewport {
  width: device-width;
}

html {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  font-kerning: auto;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  min-height: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

body {
  background-color: $body-background;
  color: $text-color;
  font-family: $base-font-family;
  font-size: $base-font-size;

  &.vg-no-scroll {
    overflow-y: hidden;
  }

  &.vg-candidate-theme {
    background-color: $white;
  }
}

.vg-main {
  padding-top: $header-height;
}

.vg-placeholder {
  @include rounded-white;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
}
