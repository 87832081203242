@import "../../../styles/base/global";

.vg-star-filled {
  margin-left: 8px;

  .vg-line-star {
    opacity: 0;
  }
  .vg-fill-star {
    opacity: 1;
    fill: $star-yellow;
  }
}

.vg-star-unfilled {
  margin-left: 8px;

  .vg-line-star {
    fill: $dark-gray-3;
    opacity: 1;
  }
  .vg-fill-star {
    opacity: 0;
  }
}
