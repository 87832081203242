//
// Help List Item
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.vg-faq-item {
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 32px;
  }

  h3 {
    margin: 0;
  }

  p {
    margin: 0;
    font-size: 8px;
  }

  a {
    color: $orange;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
  }
}
