@import "../../../styles/base/global";

.vg-video-interview-ratings-list {
  margin-bottom: 16px;
  width: 100%;

  h4 {
    float: left;
    margin: 0 0 8px;
  }

  .vg-title {
    border-bottom: 1px solid $light-gray-2;
    color: $dark-gray-2;
    font-size: 14px;
    line-height: 1.14;
    min-height: 48px;
    padding: 16px;

    .vg-average-rating {
      float: right;
    }

    .vg-average-rating-text {
      margin-right: 8px;
      span {
        font-weight: 600;
      }
    }
  }

  .vg-list-container {
    width: 100%;
    padding-top: 16px;
    height: 130px;
    overflow: auto;
  }

  width: 100%;
}
