//
// Lists
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.vg-modal-sorting {
  padding: 20px;
  text-align: center;
  &-content {
    max-width: 80%;
    margin: 0 auto;
  }
  h2 {
    color: $dark-gray-1;
    font-size: 2.6em;
    font-weight: $regular;
    margin: 0 0 8px;
  }

  p {
    color: $dark-gray-1;
    font-size: 1.4em;
    line-height: 1.5;
    margin: 0 0 15px;
  }

  .vg-link-triagem {
    border: solid 1px $light-gray-1;
    border-radius: 4px;
    display: block;
    outline: none;
    padding: 16px;
    position: relative;
    margin: 0 0 16px;
    width: 100%;

    &:hover {
      border-color: $green;
      cursor: pointer;
    }

    p {
      color: $dark-gray-3;
      font-size: 1.5em;
      margin: 10px 0 0;
    }
    .vg-icon {
      width: 90%;
      height: auto;
      &-aprovada_com_vaga {
        fill: $green;
        bottom: 16px;
        display: none;
        height: 16px;
        right: 10px;
        position: absolute;
        width: 16px;
      }
    }
    &-selected {
      border-color: $green;

      .vg-icon-aprovada_com_vaga {
        display: block;
      }
    }
  }
}
