//
// SelectEpartenon
// -----------------------------------------------------------------------------

@import "../../../../styles/base/global";

.grid-input-grade {
  width: 64px;
  & input {
    border-color: $light-gray-1;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    color: $dark-gray-2;
    display: block;
    font-size: 1.4em;
    height: 36px;
    padding: 12px 8px;
    transition: border-color 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
    width: 100%;
  }
  &.error {
    border-color: $dark-pink;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
  }
}
