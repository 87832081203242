//
// Lists
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.dialog-csp {
  li {
    font-size: 14px;

    label {
      font-size: 16px;
    }
  }

  [role="dialog"] {
    z-index: 4;
  }

  .overlay {
    z-index: 3;
  }

  box-icon[name="error"] {
    left: 4px;
    top: 3px;
  }

  box-icon[name="check-circle"] {
    left: 4px;
    top: 4px;
  }

  input {
    height: 40px !important;
  }

  ul {
    span {
      font-size: 14px;
    }
  }

  .h-\[29px\] {
    height: 24px;
  }
}

.csp-toast {
  font-size: 14px;

  h1 {
    margin-bottom: 12px !important;
  }
}

.vg-title-1 {
  &.vg-title-vacancies {
    border-top: solid 1px $light-gray-1;
    font-size: 2.3em;
    margin-top: 40px;
    padding: 40px 0 0;

    &:first-of-type {
      border: 0;
      margin-top: 0;
    }

    .vg-sub-tit {
      display: block;
      font-size: 0.7em;
      padding: 12px 0 0;

      a {
        font-size: 0.9em;
      }
    }
  }
}

.vg-list-wrap {
  list-style: none;
  margin: 0;
  padding: 0;

  .vg-list-item {
    @include rounded-white;
    margin-bottom: 16px;
    position: relative;

    .vg-list-buttons .vg-dropdown-menu {
      @include drop-down-card-corner-position;
    }

    .vg-list-buttons {
      .vg-dropdown-menu {
        @include drop-down-card-corner-position;
      }
    }

    > [class^="vg-list-"] {
      list-style: none;
    }

    .vg-icon-time {
      height: 16px;
      width: 16px;

      svg {
        fill: $dark-gray-5;
      }

      &.futura svg {
        fill: $denim;
      }

      &.em-andamento svg {
        fill: $lime-green;
      }

      &.expirada svg {
        fill: $cinnabar;
      }
    }

    .vg-icon-date-situation {
      height: 10px;
      margin-left: 8px;
      width: 10px;

      svg {
        fill: $orange;
      }
    }

    .vg-icon-opening-info-situation {
      height: 10px;
      margin-left: 8px;
      width: 10px;
      cursor: pointer;

      svg {
        fill: $dark-gray-5;
      }

      &-up {
        transition: transform 300ms;
        transform: rotate(180deg);
      }
    }

    .vg-icon-opening-date-situation {
      height: 10px;
      margin-left: 8px;
      width: 10px;
      cursor: pointer;

      svg {
        fill: $orange;
      }

      &-up {
        transition: transform 300ms;
        transform: rotate(180deg);
      }
    }

    .vg-atom-location-label {
      color: $dark-gray-5;
    }

    .vg-text-info {
      font-size: 14px;
      display: none;
      line-height: 1.71;
      margin-left: 8px;

      .vg-icon-info {
        svg {
          fill: $dark-gray-5;
        }
      }

      button {
        cursor: pointer;
        align-items: center;
        background-color: $white;
        border: 0;
        display: flex;
        margin: 0;
        padding: 0;

        .vg-icon-arrow {
          svg {
            fill: $orange;
          }
        }
      }

      @include desktop {
        display: flex;
      }
    }

    .vg-selective-process-list-header {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: 16px 16px;

      @media (min-width: $desktop-width) {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
      }

      > .vg-timeline {
        @media (min-width: $desktop-width) {
          display: none;
        }

        > .vg-atom-location {
          @media (max-width: $desktop-width) {
            display: none;
          }
        }
      }

      > .vg-atom-location {
        @media (min-width: $desktop-width) {
          display: none;
        }
      }
    }

    .vg-list-left-content {
      align-items: center;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      flex-grow: 1;

      @media (max-width: $desktop-width) {
        border-bottom: 1px solid $light-gray-2;
        margin-bottom: 16px;
        padding-bottom: 8px;
      }

      .vg-item-avatar {
        margin-right: 8px;
      }
    }

    .vg-detail {
      .vg-icon {
        margin-right: 6px;
      }

      .vg-name-vacancy {
        color: $dark-gray-1;
        font-size: 1.6em;
        font-weight: $semibold;
        line-height: 24px;
        margin: 0;

        .vg-title-vacancy {
          margin-right: 8px;
          vertical-align: middle;

          @media (min-width: $desktop-width) {
            @include text-overflow;
            display: inline-block;
            max-width: 144px;
          }
        }
      }

      .vg-name-group {
        @include text-overflow;
        border: solid 1px $light-gray-2;
        border-radius: 16px;
        box-sizing: border-box;
        color: $dark-gray-2;
        display: inline-block;
        font-size: 1em;
        font-style: normal;
        font-weight: normal;
        line-height: 1.71;
        max-width: 140px;
        padding: 2px 10px 2px 10px;
        vertical-align: bottom;
      }

      > .vg-timeline {
        @media (max-width: $desktop-width) {
          display: none;
        }
      }
    }

    .vg- {
      &code-vacancy {
        color: $dark-gray-2;
        display: inline-block;
        font-weight: $regular;
        margin-right: 8px;
        vertical-align: middle;
      }

      &icon-pcd,
      &icon-loop,
      &icon-pet,
      &icon-test {
        height: 24px;
        width: 24px;
        display: inline-block;
        vertical-align: middle;
      }
    }

    .vg-timeline {
      align-items: center;
      color: $dark-gray-5;
      display: flex;
      @media (max-width: $desktop-width) {
        align-items: flex-start;
        flex-direction: column;
      }

      .vg-icon {
        margin-right: 6px;
      }

      .vg- {
        &list-date {
          align-items: center;
          cursor: pointer;
          display: flex;
          font-size: 1.4em;
          line-height: 1.8;
          margin-right: 8px;
        }
      }
    }

    .vg-middle-section {
      display: flex;

      @media (min-width: $desktop-width) {
        flex-basis: 20%;
        flex-grow: 0;
        flex-shrink: 1;
        justify-content: center;
      }

      @media (max-width: $desktop-width) {
        .vg-dropdown-menu {
          [class*="vg-btn-outline"].vg-dropdown-button {
            border-width: 1px;
          }
        }
      }
    }

    .vg-list-buttons {
      align-items: flex-start;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;

      > [class^="vg-btn"] {
        text-align: center;
      }

      > a {
        box-sizing: border-box;
      }

      @media (max-width: $desktop-width) {
        border-top: 1px solid $light-gray-2;
        margin-top: 16px;
        padding-top: 8px;
        > a {
          margin-top: 8px;
        }
      }

      @media (min-width: $desktop-width) {
        flex-direction: row;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 35%;
        > a {
          margin-left: 8px;
        }
      }
    }
  }
}

.status-box-vacancy {
  display: flex;
  align-items: center;
}
.vg-approved-mark {
  color: $orange;
  cursor: pointer;
  display: inline-flex;
  float: right;
  font-weight: $semibold;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: normal;
  padding: 8px 16px;

  &-container {
    display: none;
    @include desktop {
      display: flex;
      flex-grow: 0;
      margin-left: 8px;
    }
  }
  &.closed-vacancy {
    color: $dark-gray-6 !important;
    cursor: not-allowed;
    & .vg-icon-user_circle {
      svg {
        fill: $dark-gray-6;
      }
    }
  }

  .vg-icon-user_circle {
    margin: 2px 8px 0 0;

    svg {
      fill: $orange;
    }
  }
}

.vg-vacancy-loader,
.vg-title-loader {
  padding: 32px;
  margin-bottom: 20px;

  .vg-icon {
    animation: infiniteFade 0.7s $ease-default both alternate infinite;
    height: 100%;
    width: 100%;
  }

  &.vg-vacancy-loader {
    @include rounded-white;
  }

  &.vg-title-loader {
    margin: 32px 0 20px;
    text-align: center;

    .vg-icon {
      width: 500px;
      margin: 0 auto;

      path {
        fill: $light-gray-1;
      }
    }
  }
}

.vg-btn-outline-light-gray {
  background-color: transparent !important;
  color: $dark-gray-2 !important;
  &:focus:not(:disabled),
  &:hover:not(:disabled) {
    background-color: transparent;
    border-color: $orange !important;
    color: $orange !important;
  }

  &:active:not(:disabled) {
    background-color: transparent !important;
    border-color: $orange !important;
    color: $orange !important;
  }
}

@include desktop {
  .vg-list-wrap {
    .vg-list-item {
      .vg-detail {
        .vg-name-vacancy {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }
}
