.badge-container {
  align-items: center;
  display: flex;
  justify-content: end;
}

.badge {
  align-items: center;
  background-color: #ecfdf3;
  border-radius: 16px;
  color: #027a48;
  display: flex;
  justify-content: center;
  margin-right: 8px;
  padding: 2px 6px;

  [data-icon="box-icon"] {
    height: 14px;
    margin-right: 4px;
    width: 12px;
  }

  span {
    font-size: 12px;
    font-weight: 600;
  }
}
