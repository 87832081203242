@import "../../../styles/base/global";

ul {
  padding-left: 0;
  li.vg-content-box {
    list-style: none;
    margin-bottom: 8px;

    &:hover {
      box-shadow: 0 2px 4px 0 rgba($black, 0.3);
    }
  }
}

.vg-link-edit {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: $orange;
  margin: auto;
  cursor: pointer;
}
