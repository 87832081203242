@import "../../../styles/base/global";

.vg-page-terms {
  @include rounded-white;

  .vg-page-terms-header {
    background-color: #dae4eb;
    border-radius: 5px 5px 0 0;

    .vg-icon-terms {
      margin: 0 auto;
      padding: 15px 0 0;
      width: 180px;
      height: 150px;

      svg {
        width: 180px;
        height: 150px;
      }
    }
  }

  .vg-text-terms {
    padding: 20px;

    p {
      font-size: 1.5em;
      line-height: 2em;
    }
  }

  .vg-link-termos {
    color: $orange;
    text-decoration: none;
  }
}

$color--green: #7ac142;
$curve: cubic-bezier(0.65, 0, 0.45, 1);

#check-icon {
  stroke: $color--green;
  transform-origin: 50% 50%;
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
  animation: stroke 1s $curve 1s both;
  stroke: 2px;
}

@keyframes stroke {
  80% {
    stroke-dashoffset: 0;
  }

  71% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@include desktop {
  .vg-page-terms {
    .vg-text-terms {
      p {
        font-size: 1.4em;
        line-height: 1.4em;
      }
    }
  }
}
