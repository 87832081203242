// Toast
// -----------------------------------------------------------------------------

.vg-main {
  .Toastify__toast-container {
    bottom: auto;
    top: 82px;
    width: 400px;

    .Toastify__toast-body {
      padding: 0;
    }
  }

  .sr-toast {
    background-color: $white;
    border-radius: $default-radius;
    box-shadow: 0 0 30px rgba($black, 0.2);
    margin: 0 auto 20px;
    padding: 0;
    width: 95%;
    z-index: 3;

    &.vg-toast-success {
      .vg-toast-icon {
        background-color: $toast-light-green;
      }

      .Toastify__progress-bar {
        @include linear-degrade($toast-light-green, $toast-green);
      }
    }

    &.vg-toast-warning {
      .vg-toast-icon {
        background-color: $toast-light-yellow;
      }

      .Toastify__progress-bar {
        @include linear-degrade($toast-light-yellow, $toast-yellow);
      }
    }

    &.vg-toast-error {
      .vg-toast-icon {
        background-color: $toast-light-red;
      }

      .Toastify__progress-bar {
        @include linear-degrade($toast-light-red, $toast-red);
      }
    }

    &.vg-toast-lgbt {
      .vg-toast-icon {
        background-color: $light-purple;
      }
    }

    .vg-toast {
      padding: 0;
      display: flex;

      &-icon {
        width: 94px;
        height: 131px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 32px;

        .vg-icon {
          width: 32px;
          height: 32px;
        }
      }

      &-content {
        padding: 0 31px;
        line-height: 1.5;

        span {
          display: flex;
          align-content: center;
          justify-content: center;
          flex-direction: column;
          height: 100%;
        }

        h1 {
          font-size: 1.15em;
          font-weight: $regular;
          margin-bottom: 0;
        }

        p {
          font-size: 0.93em;
          color: $dark-gray-2;
          margin-top: 0;

          a {
            text-decoration: underline;
          }
        }
      }
    }

    span {
      color: $dark-gray-1;
      font-size: 1.4em;
      font-weight: $light;
    }

    .Toastify__close-button {
      position: absolute;
      top: 15px;
      right: 15px;
      color: #c8c8c8;
      font-size: 12px;
      width: 9px;
      height: 9px;
      opacity: 1;
    }

    .sr-close-toast {
      background: none;
      border: 0;
      color: rgba($white, 0.4);
      cursor: pointer;
      float: right;
      outline: none;
      transition: color 0.2s $ease-default;

      &:hover {
        color: $white;
      }
    }
  }
}

@include desktop {
  .vg-main {
    .sr-toast {
      right: 15px;
      width: auto;
    }

    .Toastify__toast-container {
      bottom: 1em;
      top: auto;
      width: 400px;
    }
  }
}
