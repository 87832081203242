//
// User Navigation
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-dashboard-button {
  display: block;
  position: relative;
  width: 100%;

  a,
  li.vg-item-credits {
    color: $white;
    font-size: 1.8em;
    font-weight: $semibold;
    line-height: 0;
    position: relative;
    text-decoration: none;
  }

  .vg-user-link {
    display: none;
  }

  .vg-user-menu {
    list-style: none;
    margin: 0;
    padding: 0;

    & > h3 {
      display: none;
    }

    li {
      margin-bottom: 30px;
      text-align: center;

      &:last-child {
        margin-right: 0;
      }

      a {
        position: relative;
        text-decoration: none;

        & > .round-link {
          display: none;
        }

        &:not(.vg-btn-success) {
          &::after {
            @include horizontal-centered;
            background-color: $white;
            border-radius: 4px;
            content: "";
            height: 2px;
            opacity: 0;
            position: absolute;
            top: 108%;
            transform: translate3d(-50%, 10px, 0);
            transition: opacity 0.2s $ease-default, transform 0.2s $ease-default,
              width 0.4s $ease-default;
            width: 4px;
            will-change: transform;
          }

          &:hover,
          &.vg-link-active {
            &::after {
              opacity: 1;
              transform: translate3d(-50%, 5px, 0);
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@include desktop {
  .vg-dashboard-button {
    float: right;
    margin: 6px 0 0;
    border-left: 0px;

    a,
    li.vg-item-credits {
      font-size: 1.4em;
      font-weight: $regular;
      line-height: normal;
    }

    &.vg-user-active {
      .vg-user-menu {
        opacity: 1;
        transform: translate3d(0, 15px, 0);
        transition: opacity 0.8s $ease-default, transform 0.3s $ease-default,
          visibility 0.4s linear;
        visibility: visible;
      }

      .vg-user-link .vg-icon svg {
        transform: rotate(180deg);
      }
    }

    .vg-user-link {
      display: block;
      font-size: 1.4em;
      font-weight: $bold;
      overflow: hidden;
      position: relative;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      background: transparent;
      border: none;
      cursor: pointer;
      padding-left: 0;

      .vg-icon {
        fill: $white;
        height: 10px;
        margin-right: 4px;
        position: absolute;
        right: 0;
        top: 4px;
        width: 7px;

        svg {
          transform: rotate(0);
          transition: transform 0.2s $ease-default;
        }
      }
    }

    .vg-user-menu {
      @include rounded-white;
      border-radius: 2px;
      opacity: 0;
      padding: 10px 15px;
      position: absolute;
      right: -13px;
      top: 100%;
      transform: translate3d(0, 40px, 0);
      transition: opacity 0.3s $ease-default, transform 0.3s $ease-default,
        visibility 0.4s linear;
      visibility: hidden;
      width: 300px;
      z-index: 2;

      & > h3 {
        color: #000000;
        display: block;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
      }

      & > .row {
        display: flex;
        align-items: center;
      }

      li {
        display: block;
        flex: 1;
        margin-bottom: 0;
        text-align: left;

        &.vg-bottom-separator {
          border-bottom: 1px solid $dark-gray-3;
        }

        .vg-icon {
          vertical-align: middle;
          margin-right: 8px;
          display: inline-block;
          path {
            fill: $orange;
          }
        }

        &.vg-item-credits {
          padding: 17px 5px;
          color: $dark-gray-3;
          font-weight: $semibold;

          .vg-icon {
            width: 30px;
            height: 30px;
          }
        }

        a {
          align-items: center;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          padding: 10px 5px;
          text-decoration: none;
          width: 100%;

          &:not(.vg-btn-success) {
            color: $dark-gray-1;
          }

          &:hover {
            background-color: $light-gray-3;
          }

          &.btn-comprar-creditos {
            font-weight: $bold;
            text-align: center;
          }

          & > .label {
            margin-top: 8px;
          }

          & > .round-link {
            align-items: center;
            background: rgba(230, 230, 230, 0.5);
            border-radius: 28px;
            display: flex;
            height: 56px;
            justify-content: center;
            position: relative;
            width: 56px;
            box-icon {
              fill: #373737;
              height: 32px;
              width: 32px;
            }
            & > .round-link--badge-new {
              align-items: center;
              background: #2dac3a;
              border-radius: 30px;
              bottom: -3px;
              color: #fff;
              display: flex;
              font-size: 12px;
              font-weight: 600;
              height: 16px;
              justify-content: center;
              left: -5px;
              line-height: 16px;
              position: absolute;
              right: -5px;
            }
          }

          &:hover {
            & > .round-link {
              background: linear-gradient(90deg, #f7902a 0%, #fa6400 100%);
              box-icon {
                fill: #fff;
              }
            }
          }

          &:not(.vg-btn-success) {
            &::after {
              height: 0px;
            }

            &:hover,
            &.vg-link-active {
              &::after {
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }
}
