//
// User Navigation
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-user-navigation {
  display: block;
  position: relative;
  width: 100%;

  a,
  li.vg-item-credits {
    color: $white;
    font-size: 1.8em;
    font-weight: $semibold;
    line-height: 0;
    position: relative;
    text-decoration: none;
  }

  .vg-user-link {
    display: none;
  }

  .vg-user-menu {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 30px;
      text-align: center;

      &:last-child {
        margin-right: 0;
      }

      a {
        position: relative;
        text-decoration: none;

        &:not(.vg-btn-success) {
          &::after {
            @include horizontal-centered;
            background-color: $white;
            border-radius: 4px;
            content: "";
            height: 2px;
            opacity: 0;
            position: absolute;
            top: 108%;
            transform: translate3d(-50%, 10px, 0);
            transition: opacity 0.2s $ease-default, transform 0.2s $ease-default,
              width 0.4s $ease-default;
            width: 4px;
            will-change: transform;
          }

          &:hover,
          &.vg-link-active {
            &::after {
              opacity: 1;
              transform: translate3d(-50%, 5px, 0);
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@include desktop {
  .vg-user-navigation {
    float: right;
    margin: 6px 0 0;
    border-left: 1px solid $white;
    padding-left: 16px;
    width: 100px;
    margin-top: 8px;

    a,
    li.vg-item-credits {
      font-size: 1.4em;
      font-weight: $regular;
      line-height: normal;
    }

    &.vg-user-active {
      .vg-user-menu {
        opacity: 1;
        transform: translate3d(0, 15px, 0);
        transition: opacity 0.8s $ease-default, transform 0.3s $ease-default,
          visibility 0.4s linear;
        visibility: visible;
      }

      .vg-user-link .vg-icon svg {
        transform: rotate(180deg);
      }
    }

    .vg-user-link {
      clear: both;
      display: block;
      font-size: 1.4em;
      font-weight: $bold;
      overflow: hidden;
      padding-right: 20px;
      position: relative;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      background: none;
      border: none;
      cursor: pointer;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .vg-icon {
        fill: $white;
        height: 10px;
        margin-right: 4px;
        position: absolute;
        right: 0;
        top: 4px;
        width: 7px;

        svg {
          transform: rotate(0);
          transition: transform 0.2s $ease-default;
        }
      }
    }

    .vg-user-menu {
      @include rounded-white;
      display: block;
      opacity: 0;
      padding: 10px 15px;
      position: absolute;
      right: -13px;
      top: 100%;
      transform: translate3d(0, 40px, 0);
      transition: opacity 0.3s $ease-default, transform 0.3s $ease-default,
        visibility 0.4s linear;
      visibility: hidden;
      width: 300px;
      z-index: 2;

      &::after,
      &::before {
        border: 10px solid transparent;
        border-bottom-color: $light-gray-1;
        content: " ";
        height: 0;
        pointer-events: none;
        position: absolute;
        right: 3px;
        top: -26px;
        width: 0;
      }

      &::after {
        border-bottom-color: $white;
        border-width: 17px;
      }

      &::before {
        right: 10px;
        top: -20px;
      }

      li {
        display: block;
        margin-bottom: 0;
        text-align: left;

        &.vg-bottom-separator {
          border-bottom: 1px solid $dark-gray-3;
        }

        .vg-icon {
          vertical-align: middle;
          margin-right: 8px;
          display: inline-block;
          path {
            fill: $orange;
          }
        }

        &.vg-item-credits {
          padding: 17px 5px;
          color: $dark-gray-3;
          font-weight: $semibold;

          .vg-icon {
            width: 30px;
            height: 30px;
          }
        }

        a {
          display: inline-block;
          padding: 10px 5px;
          text-decoration: none;
          width: 100%;
          cursor: pointer;

          &:not(.vg-btn-success) {
            color: $dark-gray-1;
          }

          &:hover {
            background-color: $light-gray-3;
          }

          &.btn-comprar-creditos {
            font-weight: $bold;
            text-align: center;
          }
        }
      }
    }
  }
}
