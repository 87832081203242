@import "../../../styles/base/global";

.vg-custom-select {
  margin: 10px 30px 0;
  max-width: 250px;

  &-subtitle {
    margin: 10px 10px 0;
    max-width: 250px;
  }
}

.vg-inline-block {
  display: inline-block;
}
