@import "../../../styles/base/global";

.vg-container-list-title,
.vg-subtitle {
  text-align: center;
  font-size: 15px;
  color: $dark-gray-1;
}

.vg-subtitle {
  margin: 0 0 60px;

  a {
    margin-left: 8px;
  }
}

.vg-container-list-title {
  font-size: 18px;
  margin: 0;
  span {
    display: block;
    font-size: 15px !important;
    margin-top: 8px;
  }
}

.vg-container-list {
  .vg-content-email {
    margin: 22px 18px;

    .vg-text-email {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      display: inline-block;
      width: 100%;
      cursor: pointer;

      span {
        margin-left: 8px;
        display: inline-block;
      }

      .vg-icon-envelope {
        margin: auto;
        margin-right: 8px;

        svg {
          fill: $orange;
        }
      }

      .vg-icon-edit {
        margin: auto;
        width: 10px;

        svg {
          fill: $dark-gray-2;
        }
      }
    }

    .vg-email-empty {
      color: $orange;
    }

    .vg-display-true {
      display: block;
    }

    .vg-display-false {
      display: none;
    }

    .vg-box-input-email {
      margin-top: 16px;
      display: flex;
      justify-content: center;

      fieldset {
        padding: 0;
        border: none;
      }

      input {
        box-sizing: border-box;
        border-radius: 2px;
        width: 500px;
        height: 35px;
        border: 1px solid $dark-gray-2;
        font-size: 13px;
        margin-right: 8px;
      }

      a {
        font-weight: 600;
        font-size: 13px;
        height: 35px;
        margin-right: 8px;
      }

      span.error {
        width: 100%;
        padding: 4px;

        span {
          font-size: 13px !important;
          padding: 0;
          margin: 0;
        }
      }
    }
  }

  .vg-container-user-infos {
    color: $dark-gray-1;
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    position: relative;
    width: 100%;

    > span {
      width: 16px;
      margin: auto 0;
    }

    > div {
      flex: 1 auto;
      display: flex;
      flex-direction: row;
      flex-flow: row wrap;

      > .vg-content-user-infos {
        display: block;
        margin: auto 0 0;
        width: 100%;

        p {
          margin: 0 0 8px;
          font-size: 1.2em;
        }

        > span {
          font-size: 13px;
          .vg-icon {
            margin-right: 8px;
            fill: $dark-gray-2;
            height: 16px;
            width: 16px;
            position: relative;
            bottom: 2px;
          }
          .vg-content-user-infos-id {
            margin-right: 24px;
          }
          .vg-content-user-infos-email {
            display: block;
            margin: 8px 0;

            .vg-icon {
              bottom: 1px;
            }
          }
        }
      }

      > .vg-content-user-type {
        display: block;
        margin: auto 0 8px;
        text-align: center;
        text-transform: capitalize;
        width: 100%;
      }

      > button {
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        margin: auto 0;
        min-height: 36px;
        width: 100%;
      }
    }
  }
}

@include desktop {
  .vg-container-list {
    .vg-container-user-infos {
      > div {
        > .vg-content-user-infos {
          width: 45%;

          > span .vg-content-user-infos-email {
            display: initial;
            margin-top: 0;
          }
        }
        > .vg-content-user-type {
          width: 25%;
          text-align: left;
          margin: auto 0;
        }
        > button {
          position: absolute;
          right: 24px;
          top: 30px;
          width: auto;
        }
      }
    }
  }
}
