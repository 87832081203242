//
// Footer
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.vg-footer {
  margin: 20px 0 56px;
  text-align: center;

  .vg-container {
    position: relative;
  }

  p {
    border-top: solid 1px $light-gray-1;
    color: $dark-gray-1;
    font-size: 1.3em;
    line-height: 1.5;
    padding-top: 20px;

    .vg-icon-vagas {
      display: block;
      height: 24px;
      margin: 0 auto 10px;
      vertical-align: top;
      width: 86px;
    }

    .vg-link {
      color: $orange;
      font-weight: bold;
      margin-left: 5px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .vg-retorno-asp {
    display: block;
    background-color: $white;
    bottom: 0;
    position: fixed;
    transform: translate3d(0, 0, 0);
    width: 100%;
    z-index: 3;
    text-align: center;

    p {
      border: 0px;
      color: $dark-gray-2;
      padding: 0 16px;

      .vg-icon-warning {
        margin-right: 8px;
        path {
          stroke: $orange;
        }
      }

      a {
        color: $orange;
        cursor: pointer;
      }
    }
  }
}

@include desktop {
  .vg-footer {
    text-align: left;

    p .vg-icon-vagas {
      display: inline-block;
      margin-right: 10px;
      margin-top: -2px;
    }
  }
}
