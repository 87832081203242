@import "../../../styles/base/global";

.vg-box-share {
  box-shadow: 0 2px 2px rgba($black, 0.2);
  background-color: $white;
  border-radius: 5px;
  display: flex;
  margin: 0 0 16px 0;
  width: 100%;
  padding: 10px 20px;

  a {
    max-width: 125px;
    padding: 14px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  p {
    color: $dark-gray-1;
    margin-left: 10px;
    font-size: 1.4em;

    .vg-text-share {
      font-weight: $bold;
    }
  }
}
