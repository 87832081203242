//
// Tooltip
// -----------------------------------------------------------------------------
@import "../../../../styles/base/global";

.tooltip-grade {
  cursor: pointer;
  position: relative;

  &:hover {
    text-decoration: none;
  }

  & .titulo-notas-atencao {
    color: $light-orange-4;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
  }

  & .tooltip-message {
    background-color: $white;
    border-radius: 4px;
    position: absolute;
    border: 1px solid $light-gray-1;
    box-shadow: 0 1px 2px 0 rgba($black, 0.3);
    color: $dark-gray-1;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    padding: 0px 8px;
    z-index: 10;
    width: 700px;

    & .content-tooltip-nota {
      display: inline-block;
      padding-bottom: 16px;
      white-space: normal;
      text-align: left;
    }

    &.on-top {
      left: 50%;
      top: (-$tooltip-offset-default);
      transform: translateX(-50%);

      &::after {
        border: {
          left: 8px solid transparent;
          right: 8px solid transparent;
          top: 8px solid $light-gray-1;
        }
        bottom: -12px;
        content: "";
        position: absolute;
        right: 50%;
        transform: translate(50%, -50%);
      }
    }

    &.on-right {
      left: 100%;
      top: 50%;
      margin-left: 11px;
      transform: translateY(-50%);

      &::after {
        border: {
          top: 8px solid transparent;
          right: 8px solid $light-gray-1;
          bottom: 8px solid transparent;
        }
        left: -8px;
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &.on-bottom {
      bottom: -253px;
      left: 1550%;
      transform: translateX(-50%);

      &::after {
        border: {
          left: 8px solid transparent;
          right: 8px solid transparent;
          bottom: 8px solid $light-gray-1;
        }
        content: "";
        position: absolute;
        right: 88.9%;
        top: -8px;
        transform: translate(50%, 0%);
      }
    }

    &.on-left {
      margin-right: 11px;
      right: 100%;
      top: 50%;
      transform: translateY(-50%);

      &::after {
        border: {
          bottom: 8px solid transparent;
          top: 8px solid transparent;
          left: 8px solid $light-gray-1;
        }
        content: "";
        position: absolute;
        right: -8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

@media (max-width: $tablet-width) {
  .tooltip-grade {
    cursor: pointer;
    display: flex;
    margin-bottom: 8px;
    position: relative;
    & .content-tooltip-nota {
      padding: 0;
    }
    & .tooltip-message {
      position: relative;
      &.on-bottom {
        bottom: 0;
        display: flex;
        flex-direction: column;
        height: 100%;
        left: 49%;
        padding: 8px;
        position: relative;
        width: 100%;
        &::after {
          content: unset;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
  }
}
