@import "../../../styles/base/global";

.vg-title-1.vg-title-redes {
  margin-bottom: 8px;
}

.vg-subtitle > a {
  color: $orange;
  cursor: pointer;
  text-decoration: underline;
}

.vg-content-select {
  background-color: rgba($white, 0.5);
  padding: 14px 0;
  text-align: center;

  span {
    font-size: 14px;
    margin-right: 10px;
  }

  .vg-custom-select {
    display: inline-block;
    margin: 0;
  }
}
