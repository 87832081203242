//
// Status
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.vg-modal-status {
  height: 400px;
  max-height: 400px;
  padding: 24px;

  .vg-icon-requesterPlaceholder {
    height: auto;
    width: 100%;
  }

  .vg-requisition-name {
    color: $dark-gray-1;
    font-size: 1.6em;
    line-height: 1.6em;
    margin: 0;
    padding: 0;

    span {
      display: block;
    }
  }

  .vg-requisition-status-list {
    list-style: none;
    margin: 16px 0 0;
    overflow: hidden;

    .vg-requisition-status-item {
      padding: 8px 8px 8px 50px;
      position: relative;

      &-initial {
        background: transparent url("../../../assets/line.png") left top repeat-y;
      }

      &-pos {
        background-color: $light-gray-2;
      }

      &:after {
        background-color: $light-gray-1;
        content: "";
        display: block;
        height: 1000px;
        left: 24px;
        position: absolute;
        top: calc(-1000px + 44px);
        width: 4px;
      }

      &:before {
        background-color: $light-gray-1;
        border-radius: 50%;
        content: "";
        display: block;
        height: 16px;
        left: 18px;
        position: absolute;
        top: 39px;
        width: 16px;
      }

      .vg-icon {
        border: solid 1px $white;
        border-radius: 50%;
        display: none;
        fill: $light-gray-1;
        height: 16px;
        left: 18px;
        top: 38px;
        position: absolute;
        width: 16px;
        z-index: 3;

        svg {
          border: solid 1px $white;
          border-radius: 50%;
        }

        &:before {
          background-color: $white;
          border-radius: 50%;
          content: "";
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: -1;
        }

        &:after {
          background-color: $light-gray-1;
          border-radius: 50%;
          content: "";
          display: block;
          height: 24px;
          left: -5px;
          position: absolute;
          top: -5px;
          width: 24px;
          z-index: -2;
        }
      }

      &-initial {
        &::after {
          background-color: $light-gray-2;
          top: calc(-1000px + 39px);
        }
        &::before {
          background-color: $sla-green;
        }
      }

      &.aprovada,
      &.aprovada_por_este_aprovador,
      &-with,
      &-aprovado-pre-aprovacao {
        &:after,
        &:before {
          background-color: $sla-green;
        }

        .vg-icon {
          display: block;
          fill: $sla-green;

          &:after {
            background-color: $sla-green;
            display: block;
          }
        }
      }

      &.aguardando_definicao,
      &.aguardando_aprovacao,
      &-waiting,
      &-aguardando-pre-aprovacao {
        &:after,
        &:before {
          background-color: $light-orange;
        }

        .vg-icon {
          display: block;
          fill: $light-orange;
          z-index: 3;

          &:after {
            background-color: $light-orange;
            display: block;
          }
        }
      }

      &-canceled,
      &-reprovada-pre-aprovacao,
      &.reprovada_por_este_aprovador {
        &:after,
        &:before {
          background-color: $red-status-2;
        }

        .vg-icon {
          display: block;
          fill: $red-status-2;
          z-index: 3;

          &:after {
            background-color: $red-status-2;
            display: block;
          }
        }
      }

      .vg-status-desc {
        color: $dark-gray-3;
        display: block;
        font-size: 1.3em;
        line-height: 24px;
      }

      .vg-status-name,
      .vg-status-date {
        color: $dark-gray-1;
        font-size: 1.6em;
        font-weight: $semibold;
        line-height: 24px;
        margin: 0;
      }

      .vg-status-date {
        font-weight: $regular;
      }

      .vg-status-justification {
        color: $dark-gray-3;
        display: block;
        font-size: 13px;
      }
    }
  }
}
