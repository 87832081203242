@import "../../../styles/base/global";

.vg-address-container {
  .vg-content-box {
    margin: 0 auto;
    width: 43vw;
    .vg-icon {
      height: auto;
      width: auto;
      svg {
        fill: $light-gray-1;
      }
    }
    .vg-address-title-loader {
      margin-bottom: 22px;
    }
    .vg-address-collapse-title-loader,
    .vg-address-collapse-loader {
      background-color: $light-gray-2;
      border-bottom: 1px solid $light-gray-7;
      padding: 16px;
    }

    .vg-address-collapse-title-loader {
      border: none;
      margin-bottom: 8px;
    }
  }
  .vg-address-top-loader {
    background-color: $light-gray-1;
    border-radius: 4px;
    margin: 60px auto;
    padding: 8px;
    width: 37vw;
  }
}
