@import "../../../styles/base/global";

.hd-vg-info {
  color: $dark-gray-5;
  font-size: 14px;
  line-height: 1.71;
  padding: 0 16px 16px;

  p {
    margin: 0;
    + p {
      margin: 8px 0 0 0;
    }
  }

  .hd-vg-link {
    margin-left: 4px;
  }
}
.hd-vg-detail .hd-vg-dropdown-info {
  padding: 0;

  header {
    margin-bottom: 8px;
    padding: 16px 16px 0;
  }
}
.hd-vg-info-numbers {
  font-size: 24px;
  font-weight: $semibold;
  line-height: 24px;
  margin: 0;
  padding: 0;
}

.hd-vg-block-totals {
  background: $light-gray-7;
  font-size: 14px;
  flex-direction: row;
  display: flex;
  padding: 16px;
  text-align: center;
}

.hd-vg-col-totals {
  + .hd-vg-col-totals {
    border-left: 1px solid $light-gray-1;
    padding-left: 8px;
    margin-left: 8px;
  }
}

.hd-vg-text-totals {
  line-height: 18px;
  margin: 8px 0 0;
  width: 110px;
  white-space: pre-wrap;
}

.hd-vg-icon-location,
.hd-vg-icon-buildings,
.hd-vg-icon-tag {
  svg {
    fill: $dark-gray-5;
  }
}
