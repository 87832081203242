//
// QuickStats
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-quick-stats {
  @include rounded-white;
  margin-bottom: 32px;

  .vg-stats-content {
    align-items: center;
    border-bottom: 1px solid $light-gray-2;
    color: $dark-gray-2;
    display: flex;
    font-size: 1.4em;
    font-weight: $regular;
    justify-content: center;
    min-height: 96px;
    text-align: center;

    &:last-of-type {
      border-bottom: 0;
    }

    p {
      margin: 0;
      width: 100%;
    }

    strong {
      color: $orange;
      display: block;
      font-size: 2.2em;
      font-weight: $bold;
    }
  }
}

@include desktop {
  .vg-quick-stats {
    .vg-stats-content {
      border: 0;
      border-left: 1px solid $light-gray-2;
    }
  }
}
