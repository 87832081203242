@import "../../../styles/base/global";

.vg {
  &-phases-wrapper {
    .vg-action-create {
      .vg-row {
        .vg-notification-text {
          margin: 0;

          &-warning {
            color: $orange;
            font-size: 1.6em;
          }
          &-error {
            color: $cinnabar;
            font-size: 1.6em;
          }
        }
      }
    }
  }
  &-notification {
    &-bar {
      align-items: center;
      border-radius: 4px;
      display: flex;
      padding: 12px 16px;
      width: 100%;

      &-warning {
        background-color: rgba($orange, 0.05);
      }
      &-error {
        background-color: rgba($cinnabar, 0.05);
      }
    }
    &-icon {
      height: 16px;
      margin-right: 8px;
      width: 16px;

      &-warning {
        path,
        svg {
          fill: $orange;
        }
      }
      &-error {
        path,
        svg {
          fill: $cinnabar;
        }
      }
    }
  }
}

.vg-notification-intl {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  color: #667085;
  font-size: 0.875rem;
  line-height: 1.25rem;
  max-height: 30rem;
  overflow-y: auto;
  padding: 1.5rem;
  position: absolute;
  right: 264px;
  text-align: justify;
  top: 32px;
  width: 25rem;

  h3 {
    color: #101828;
    margin-bottom: 1.5rem;
    margin-top: 0;
  }

  li {
    border-bottom: 1px solid #eaecf0;
    margin-bottom: 1rem !important;
    margin-right: 0 !important;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }
  }
}

.vg-vaga-publicada {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;

  > div {
    align-items: center;
    display: flex;
  }

  p {
    font-size: 0.875rem;
    font-weight: 600;
    margin: 0;
    margin-right: 8px;
  }
}

.vg-no-notifications {
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid#EAECF0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 1.5rem;

  span {
    align-items: center;
    background-color: #f2f4f7;
    border: 6px solid #f9fafb;
    border-radius: 100%;
    display: flex;
    margin-bottom: 0.75rem;
    padding: 0.625rem;
  }

  p {
    margin: 0;
  }
}

.vg-ver-todas {
  border-top: 1px solid #eaecf0;
  margin-top: 1rem;
  padding: 1rem 1.5rem 0 1.5rem;
  text-align: right;

  a {
    color: #667085;
    font-weight: 600;
    text-decoration: none;
  }
}

.vg-new-notification {
  background-color: #12b76a;
  border-radius: 100%;
  height: 0.625rem;
  width: 0.625rem;
}
