//
// Lista de ações por fase
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-phase-actions {
  border: 1px solid $light-gray-1;
  border-radius: 4px;
  list-style: none;
  margin: 16px 0 0;
  padding: 0;

  &.vg-phase-empty {
    text-align: center;

    a {
      color: $orange;
    }
  }

  li {
    align-items: center;
    color: $dark-gray-2;
    cursor: auto;
    display: flex;
    justify-content: space-between;
    padding: 13px 15px;

    .vg-icon {
      fill: $light-gray-1;
    }

    + li {
      border-top: 1px solid $light-gray-1;
    }

    &.vg-phase-placeholder .vg-icon {
      width: 100%;
      height: auto;
    }

    p {
      width: 100%;
      margin: 0 8px;
      font-size: 1.4em;
      line-height: 1.5;

      small {
        display: block;
        font-size: 85%;
      }
    }

    button {
      @include reset-button;

      .vg-icon {
        fill: $dark-gray-2;
        transition: fill 0.2s $ease-default;
      }

      + button {
        margin-left: 8px;
      }

      &:hover {
        .vg-icon {
          fill: $orange;
        }
      }
    }
  }
  &--ok,
  &--notok {
    color: $green;
    margin-right: 4px;
    font-style: normal;
  }
  &--notok {
    color: $cinnabar;
  }
}

.vg-actions-list {
  min-height: 200px;
  margin: 24px 0;

  ul {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    margin: 0 auto;
    min-height: 200px;
    padding: 0;
    width: 100%;

    li {
      border: solid 1px $light-gray-1;
      border-radius: 4px;
      margin-top: 4%;
      padding: 28px;
      position: relative;
      width: 100%;

      .vg-icon {
        background-color: $light-gray-4;
        border-radius: 50%;
        display: block;
        fill: $orange;
        height: 80px;
        margin: 0 auto 16px;
        position: relative;
        width: 80px;

        svg {
          @include vertical-horizontal-centered;
          height: 32px;
          width: 32px;
        }
      }

      .vg-btn-outline {
        display: block;
        font-size: 1.2em;
        margin: 0 auto;
        text-align: center;
        width: 100%;
      }

      b {
        background-color: $light-gray-4;
        border-radius: 4px;
        border: solid 1px $light-gray-1;
        border-top: none;
        color: $dark-gray-2;
        font-size: 1.2em;
        font-weight: normal;
        left: -1px;
        padding: 8px 40px;
        position: absolute;
        text-align: center;
        transform: translateY(20px);
        width: 100.7%;
      }
    }
  }
}

@include desktop {
  .vg-actions-list {
    ul {
      flex-direction: row;
      width: 80%;

      li {
        width: 48%;
      }
    }
  }
}
