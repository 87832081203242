// PageFade
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.fade-translate-enter {
  height: 100%;
  opacity: 0;
  position: fixed;
  transform: translate(0, -3vh);
  width: 100%;

  &.fade-translate-enter-active {
    opacity: 1;
    transform: translate(0, 0);
    transition: opacity 0.5s $ease-default, transform 0.3s $ease-in-out;
  }
}

.fade-translate-exit {
  height: 100%;
  opacity: 1;
  position: fixed;
  transform: translate(0, 0);
  width: 100%;

  &.fade-translate-exit-active {
    opacity: 0;
    transition: opacity 0.3s $ease-default;
  }
}
