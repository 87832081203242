//
// Graphs
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-graphs {
  .vg-row {
    margin: 0 auto;
  }

  [class^="vg-col"] {
    margin-bottom: 32px;
  }

  .vg-error-handler-wrapper {
    padding: 0;
  }

  .vg-icon-graph {
    display: inline-block;
    height: 40%;
    width: 40%;
  }

  .vg-graph-empty {
    align-items: center;
    display: flex;
    justify-content: space-between;

    p {
      margin-top: 0;
    }
  }

  &-content {
    @include rounded-white;
    height: 100%;
    overflow: hidden;
    padding: 30px 32px;
  }

  &-candidate {
    padding: 30px 0 0;

    .VictoryContainer {
      cursor: grab;
      position: relative;
      z-index: 1;

      &::after {
        background-color: $light-gray-6;
        bottom: -10%;
        content: "";
        height: 32%;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
      }

      svg {
        min-height: 148px;
        position: relative;
        z-index: 2;
      }

      &:active {
        cursor: grabbing;
      }
    }
  }

  &-title {
    color: $dark-gray-1;
    font-size: 1.6em;
    font-weight: $regular;
    margin: 0;
    margin-bottom: 32px;
    padding: 0;
    text-align: center;
  }

  .vg-graph {
    display: inline-block;
    margin-right: 8%;
    min-height: 120px;
    position: relative;
    vertical-align: middle;
    width: 40%;

    p {
      @include vertical-horizontal-centered;
      color: $dark-gray-1;
      font-size: calc(0.3vw + 0.3vh + 7vmin);
      font-weight: $bold;
      margin: -4px 0 0;
      padding: 0;
      text-align: center;
      width: 100%;

      small {
        color: $dark-gray-1;
        display: block;
        font-size: 0.6em;
        font-weight: $regular;
      }
    }

    circle {
      fill: $white;
    }
  }

  .vg-bce-disable {
    text-align: center;

    p {
      font-size: 1.3em;
      line-height: 1.7;
      text-align: left;

      b {
        color: $orange;
      }
    }
  }

  .vg-graph-label {
    color: $dark-gray-1;
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 48%);

    p {
      font-size: 1.3em;
      line-height: 1.7;

      a {
        color: $orange;
        display: block;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        font-size: 1.3em;
        margin-bottom: 18px;
        padding-left: 14px;
        position: relative;

        &::before {
          @include vertical-centered;
          background-color: $sla-green;
          border-radius: 50%;
          content: "";
          height: 8px;
          left: 0;
          width: 8px;
        }

        &:nth-of-type(2)::before {
          background-color: $sla-yellow;
        }

        &:nth-of-type(3)::before {
          background-color: $sla-red;
        }
      }
    }
  }

  &-placeholder {
    @include rounded-white;
    height: 100%;
    overflow: hidden;
    padding: 34px 0 0;

    .vg-icon {
      animation: infiniteFade 0.7s $ease-default both alternate infinite;
      display: block;
      height: 100%;
      margin: 0 auto;
      width: 100%;
    }

    &.vg-graphs-line-placeholder {
      .vg-icon {
        height: 100%;
        width: 100%;
      }
    }
  }
}

@include desktop {
  .vg-graphs {
    display: flex;
    margin: 0 0 30px;

    [class^="vg-col"] {
      margin-bottom: 0px;
    }

    .vg-graph {
      p {
        font-size: calc(0.3vw + 0.3vh + 2.4vmin);
      }
    }
  }

  .vg-graphs.vg-boxes-with-bte {
    margin: 0 auto 30px;
    max-width: 800px;
    justify-content: center;
  }
}

.bce-unlimited {
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    margin-bottom: 8px;
  }

  p,
  span {
    color: $dark-gray-1;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    margin: 0;
  }

  .vg-graph-label {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    width: 100%;
  }

  &-box {
    padding: 8px;
    border: 1px solid $light-orange-5;
    box-sizing: border-box;
    border-radius: 2px;
    margin-bottom: 8px;
  }

  a {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
  }

  .icon-target {
    width: 49px;
    height: 49px;
    float: left;
    margin: 24px 0;
  }
}
