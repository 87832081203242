@import "../../../styles/base/global";

.vg-status-list {
  align-items: center;
  background: $white;
  border-radius: 25px;
  display: inline-flex;
  font-size: 1.4em;
  font-weight: $semibold;
  overflow: hidden;
  padding: 4px 16px 4px 16px;
  position: relative;
  vertical-align: middle;

  .vg-icon {
    @include size(16px, 16px);
    margin-right: 8px;
    fill: $green;
  }

  &.prazo_expirado {
    color: $red-status-2;
    border: 1px solid $red-status-2;
    font-weight: 600;

    .vg-icon {
      fill: $red-status-2;
    }
  }

  &.convite_pendente,
  &.entrevista_incompleta,
  &.entrevista_processamento {
    color: $light-orange;
    border: 1px solid $light-orange;
    font-weight: 600;

    .vg-icon {
      fill: $light-orange;
    }
  }
}
