.vg-icon {
  display: inline-block;
  height: 18px;
  vertical-align: middle;
  width: 18px;

  svg {
    display: block;
    height: 100%;
    width: 100%;
  }
}

.vg-icon-down_arrow {
  height: 32px;
  min-width: 32px;

  svg {
    display: block;
    height: 100%;
    padding: 10px;
    width: 100%;
  }
}
