//
// RichTextEditor
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.vg-editor-wrapper {
  border: 1px solid $light-gray-1;
  border-radius: 5px;
  margin-bottom: 19px;

  // definido o tamanho padrão de fonte do editor
  font-size: 13px;
  line-height: 1.5;
  font-family: Arial, Helvetica, sans-serif;

  .vg-editor-toolbar {
    background-color: $light-gray-3;
    border: 0;
    border-radius: 5px 5px 0 0;
    margin-bottom: 0;
    padding: 7px 5px 3px;

    .rdw-option-wrapper {
      border: 1px solid $light-gray-2;
      border-radius: 4px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    .rdw-dropdown-wrapper {
      border: 1px solid $light-gray-2;
      border-radius: 4px;

      .rdw-dropdown-optionwrapper {
        border-radius: 4px;

        li {
          padding: 5px 10px;
          text-align: left;
        }
      }
    }
  }

  .vg-editor {
    border: 0;
    max-height: 250px;
    min-height: 200px;
    overflow: auto;
    padding: 0 10px;
  }
}

.rdw-dropdown-wrapper {
  position: relative;

  .rdw-dropdown-custom {
    position: absolute;
    right: 0;
    width: 150px;

    .rdw-dropdownoption-default {
      padding: 0 !important;

      &:hover {
        background: $light-gray-3;
      }

      a {
        color: $black;
        font-size: 13px;
        padding: 8px 10px;
        text-decoration: none;
        text-transform: none;
      }
    }
  }
}

.rdw-dropdown-select-variable {
  position: relative;
  height: 30px;
  display: flex;
  align-content: center;

  span {
    padding: 6px;
  }
}

[class^="vg-highlight-editor"] {
  background: $light-orange-3;
  color: $orange;
  line-height: 1.5;
  padding: 2px 5px 3px;
  line-height: 24px;
  border-radius: 50px;
  font-family: $base-font-family;
  font-weight: $bold;

  span {
    background: $light-orange-3;
    color: $orange;
  }

  &.vg-highlight-editor-error {
    background: $red-status;
    color: $white;

    span {
      background: $red-status;
      color: $white;
    }
  }
}
