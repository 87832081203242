//
// State
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.vg-selective-process-state {
  @include rounded-gray;
  background: $white;
  padding: 16px;
  text-align: left;

  h2 {
    color: $dark-gray-5;
    display: block;
    font-size: 1.5em;
    margin: 0;
    margin-bottom: 15px;
  }

  p {
    color: $dark-gray-1;
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
  }

  .vg-icon {
    fill: $dark-gray-5;
    width: 18px;
    height: 24px;
  }

  .vg-icon-border {
    border-left: 1px solid $light-gray-6;
    height: 40px;
    margin: 16px;
    width: 1px;
  }

  .vg-link {
    font-weight: 600;
    text-decoration: none;
  }
}
