// =====================================================================================================================
// Mixins
// =====================================================================================================================

// Media Queries
// ---------------------------------------------------------------------------------------------------------------------
@mixin mobile {
  @media (min-width: #{$mobile-width}) {
    @content;
  }
}

@mixin large-mobile {
  @media (min-width: #{$large-mobile-width}) {
    @content;
  }
}

@mixin mobile-landscape {
  @media (max-height: #{$mobile-width}) and (orientation: landscape) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin large-desktop {
  @media (min-width: #{$large-desktop-width}) {
    @content;
  }
}

@mixin xlarge-desktop {
  @media (min-width: #{$xlarge-desktop-width}) {
    @content;
  }
}

@mixin min-height-desktop {
  @media (max-height: 700px) {
    @content;
  }
}

// Hide text
// -----------------------------------------------------------------------------
@mixin hide-text($height: 1em) {
  height: $height;
  line-height: 1.5;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    height: 100%;
    width: 0;
  }
}

// Clearfix
// -----------------------------------------------------------------------------
@mixin clearfix {
  &::before,
  &::after {
    content: "";
    display: table;
  }

  &::after {
    clear: both;
  }
}

// Text overflow
// Requires inline-block or block for proper styling
// -----------------------------------------------------------------------------
@mixin text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Vertical, Horizontal and Center Alignment
// -----------------------------------------------------------------------------
@mixin horizontal-centered {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin vertical-centered {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin vertical-horizontal-centered {
  @include vertical-centered();
  left: 50%;
  transform: translate(-50%, -50%);
}

// Theme mixins
// ---------------------------------------------------------------------------------------------------------------------
@mixin theme(
  $prop: full,
  $color: $theme,
  $invert: false,
  $variation: 0,
  $opacity: 1
) {
  // Analisa as propriedades e retorna o estilo
  @if $prop == full {
    @if $invert == false {
      @if $variation == 0 {
        background-color: rgba($color, $opacity);
      } @else if $variation > 0 {
        background-color: rgba(mix($black, $color, $variation), $opacity);
      } @else {
        background-color: rgba(
          mix($white, $color, (-1 * $variation)),
          $opacity
        );
      }
      color: set-black-white($color);
    } @else {
      @if $variation == 0 {
        background-color: set-black-white($color);
      } @else if $variation > 0 {
        background-color: rgba(
          mix($black, set-black-white($color), $variation),
          $opacity
        );
      } @else {
        background-color: rgba(
          mix($white, set-black-white($color), (-1 * $variation)),
          $opacity
        );
      }
      color: rgba($color, $opacity);
    }
  } @else {
    @if $invert == false {
      @if $variation == 0 {
        #{$prop}: rgba($color, $opacity);
      } @else if $variation > 0 {
        #{$prop}: rgba(mix($black, $color, $variation), $opacity);
      } @else {
        #{$prop}: rgba(mix($white, $color, (-1 * $variation)), $opacity);
      }
    } @else {
      @if $variation == 0 {
        #{$prop}: set-black-white($color);
      } @else if $variation > 0 {
        #{$prop}: rgba(
          mix($black, set-black-white($color), $variation),
          $opacity
        );
      } @else {
        #{$prop}: rgba(
          mix($white, set-black-white($color), (-1 * $variation)),
          $opacity
        );
      }
    }
  }
}

@mixin rounded-white {
  @include default-drop-shadow;
  background-color: $white;
  border-radius: 5px;
  transform: box-shadow 0.2s $ease-default;
}

@mixin rounded-gray {
  @include default-drop-shadow;
  @include rounded-white;
  background-color: $light-gray-3;
  border: 1px solid $dark-gray-4;
}

@mixin rounded-white-hover {
  box-shadow: 0 2px 10px rgba($black, 0.2);
}

@mixin default-drop-shadow {
  box-shadow: 0 2px 2px rgba($black, 0.2);
}

@mixin remove-rounded-white {
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
}

@mixin orange-degrade {
  background: #fb6501;
}

@mixin green-degrade {
  background: #02a97f;
}

@mixin linear-degrade($color1, $color2) {
  background: linear-gradient(to right, $color1 0%, $color2 100%);
}

@mixin reset-button {
  appearance: none;
  background: transparent;
  border: 0;
  outline: none;
  padding: 0;
  cursor: pointer;
}

@mixin drop-down-card-corner-position {
  margin-left: 10px;
  position: absolute;
  right: 18px;
  top: 21px;
  @media (min-width: #{$desktop-width}) {
    float: none;
    position: relative;
    right: auto;
    top: 0;
    .vg-dropdown-button {
      padding: 10px;
    }
  }
}

@mixin size($w: auto, $h: auto) {
  width: $w;
  height: $h;
}
