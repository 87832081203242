//
// Custom Radio Button
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.vg-custom-radio {
  display: inline-block;

  input[type="radio"] {
    display: none;
  }

  input[type="radio"]:checked + .vg-label-text {
    font-weight: $semibold;

    &::after {
      background-color: $orange;
      transition: 0.5s background-color $ease-default;
    }
  }

  .vg-label {
    margin-right: 20px;

    &-text {
      font-size: 1.4em;
      padding-left: 20px;
      position: relative;

      &::before,
      &::after {
        @include vertical-centered;
        border-radius: 50%;
        content: "";
      }

      &::before {
        border: 1px solid $light-gray-1;
        box-shadow: inset 0 1px 2px 0 rgba($black, 0.2);
        cursor: pointer;
        height: 16px;
        left: 0;
        width: 16px;
      }

      &::after {
        background-color: transparent;
        cursor: pointer;
        height: 8px;
        left: 4px;
        pointer-events: none;
        width: 8px;
      }
    }
  }
}

@keyframes radio {
  0% {
    background: transparent;
  }

  100% {
    background: $orange;
  }
}
