//
// Range Input
// --------------------------------------------------------------------------

@import "../../../styles/base/global";

input[type="range"] {
  -webkit-appearance: none;
  margin: 5px 0;
  width: 100%;
  border-radius: 25px;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    @include orange-degrade;
    @include default-drop-shadow;
    border-radius: 25px;
    cursor: pointer;
    height: 8px;
    width: 100%;
  }

  &::-webkit-slider-thumb {
    @include default-drop-shadow;
    -webkit-appearance: none;
    background: $white;
    border: 2px solid $light-gray-2;
    border-radius: 15px;
    cursor: pointer;
    height: 17px;
    margin-top: -5px;
    width: 17px;
  }

  &::-moz-range-track {
    @include default-drop-shadow;
    @include orange-degrade;
    border-radius: 25px;
    cursor: pointer;
    height: 8px;
    width: 100%;
  }

  &::-moz-range-thumb {
    @include default-drop-shadow;
    background: $white;
    border: 3px solid $light-gray-1;
    border-radius: 15px;
    cursor: pointer;
    height: 17px;
    width: 17px;
  }

  &::-ms-track {
    @include default-drop-shadow;
    background: transparent;
    border-color: transparent;
    color: transparent;
    cursor: pointer;
    height: 8px;
    width: 100%;
  }

  &::-ms-fill-lower,
  &::-ms-fill-upper {
    @include default-drop-shadow;
    @include orange-degrade;
    border-radius: 50px;
  }

  &::-ms-thumb {
    @include default-drop-shadow;
    background: $white;
    border-radius: 15px;
    border: 3px solid $orange;
    cursor: pointer;
    height: 10px;
    height: 17px;
    width: 17px;
  }
}
