.vg-video-interview-subtitle {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #474747;
	font-size: 1.5em;
	font-weight: 400;
	line-height: 1.2;
	margin-bottom: 0;
}
