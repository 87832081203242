//
// Header
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

$xsalad-height: 4px !default;

.vg-header {
  @include orange-degrade;
  box-shadow: none;
  height: $header-height;
  padding-bottom: 20px;
  padding-top: 20px;
  position: fixed;
  top: 0;
  transform: box-shadow 3s $ease-default;
  width: 100%;
  z-index: 4;

  &.vg-shadow {
    box-shadow: 0 1px 3px rgba($black, 0.15);
  }

  &-wrapper {
    background: linear-gradient(
      to right,
      rgba($orange, 0.95) 0%,
      rgba($red-gradient, 0.95) 100%
    );
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transform: translate3d(0, 10px, 0);
    transition: transform 0.2s $ease-default, opacity 0.2s $ease-default,
      visibility 0.5s $ease-default;
    visibility: hidden;
    width: 100%;
    will-change: transform;
    z-index: 1;
  }

  &-xsalad {
    @include vertical-centered;
    appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
    height: 32px;
    outline: none;
    padding: 0;
    position: absolute;
    right: 15px;
    width: 32px;
    will-change: transform;
    z-index: 2;

    &::before,
    &::after,
    span {
      @include vertical-centered;
      background-color: $white;
      border-radius: 4px;
      content: "";
      height: $xsalad-height;
      left: 0;
      position: absolute;
      transition: background 0.2s $ease-default, transform 0.2s $ease-default;
      width: 32px;
    }

    &::before {
      transform: translate3d(0, calc(50% - 13px), 0);
    }

    &::after {
      transform: translate3d(0, calc(50% + 5px), 0);
    }
  }

  &.vg-active {
    .vg-header-wrapper {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      visibility: visible;
      z-index: 1;
    }

    // .vg-btn-feedback {
    //   opacity: 1;
    //   transform: translate3d(-50%, 0, 0);
    //   visibility: visible;
    //   pointer-events: auto;
    // }

    .vg-header-xsalad {
      span {
        background-color: transparent;
      }

      &::before {
        transform: translate3d(0, -3px, 0) rotate(45deg);
      }

      &::after {
        transform: translate3d(0, -3px, 0) rotate(-45deg);
      }
    }
  }

  .vg-help,
  .vg-balance {
    appearance: none;
    background-color: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    display: none;
    @include desktop {
      display: block;
    }
  }

  .vg-tooltip-balance {
    opacity: 0;
    @include rounded-white;
    border-radius: 5px;
    padding: 10px 15px;
    position: absolute;
    right: 50px;
    top: 100%;
    transform: translate3d(0, 40px, 0);
    transition: opacity 0.3s $ease-default, transform 0.3s $ease-default,
      visibility 0.4s linear;
    width: 235px;
    z-index: 2;
    visibility: hidden;

    :before {
      border: 10px solid transparent;
      border-bottom-color: #fff;
      content: " ";
      height: 0;
      pointer-events: none;
      position: absolute;
      right: 99px;
      top: -20px;
      width: 0;
    }

    &.active {
      opacity: 1;
      transform: translate3d(0, 15px, 0);
      transition: opacity 0.8s $ease-default, transform 0.3s $ease-default,
        visibility 0.4s linear;
      visibility: visible;
    }

    > span {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #667085;
      margin-top: 20px;
      display: inline-block;

      &.vg-no-credit {
        margin-top: 10px;
        color: #f04438;

        .vg-icon {
          margin-right: 8px;
        }
      }
    }

    > p {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #f7902a;
      margin: 0;
    }

    .vg-tooltip-btns {
      margin-top: 8px;

      .vg-btn-outline,
      .vg-btn-outline-light-gray {
        width: 100%;
        margin-top: 12px;
        .vg-icon {
          margin-right: 8px;
        }
      }

      .vg-btn-tooltip-comprar {
        background-color: #475467 !important;
        border: 1px solid #475467 !important;
        color: #fff !important;
      }

      .vg-btn-tooltip-servicos .vg-icon {
        fill: #797979 !important;
      }
      .vg-btn-tooltip-servicos:hover .vg-icon {
        fill: #f7902a !important;
      }
    }
  }
}

.vg-main-candidate .vg-header {
  @include green-degrade;
}

@include desktop {
  .vg-header {
    &.vg-active {
      .vg-header-wrapper {
        display: flex;
        transition: none;
      }
    }

    .vg-container {
      display: flex;
    }

    &-right-section {
      display: flex;
      position: relative;

      .vg-help {
        margin: 0 16px;
      }
    }

    &-wrapper {
      background: none;
      display: flex;
      justify-content: space-between;
      opacity: 1;
      position: relative;
      transform: none;
      transition: none;
      visibility: visible;
      width: calc(100% - 203px);

      .vg-help {
        display: block;
      }
    }

    &-xsalad {
      display: none;
    }
  }
}

.box-icon-chat {
  @include desktop {
    position: relative;
  }
}

.container-beamer {
  position: relative;
  margin-left: 18px;
  #beamerButton {
    display: none;
    @include desktop {
      display: block;
      position: absolute;
      right: 210px;
      top: 8px;
      z-index: 9;

      .beamer_icon.active {
        left: 13px;
        margin-top: 10px;
      }

      &.isVagas25 {
        right: 202px !important;
      }
    }
  }
}

.list-icons-header {
  margin-left: 20px;
}
