//
// PostingDateContent
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-posting-date-content {
  width: 266px;

  .vg-label {
    display: block;
    position: relative;

    + .vg-label {
      margin-top: 16px;
    }

    .input-title {
      display: block;
      font-size: 1.6em;
      font-weight: 600;
      padding: 0 0 8px;
    }

    .vg-date-text {
      color: $dark-gray-5;
      font-size: 1.6em;
      line-height: 24px;
      margin: 0;
      padding: 0px;
    }

    .vg-input-text {
      border: 1px solid $light-gray-1;
      border-radius: 4px;
      color: $dark-gray-5;
      font-size: 1.6em;
      padding: 8px;
      width: 100%;

      &:disabled {
        background-color: $dark-gray-4;
        border: 1px solid $light-gray-1;
        cursor: not-allowed;
      }
    }
  }

  .validation-error-message {
    background-color: rgba(218, 51, 51, 0.15);
    border-radius: 4px;
    color: $cinnabar;
    font-size: 1.6em;
    line-height: 24px;
    margin: 8px 0;
    padding: 8px;
    width: 100%;
  }

  .vg-icon-calendar {
    bottom: 9px;
    height: 16px;
    margin-top: -8px;
    position: absolute;
    right: 0;
    width: 16px;

    svg {
      stroke: $orange;
    }
  }
}
