//
// ModalAboutCredits
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.modal-entenda-creditos {
  span {
    text-align: center;
    color: $orange;
  }

  h3 {
    color: $orange;
    font-size: 16px;
    text-align: center;
    font-weight: normal;
  }

  .vg-panel:last-child {
    margin-bottom: 25px;
  }

  .vg-modal-body {
    padding: 25px;
  }
}
