//
// TokenField
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.vg-input-token {
  border: {
    color: $light-gray-1;
    radius: 4px;
    style: solid;
    width: 1px;
  }
  max-height: 96px;
  overflow-y: auto;
  padding: 5px 4px;
  
  .ReactTags__selected {
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
  }

  .ReactTags__tag {
    background-color: $light-gray-3;
    border-radius: 4px;
    color: $dark-gray-1;
    cursor: pointer !important;
    font-size: 1.3em;
    margin: 2px;
    padding: 4px 32px 7px 8px;
    position: relative;

    .ReactTags__remove {
      background-color: $light-gray-1;
      border: none; 
      border-radius: 50%;
      color: $light-gray-3;
      cursor: pointer;
      display: block;
      font-size: 14px;
      font-weight: 600;
      height: 18px;
      margin-top: -9px;
      position: absolute;
      right: 6px;
      text-align: center;
      top: 50%;
    }
  }

  .ReactTags__tagInput {
    display: inline-block;
    min-width: 100px;
    width: 100%;
    flex: 1;

    .ReactTags__tagInputField {
      border: 0;
      font-size: 1.5em;
      line-height: 1em;
      padding: 6px 4px;
      width: 100%;
      color: $dark-gray-2;

      &:focus {
        outline: none;
      }
    }
  }
}
