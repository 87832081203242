//
// Tooltip
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.tooltip {
  cursor: pointer;
  position: relative;

  &:hover {
    text-decoration: none;
  }

  .tooltip-message {
    background-color: $light-gray-1;
    border-radius: 4px;
    position: absolute;
    border: 1px solid $light-gray-1;
    box-shadow: 0 1px 2px 0 rgba($black, 0.3);
    color: $dark-gray-1;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    padding: 0px 8px;
    white-space: nowrap;
    z-index: 10;

    &.on-top {
      left: 50%;
      top: (-$tooltip-offset-default);
      transform: translateX(-50%);

      &::after {
        border: {
          left: 8px solid transparent;
          right: 8px solid transparent;
          top: 8px solid $light-gray-1;
        }
        bottom: -12px;
        content: "";
        position: absolute;
        right: 50%;
        transform: translate(50%, -50%);
      }
    }

    &.on-right {
      left: 100%;
      top: 50%;
      margin-left: 11px;
      transform: translateY(-50%);

      &::after {
        border: {
          top: 8px solid transparent;
          right: 8px solid $light-gray-1;
          bottom: 8px solid transparent;
        }
        left: -8px;
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &.on-bottom {
      bottom: (-$tooltip-offset-default);
      left: 50%;
      transform: translateX(-50%);

      &::after {
        border: {
          left: 8px solid transparent;
          right: 8px solid transparent;
          bottom: 8px solid $light-gray-1;
        }
        content: "";
        position: absolute;
        right: 50%;
        top: -8px;
        transform: translate(50%, 0%);
      }
    }

    &.on-left {
      margin-right: 11px;
      right: 100%;
      top: 50%;
      transform: translateY(-50%);

      &::after {
        border: {
          bottom: 8px solid transparent;
          top: 8px solid transparent;
          left: 8px solid $light-gray-1;
        }
        content: "";
        position: absolute;
        right: -8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
