@import "../../../styles/base/global";

.vg-acceptance-term-page {
  .vg-box-title {
    background-color: $body-background;
    box-shadow: none;
    padding-bottom: 16px;

    h1 {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      margin-bottom: 8px;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      margin-top: 0px;
    }
  }

  .vg-notification-box {
    background-color: $white;
    border-radius: 2px;
    box-shadow: 0px 1px 2px rgba(55, 55, 55, 0.2);
    margin: auto;
    padding: 24px;

    h1 {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      margin: 0px auto 8px auto;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin: 0px;
    }
  }

  .vg-option-box {
    background-color: $white;
    border-radius: 2px;
    box-shadow: 0px 1px 2px rgba(55, 55, 55, 0.2);
    margin: 16px auto;
    padding: 24px;

    h1 {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      margin: 0px;
      padding-bottom: 16px;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    .bold-title {
      margin-top: 0px;
    }
  }

  .vg-upload-box {
    .vg-upload-flex-options {
      display: flex;
    }

    .bold-title {
      font-weight: 600;
    }
  }

  .vg-radio-button {
    margin-bottom: 16px;
    margin-right: 24px;
  }

  .vg-input-box-flex {
    display: flex;
  }

  .vg-input-button {
    background-color: $green;
    border: none;
    border-radius: 2px;
    color: $white;
    font-size: 16px;
    height: 40px;
    margin: 8px 0px auto 16px;
    width: 96px;
  }

  .enabled {
    cursor: pointer;
  }

  .disabled {
    opacity: 0.5;
  }

  .vg-input-button-cancel {
    align-items: center;
    background-color: transparent;
    border: 1px solid $light-gray-6;
    border-radius: 2px;
    color: $dark-gray-1;
    display: flex;
    font-size: 16px;
    height: 40px;
    justify-content: center;
    margin: 8px 0px auto auto;
    text-decoration: none;
    width: 116px;

    &.modal-cancel {
      margin: 16px 0 16px 0;
    }
  }

  .vg-input-button-continue {
    align-items: center;
    background-color: $red-status;
    border: 1px solid $red-status;
    border-radius: 2px;
    color: $white;
    font-size: 16px;
    height: 40px;
    justify-content: center;
    margin: 16px 0px 16px 10px;
    text-decoration: none;
    min-width: 116px;
  }

  .vg-input-text {
    background-color: $white;
    border: 1px solid $light-gray-9;
    border-radius: 2px;
    color: $white;
    display: flex;
    font-size: 1.4em;
    margin-top: 16px;
    margin-bottom: 0;
    padding: 12px 16px;
    width: 100%;

    input {
      background-color: transparent;
      border: none;
      width: 100%;
    }

    button {
      background-color: transparent;
      border: none;
      padding: auto 10px auto auto;
      cursor: pointer;
    }

    &.toastError {
      border-color: $cinnabar;
    }
  }

  .vg-span-text {
    display: flex;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;

    span {
      margin-right: 8px;
    }

    &.info {
      color: $dark-gray-1;
    }

    &.toastError {
      color: $toast-red;
    }

    &.checkCircleFilled {
      color: $lime-green;
    }
  }

  .accordion-title:last-child {
    border-bottom: 1px solid $light-gray-1;
  }

  .accordion-title {
    align-items: center;
    background-color: $white;
    border: 1px solid $light-gray-1;
    border-bottom: 0px;
    color: $dark-gray-2;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: 300;
    min-height: 58px;
    padding: 12px 12px 12px 16px;
    text-align: left;
    transition: background-color 0.6s ease;
    width: 100%;

    &[aria-expanded="true"] {
      border-bottom: 1px solid $light-gray-1;

      .accordion-icon {
        fill: $orange;
        pointer-events: none;
        transform: rotateZ(-180deg);
      }
    }
  }

  .accordion-icon {
    fill: $dark-gray-1;
    margin: auto 0 auto auto;
    pointer-events: none;
    transition: transform 0.6s ease;
  }

  .modal-trash-icon {
    background-color: $red-status;
    border-radius: 50%;
    color: $white;
    height: 40px;
    margin: auto;
    width: 40px;

    .trash-icon {
      margin-top: 10px;
    }
  }

  .accordion-information {
    color: $dark-gray-1;
    font-size: 16px;
    font-weight: 700;
    margin-right: 16px;
    pointer-events: none;
  }

  .term-name {
    margin: 0;
    pointer-events: none;
    word-break: break-all;
  }

  .accordion-content {
    background-color: rgba(237, 237, 237, 0.5);
    border-right: 1px solid $light-gray-1;
    border-left: 1px solid $light-gray-1;
    border-radius: 2px;
    color: $dark-gray-5;
    display: block;
    max-height: 500px;
    overflow: hidden;
    padding: 0px;
    transition: max-height 0.2s ease-out;

    .vg-upload-box {
      padding: 24px;
    }
  }

  .accordion-content:last-child {
    border-bottom: 1px solid $light-gray-1;
  }

  .vg-modal-termo-aceite {
    background: $white;
    border: 1px solid $light-gray-4;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    width: 500px;
    text-align: center;

    .buttons-configuration-termo-aceite {
      display: flex;
      margin: auto;
    }
  }

  .delete-modal {
    height: 400px;
    padding-top: 40px;
    width: 425px;

    .modal-trash-icon {
      margin: 0 auto;
    }

    .delete-termo {
      margin: 0 auto;
      width: 80%;

      .delete-title {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        margin: 24px 0 8px 0;
      }

      .delete-information {
        margin: 0;
      }
    }

    .delete-cancel,
    .delete-continue {
      height: 40px;
      width: 180px;
    }
  }
}
