//
// CSS3 Keyframe Animations
// -----------------------------------------------------------------------------

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0%,
  10% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes infiniteFade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.4;
  }
}

.animation-loader {
  .vg-icon {
    animation: infiniteFade 0.7s $ease-default both alternate infinite;
    height: 100%;
    margin: 0 auto;
    width: 100%;

    path {
      fill: $light-gray-1;
    }
  }

  + .animation-loader {
    margin-top: 32px;
  }
}

.animation-spinner svg {
  animation-name: spin;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
