//
// Panel
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.vg-panel {
  padding: 20px;
  background: $light-gray-6;
  color: $dark-gray-1;
  min-height: 120px;

  .contentIcon {
    background: $white;
    border-radius: 50%;
    margin-right: 20px;
    padding: 20px;
    float: left;

    .vg-icon {
      width: 40px;
      height: 40px;
      path,
      circle {
        fill: $orange;
      }
    }
  }

  h2 {
    margin: 0px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.6;
  }

  p {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.6;
    margin: 0;
  }
}
