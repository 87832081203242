//
// Alert
// --------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-alert {
  background-color: rgba($light-orange, 0.2);
  border: 1px solid $light-orange;
  border-radius: 4px;
  color: darken($light-orange, 20);
  font-size: 1.4em;
  height: 35px;
  padding: 10px;

  &[class^="vg-alert-animate-"] {
    opacity: 0;
    transform: translate3d(0, 5px, 0);
    transition: opacity 0.2s $ease-default, transform 0.2s $ease-default;

    &.vg-alert-animate-entered {
      opacity: 1;
      position: relative;
      transform: translate3d(0, 0, 0);
      z-index: 2;
    }
  }
}
