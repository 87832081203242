//
// Link
// -----------------------------------------------------------------------------
@import "../../../../styles/base/global";

.grid-grade {
  border: 1px solid $light-gray-8;
  border-radius: 3px;
  box-sizing: border-box;
  margin: 8px 0;
  padding: 8px;
  position: relative;
  & .icon-trash {
    position: absolute;
    right: 4px;
  }
  & .box-select-grade {
    display: inline-flex;
    padding: 0px 3px;
    width: 145px;
  }
  & .label-grade {
    display: inline-flex;
    font-size: 1.4em;
    font-weight: 500;
    padding: 8px 6px 8px 2px;
    text-align: left;
    width: 100%;
  }
  @media (min-width: #{$tablet-width}) {
    & .label-grade {
      width: auto;
    }
  }
  & .box-input-grade {
    display: inline-flex;
    padding: 0px 3px;
    width: 64px;
  }

  & .div-reset-grade {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    padding: 8px 0px;
  }

  & .box-error {
    color: $dark-pink;
    font-size: 11px;
    text-align: center;
  }
}
