//
// Loader
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.vg-loading {
  padding: 120px 0;
}

.vg-loader-wrap {
  p {
    animation: text-blink 1.5s infinite ease-in-out;
    display: inline-block;
    font-size: 1.4em;
    margin-left: 10px;
  }
}

.vg-loader {
  animation: loader2 2s infinite ease;
  border: 4px solid $orange;
  border-radius: 10%;
  display: inline-block;
  height: 30px;
  overflow: hidden;
  position: relative;
  top: 50%;
  vertical-align: middle;
  width: 30px;

  .vg-loader-inner {
    animation: loader-inner 2s infinite ease-in;
    background-color: $orange;
    display: inline-block;
    vertical-align: top;
    width: 100%;
  }
}

@keyframes loader2 {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }

  25% {
    transform: rotate(180deg) translate3d(0, 0, 0);
  }

  50% {
    transform: rotate(180deg) translate3d(0, 0, 0);
  }

  75% {
    transform: rotate(360deg) translate3d(0, 0, 0);
  }

  100% {
    transform: rotate(360deg) translate3d(0, 0, 0);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

@keyframes text-blink {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
