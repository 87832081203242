@import "../../../../styles/base/global";

.error {
  border: 1px solid $cinnabar;
  border-radius: 2px;
  display: flex;
  width: 70%;
  padding: 16px;
  margin: auto;
  color: $cinnabar;
}

.success,
.loading,
.failed,
.concluded,
.delete {
  border: 1px solid $light-gray-2;
  border-radius: 2px;
  display: flex;
  color: $dark-gray-2;
  margin: auto;
  padding: 16px;
  width: 70%;
}

.loading {
  border: 1px solid $light-gray-2;
  border-radius: 2px;
  display: flex;
  color: $dark-gray-2;
  margin: auto;
  padding: 16px;
  width: 70%;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
}

.pdf-icon {
  background-color: $white;
  color: $light-gray-8;
  height: 32px;
  vertical-align: top;
  width: 26px;

  icon {
    height: 32px;
    width: 26px;
  }
}

.info {
  width: 90%;
  display: grid;

  p {
    text-align: left;
    margin: 0px;
  }
}

.info-name {
  margin-left: 10px !important;
}

.bar {
  display: flex;
}

.progress-bar-error,
.progress-bar-delete {
  background-color: $cinnabar;
  border-radius: 2px;
  width: 100%;
  height: 4px;
}

.progress-bar-success,
.progress-bar-concluded {
  position: relative;
  background-color: $green;
  border-radius: 2px;
  width: 100%;
  height: 4px;
}

.progress-bar-loading,
.progress-bar-failed {
  border-radius: 2px;
  height: 4px;
}

.progress-bar-loading {
  background-color: $light-orange-4;
}

.progress-bar-failed {
  background-color: $cinnabar;
}

.progress-bar-bg-loading {
  background-color: $light-gray-9;
  border-radius: 2px;
  width: 100%;
  height: 4px;
  margin: 8px;
}

.false-input {
  margin: 0px 8px;
}
