//
// Lista de Fases
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-collapse-list {
  padding: 15px 0 24px;

  .vg-placeholder {
    padding: 24px;

    + .vg-placeholder {
      margin-top: 17px;
    }

    .vg-icon {
      animation: infiniteFade 0.7s $ease-default both alternate infinite;
      height: 40px;
      width: 100%;
    }
  }

  .vg-collapse-item {
    @include rounded-white;
    display: block;
    margin: 0;
    overflow: hidden;
    transition: box-shadow 0.2s $ease-default;
    width: 100%;

    &:hover {
      @include rounded-white-hover;
    }

    &.vg-active {
      .vg-collapse-header {
        background-color: $light-gray-5;

        .vg-icon-arrow {
          transform: rotate(180deg);
          transform-origin: 50% 50%;
        }
      }
    }
  }

  .vg-collapse-header {
    display: block;
    padding: 24px;
    text-decoration: none;
    transition: background 0.2s $ease-default;

    h3 {
      color: $dark-gray-1;
      display: inline-block;
      font-size: 1.5em;
      font-weight: $semibold;
      margin: 0;
    }

    .vg-icon-arrow {
      fill: $orange;
      height: 15px;
      margin-left: 10px;
      transform-origin: 50% 50%;
      transition: transform 0.2s $ease-default;
      vertical-align: middle;
      width: 15px;
    }
  }

  .vg-animation {
    transition: max-height 0.2s $ease-default, padding 0.2s $ease-default,
      opacity 0.8s $ease-default, transform 0.2s $ease-default;
  }

  .vg-collapse-content {
    overflow: hidden;
    padding-left: 30px;
    padding-right: 30px;

    h3 {
      font-size: 1.4em;
      margin: 0;
    }

    .vg-row .vg-row {
      border-bottom: 1px solid $light-gray-2;
      padding-bottom: 20px;
      padding-top: 20px;

      &:last-of-type {
        border-bottom: 0;
      }
    }

    .vg-btn-success {
      float: right;
      margin-top: 10px;
    }
  }
}

.vg-collapse-space {
  padding: 8px 0;
}
