//
// User Navigation
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-phases-list-container {
  display: flex;
  flex-direction: row;
  overflow: hidden;

  .vg-phase {
    background-color: $white;
    color: $dark-gray-1;
    margin-right: 16px;
    padding: 8px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;

    &:hover {
      .vg-phase-title {
        color: $orange;
      }
    }

    &.vg-active {
      background-color: $white;
      border-radius: 0 0 0 4px;
      color: $dark-gray-1;

      .vg-phase-title {
        font-weight: $semibold;
      }

      &::before,
      &::after {
        background-color: $white;
      }
    }

    &::before,
    &::after {
      background-color: $white;
      border: 1px solid $light-gray-2;
      border-bottom: 0;
      border-left: 0;
      content: "";
      height: 36px;
      position: absolute;
      right: 0;
      top: 0;
      transform: translate3d(11px, 2px, 0) rotate(45deg);
      width: 30px;
      z-index: 1;
    }

    &::after {
      border: 0;
      transform: translate3d(-9px, -2px, 0);
    }

    .vg-phase-title {
      font-size: 1.3em;
      font-weight: $regular;
      line-height: 1.5;
      position: relative;
      transition: color 0.2s $ease-default;
      z-index: 2;
    }

    .vg-phase-value {
      background-color: transparent;
      font-size: 1.3em;
      font-weight: $bold;
      margin-left: 4px;
      padding: 1px 0;
      position: relative;
      transform: rotate(0.02deg) translateZ(0);
      z-index: 2;

      &:empty {
        display: none;
      }
    }

    .vg-phase-total {
      background-color: transparent;
      color: $orange;
      font-size: 1.3em;
      font-weight: $semibold;
      margin-left: 8px;
      padding: 1px 0;
      position: relative;
      transform: rotate(0.02deg) translateZ(0);
      z-index: 2;

      &:empty {
        display: none;
      }
    }
  }
}

.vg-first-phase {
  float: left;
  flex-grow: 0;
}

.vg-phases-list {
  flex: 1;
  color: $dark-gray-1;
  font: {
    size: 10px;
    weight: $semibold;
  }
  overflow: hidden;
  position: relative;

  &.vg-hide-before::before,
  &.vg-hide-after::after {
    opacity: 0;
  }

  &:hover:after,
  &:hover:before {
    display: none;
    background: linear-gradient(
      to right,
      rgba($white, 1) 0%,
      rgba($white, 0) 100%
    );
    content: "";
    height: 45px;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: opacity 0.3s $ease-default;
    width: 60px;
    z-index: 2;
  }

  &:hover:after {
    background: linear-gradient(
      to left,
      rgba($white, 1) 0%,
      rgba($white, 0) 100%
    );
    left: auto;
    right: 0;
  }

  .slick-list {
    margin-top: 9px;
  }

  .slick-track {
    will-change: transform;
  }

  &:hover {
    .slick-arrow {
      transform: translate3d(0, 0, 0) rotate(90deg);

      &.slick-next {
        transform: translate3d(0, 0, 0) rotate(-90deg);
      }
    }
  }

  .slick-arrow {
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    height: 18px;
    left: 8px;
    outline: none;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 9px;
    transform: translate3d(-30px, 0, 0);
    width: 18px;
    z-index: 3;

    &.slick-disabled {
      display: none !important;
    }

    &:hover {
      svg {
        fill: $orange;
      }
    }

    svg {
      fill: $light-gray-1;
      transition: fill 0.2s $ease-default;
    }

    &.slick-next {
      left: auto;
      right: 8px;
      transform: translate3d(30px, 0, 0) rotate(0);
    }
  }
}
