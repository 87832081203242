//
// CreateQuestion
// -----------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-create-question-page {
  .vg-form-create-group {
    @include rounded-white;
    padding: 32px;
    text-align: left;
  }

  .vg-item-form-question {
    margin-bottom: 24px;
    text-align: left;

    &.vg-question-input {
      .vg-input-text {
        height: 60px;
        max-height: 60px;
        max-width: 100%;
        min-width: 100%;
      }
    }

    .vg-input-text {
      border: {
        color: $light-gray-1;
        radius: 4px;
        style: solid;
        width: 1px;
      }
      display: block;
      font-size: 1.4em;
      padding: 12px 8px;
      width: 100%;
      color: $dark-gray-2;

      &:focus {
        outline: none;
      }
    }

    .vg-item-add-question {
      border: {
        color: $light-gray-1;
        radius: 4px;
        style: solid;
        width: 1px;
      }

      .vg-icon-plus {
        background-color: $orange;
        border-radius: 50%;
        fill: $white;
        height: 16px;
        margin: 0 8px 0 0;
        position: relative;
        width: 16px;

        svg {
          display: block;
          height: 10px;
          left: 50%;
          margin: -5px 0 0 -5px;
          position: absolute;
          top: 50%;
          width: 10px;
        }
      }

      .vg-btn-link {
        display: block;
        padding: 12px;
      }

      .vg-question-item {
        border-bottom: solid 1px $light-gray-1;
        display: block;
        font-size: 1.5em;
        padding: 12px;
        position: relative;

        .vg-question-name {
          display: inline-block;
          line-height: 1.3em;
          margin: 0;
          overflow: hidden;
          padding: 0 60px 0 0;
          text-overflow: ellipsis;
          width: 100%;
          white-space: nowrap;
        }
        .vg-question-action {
          display: block;
          height: 24px;
          position: absolute;
          right: 12px;
          top: 10px;
        }

        .vg-icon-pen,
        .vg-icon-trash {
          cursor: pointer;
          height: 24px;
          width: 24px;
          fill: $light-gray-1;
        }
        .vg-icon-pen {
          margin: 0 10px 0 0;
        }
      }
    }
  }

  .vg-label-title {
    color: $dark-gray-1;
    display: block;
    font-size: 1.5em;
    font-weight: $semibold;
    padding: 0 0 8px;
  }

  .vg-footer-create {
    margin: 24px 0;
    text-align: right;
  }

  .vg-wrap-buttons {
    text-align: right;
  }
}

.vg-time-reading {
  border: {
    color: $light-gray-1;
    radius: 4px;
    style: solid;
    width: 1px;
  }
  display: flex;
  position: relative;
  z-index: 1;

  .vg-custom-radio {
    border-right: solid 1px $light-gray-1;
    flex: 1;
    position: relative;
    text-align: center;

    .vg-label {
      cursor: pointer;
      display: block;
      height: 100%;
      overflow: hidden;
      padding: 12px 0;
      width: 100%;
      position: relative;

      &:hover {
        .vg-label-text {
          color: $white;
          font-weight: $semibold;

          &::before {
            opacity: 1;
          }
        }
      }
    }

    &:last-child {
      border: 0;
    }

    .vg-label-text {
      padding-left: 0;
      position: static;

      &::after {
        display: none;
      }

      &::before {
        @extend %spread;
        @include orange-degrade;
        border: 0;
        border-radius: 0;
        opacity: 0;
        transform: translate3d(0, 0, 0);
        transition: opacity 0.4s $ease-default;
        z-index: -1;
      }
    }

    input[type="radio"]:checked {
      + .vg-label-text {
        color: $white;

        &::before {
          opacity: 1;
        }
      }
    }
  }
}

.vg-item-desc {
  border-bottom: solid 1px $light-gray-1;
  margin: 0 0 24px;
  text-align: center;

  .vg-icon-robot {
    display: block;
    fill: $orange;
    height: 24px;
    margin: 0 auto;
    width: 24px;
  }
  p {
    color: $dark-gray-3;
    font-size: 1.4em;
  }
}

.vg-item-padding {
  padding: 0 32px;
  &.vg-question-input {
    padding: 32px 32px 0;
  }
}
