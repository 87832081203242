//
// Logo
// -----------------------------------------------------------------------------

.vg-logo {
  display: inline-block;
  position: relative;
  vertical-align: top;
  z-index: 1;

  .vg-icon {
    display: block;
    height: 42px;
    width: 132px;

    &-logo-simple {
      height: 42px;
      margin-top: 8px;
      width: 101px;
    }
  }

  svg {
    display: block;
    height: auto;
    width: 100%;
  }
}

//retirar pós rebranding
.vg-logo-old {
  display: inline-block;
  position: relative;
  vertical-align: top;
  z-index: 1;

  .vg-icon {
    display: block;
    height: 34px;
    width: 203px;

    &-logo-simple-old {
      height: 34px;
      width: 117px;
    }
  }

  svg {
    display: block;
    height: auto;
    width: 100%;
  }
}
