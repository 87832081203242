@import "../../../styles/base/global";

.vg-modal-share-video {
  padding: 15px;

  &-subtitulo {
    color: $dark-gray-1;
    font-size: 1.6em;
    font-weight: $regular;
    margin-bottom: 16px;
    text-align: left;
  }

  .vg-label-text {
    color: $dark-gray-1;
    font-size: 1.6em;
    font-weight: $semibold;
    margin-bottom: 8px;
    margin-top: 0px;
    text-align: left;
  }

  .vg-input-token {
    margin-bottom: 16px;
  }

  .vg-btn-success {
    margin-left: 16px;
  }

  .vg-wrap-buttons {
    text-align: right;
  }

  .vg-spinner-icon {
    margin-left: 10px;

    svg {
      fill: $white;
    }
  }

  .vg-relative {
    position: relative;
  }

  .vg-custom-select {
    margin: 0 0 10px;
    max-width: 100%;
  }

  @media (min-width: $desktop-width) {
    .vg-custom-select {
      border: none;
      margin: 0;
      max-width: 250px;
      position: absolute;
      right: 8px;
      top: 6px;
    }
  }
}
