//
// Custom Radio Button
// -----------------------------------------------------------------------------
@import "../../../styles/base/global";

.vg-custom-check {
  display: inline-block;

  input[type="checkbox"] {
    position: absolute;
    left: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;

    &:checked + .vg-input-checkbox-inner {
      border: 1px solid $orange;
      background-color: $orange;
      box-shadow: inset 0 1px 2px 0 rgba($black, 0);
      &:after {
        display: table;
        content: "";
        position: absolute;
        transform: rotate(45deg) scale(1);
      }
    }
  }

  .vg-input-checkbox {
    display: inline-block;
    position: relative;
    vertical-align: top;
    width: 16px;

    .vg-input-checkbox-inner {
      border: 1px solid $light-gray-1;
      border-radius: 2px;
      box-shadow: inset 0 1px 2px 0 rgba($black, 0.2);
      display: block;
      position: relative;
      top: 0;
      left: 0;
      margin: 0 8px 0 0;
      width: 16px;
      height: 16px;
      transition: all 0.3s;

      &:after {
        transform: rotate(45deg) scale(0);
        position: absolute;
        left: 4px;
        top: 1px;
        display: table;
        width: 5px;
        height: 9px;
        border: 2px solid $white;
        border-top: 0;
        border-left: 0;
        content: "";
      }
    }
  }
}
