@import "../../../../styles/base/global";

.drag-drop-container {
  background-color: $white;
  border: 1px dashed $light-gray-8;
  height: 250px;
  margin: auto;
  max-width: 1050px;
  padding: 30px;
  text-align: center;

  .drag-drop-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    padding: 10px;
  }

  .drag-drop-message {
    color: $dark-gray-2;
    font-size: 14px;
    line-height: 22px;
    padding: 10px;
    padding-bottom: 10px;
  }

  .drag-drop-icon {
    color: $light-gray-6;
    height: 70px;
    width: 70px;
  }

  .false-input {
    background-color: $white;
    border: 2px solid $light-gray-6;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    padding: 1px 22px;
  }

  .input-drag-drop,
  .input-change {
    display: none;
  }
}

.pdf_input {
  display: flex;

  .input-change {
    background-color: $white;
    border: 1px solid $light-gray-6;
    border-radius: 2px;
    color: $dark-gray-2;
    display: block;
    font-size: 16px;
    float: left;
    min-height: 40px;
    margin: 16px 5px 0 5px;
    padding: 12px 16px;
    width: 90%;
    word-break: break-all;
  }

  .icon-modal {
    background-color: none;
  }

  .icon-input {
    float: right;
  }

  .icon-input-pdf {
    background-color: $white;
    border: none;
    color: $dark-gray-2;
    cursor: pointer;
    float: right;
    height: 12px;
  }

  .vg-icon-pdf {
    color: $light-gray-8;
    height: 18px;
    width: 18px;
    vertical-align: top;
  }

  .input-pdf {
    display: none;
  }

  .change-pdf {
    margin-top: 18px;
  }

  .false-input {
    background-color: $body-background;
    border: 1px solid $dark-gray-3;
    border-radius: 2px;
    color: $dark-gray-3;
    cursor: pointer;
    font-size: 16px;
    height: 40px;
    width: 96px;
  }
}

.button-save {
  background-color: $green;
  border: none;
  border-radius: 2px;
  color: $white;
  font-size: 16px;
  height: 40px;
  margin: 16px 0px auto 8px;
  width: 96px;
}

.vg-text-info {
  font-size: 14px;
  padding: 0 5px;
}
