//
// Avatar
// --------------------------------------------------------------------------

@import "../../../styles/base/global";

.vg-avatar-placeholder {
  animation: infiniteFade 0.7s $ease-default both alternate infinite;
}

.vg-avatar {
  background: linear-gradient(
    to bottom,
    rgba($light-gray-1, 1) 0%,
    rgba($white, 1) 100%
  );
  border: 3px solid $white;
  border-radius: 50%;
  color: $dark-gray-1;
  cursor: pointer;
  display: block;
  font-size: 2.2em;
  font-weight: bold;
  height: 50px;
  line-height: 31px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 50px;

  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.vg-avatar-editor {
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  transform: rotate(0.002deg);
  width: 100%;

  > div:nth-of-type(1) {
    display: inline-block;
    width: 86vw !important;
    height: 86vw !important;
  }

  canvas {
    cursor: move;
    width: 86vw !important;
    height: 86vw !important;
  }

  .vg-avatar-wrapper {
    background-color: $light-gray-1;
    border: 1px dashed $dark-gray-4;
  }
}

.vg-avatar-toolbar {
  align-items: center;
  color: $dark-gray-2;
  display: flex;
  font-size: 1.3em;
  justify-content: space-between;
  padding: 16px 0;

  .vg-avatar-zoom {
    display: inline-block;
    margin: 0 10px;
    width: 60%;
  }

  input {
    margin-right: 20px;
  }

  label {
    align-items: center;
    display: flex;

    &.vg-avatar-zoom-label {
      width: 100%;
    }
  }

  button {
    appearance: none;
    border: 0;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
    padding: 10px;
    vertical-align: middle;
  }
}

.vg-avatar-wrap-buttons {
  display: flex;

  .vg-btn-danger {
    margin-left: 20px;
  }
}

.vg-avatar-wrapper {
  .vg-modal-body p {
    color: $dark-gray-1;
    margin: 20px auto 0;
    max-width: 80%;
    text-align: center;
  }

  .vg-wrap-buttons {
    border-top: 1px solid $light-gray-2;
    padding: 20px 20px 0;
    width: 100%;

    [class^="vg-btn"] {
      margin: 0;
      margin-bottom: 8px;
      text-align: center;
      width: 100%;
    }

    [class^="vg-col"] {
      &:nth-of-type(1) {
        text-align: left;
      }

      &:nth-of-type(2) {
        text-align: right;
      }
    }
  }
}

@include desktop {
  .vg-avatar-wrapper {
    .vg-wrap-buttons {
      padding: 10px 20px 0;

      [class^="vg-btn"] {
        margin-bottom: 0;
        width: auto;

        &.vg-btn-success {
          float: right;
        }
      }
    }
  }

  .vg-avatar-editor {
    width: 342px;

    > div:nth-of-type(1) {
      display: inline-block;
      width: 300px !important;
      height: 300px !important;
    }

    canvas {
      cursor: move;
      width: 300px !important;
      height: 300px !important;
    }
  }
}
